.otp-input {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    width: 100%;
    gap: 12px;

    input {
      aspect-ratio: 1;
      width: 100% !important;
      font-size: 1.5rem;
      text-align: center;
      border: 1px solid $gray-200;
      border-radius: 5px;
      &:focus {
        outline: none;
        border-color: $primary-400;
      }
    }
  }
}