.onboarding-card {
  background-color: $white;
  padding: 20px 24px;
  border-radius: 4px;

  box-shadow: 0px 2px 12px -6px #18274b14;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
  }

  &__suptitle {
    margin: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: $neutral-500;
  }

  &__title {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: DM Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: $neutral-black;
  }

  &__text {
    margin: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.52px;
    text-align: left;
    color: $neutral-500;
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
