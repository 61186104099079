.docx-preview-wrapper {
  display: inline-flex !important;
  align-items: flex-start !important;
}

.docx-preview-bg {
  overflow: auto;

  display: flex;
  align-items: center;
}
