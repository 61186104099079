.modal-dialog.new-client-modal.modal-md {
  @include media-breakpoint-up(sm) {
    max-width: 658px;
  }

  .modal-body {
    .divorcemate-import {
      .btn {
        justify-content: flex-start;
        font-size: 16px;
      }
    }

    .form-group {
      margin-bottom: 12px;
    }

    .row {
      & > div {
        &:not(:last-child) {
          padding-right: 12px;
        }

        &:not(:first-child) {
          padding-left: 12px;
        }
      }
    }

    .custom-select-input .custom-select__menu .custom-select__menu-list {
      max-height: 113px;
    }
  }
}
