.modal .create-interview.modal-md {
  .modal-content {
    .modal-header .modal-description {
      margin-top: 24px;
      line-height: 21px;
      margin-bottom: 0;
    }

    .modal-body .create-interview-form {
      .custom-input {
        & > input {
          height: 42px;
          padding: 11px 10px 10px 12px;
        }
      }

      .custom-select-input {
        .custom-select__control {
          height: 42px;

          .custom-select__value-container {
            height: 40px;
            min-height: 40px;
          }

          .custom-select__indicators .custom-select__indicator-separator {
            display: none;
          }
        }
      }

      .custom-checkbox {
        margin-top: 24px;
      }

      .tip {
        margin-top: 24px;
        background-color: $neutral-100;
        border: 1px solid $neutral-300;
        padding: 8px 12px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
