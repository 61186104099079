.navbar-buttons {
  margin-top: -25px;

  @include media-breakpoint-down(xs) {
    margin-top: -19px;
  }

  .container {
    @include media-breakpoint-down(xs) {
      padding-right: 0 !important;
    }
  }

  .client-buttons {
    width: 100%;
    margin-left: 0;
    border: 0;

    .btn-group {
      .calculations-type-dropdown {
        .btn.custom-button {
          border-radius: 4px 0 0 4px;
          padding: 12px 12px 13px;

          @include media-breakpoint-down(xs) {
            padding: 11px 4px;
          }
        }

        &:last-of-type {
          .btn.custom-button {
            border-radius: 0px !important;
          }
        }
      }

      .btn.custom-button {
        color: $neutral-500;
        background-color: $white;
        border-color: $white;
        padding: 12px 20px;

        &:hover:not(:disabled) {
          color: $neutral-700;
          background-color: $neutral-100;
          border-color: $white;
        }

        &:active:not(:disabled),
        &.active:not(:disabled),
        &:focus:not(:disabled) {
          color: $primary-500;
          background-color: $neutral-100;
          border-color: $white;
        }

        &:not(:first-child) {
          margin-left: 0;
          border-left: 0;
        }

        &:not(:last-child) {
          border-right: 1px solid $neutral-200 !important;
        }

        @include media-breakpoint-down(xs) {
          height: 38px;
          padding: 11px 12px;
          font-size: 12px;

          svg:not(.svg-inline--fa) {
            height: 16px;
            width: 16px;
            margin-right: 5px;
          }
        }
      }
    }

    &.has-upgrade {
      .btn-group {
        @media screen and (max-width: 400px) {
          width: 100%;
          padding-right: 8px;
        }

        .calculations-type-dropdown {
          @media screen and (max-width: 400px) {
            width: 25%;
            flex: initial;
          }

          .btn.custom-button {
            @media screen and (max-width: 400px) {
              width: 100%;
              padding: 11px 8px;
            }
          }
        }

        .btn.custom-button {
          @media screen and (max-width: 400px) {
            span {
              display: none;
            }

            svg:not(.svg-inline--fa) {
              height: 20px;
              width: 20px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
