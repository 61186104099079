@import 'budget-editor-inputs';
@import 'budget-summary-calculation';

.budget-editor-modal {
  &.fullscreen-modal {
    max-width: 95% !important;
    width: 95% !important;
    margin: 1rem auto;
    
    .modal-content {
      min-height: 90vh;
      max-height: 90vh;
      display: flex;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 0.75rem 1.25rem;

      .modal-title {
        font-size: 1.25rem;
        font-weight: 600;
      }
      
      // Styles for the enhanced header with client info
      .modal-header-title-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        .modal-title {
          margin-bottom: 0.25rem;
        }
        
        .modal-file-info {
          font-size: 0.85rem;
          color: #6c757d;
          margin-top: 2px;
          
          .file-number, .matter-number {
            display: inline-block;
            margin-right: 1rem;
            font-weight: 500;
          }
        }
      }
    }

    .modal-body {
      overflow-y: auto;
      padding: 1rem 1.5rem;
      background-color: #fff;
    }
  }

  .budget-editor-content {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
   
    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 95%;
      width: 95%;
    }
    
    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
      padding: 0;
      
      // Add additional padding to elements inside
      .budget-editor-header,
      .budget-summary-calculation,
      .add-custom-item-section,
      .budget-editor-disclaimer {
        padding: 0.75rem;
        margin-left: 0;
        margin-right: 0;
        border-radius: 4px;
      }
      
      // Mobile styles for the header container
      .budget-headers-container {
        .main-headers {
          .category-header,
          .budget-header {
            font-size: 0.9rem;
            padding: 0.5rem 0.25rem;
          }
        }
        
        .sub-headers {
          .period-header {
            font-size: 0.8rem;
            padding: 0.4rem 0.15rem;
          }
        }
      }
      
      // Specific styling for budget categories on mobile
      .budget-categories {
        .budget-category {
          .category-header {
            padding: 0 0.25rem !important;
            
            h5 {
              font-size: 0.95rem;
              max-width: calc(100% - 30px); // Make room for the "New" button
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          
          .category-items {
            border-radius: 0;
            
            // Make the rows stack better on mobile
            .budget-item-row, .new-budget-item-row {
              padding: 0.5rem 0.25rem !important;
              
              // Adjust the delete icon position for stacked layout
              .delete-icon-container {
                left: 5px;
                top: 8px;
                transform: none;
              }
              
              // Add more space for the description when stacked
              .budget-item-description {
                padding: 30px 5px 8px 5px !important; // Add top padding for delete icon
              }
            }
            
            // Reorganize the subtotal row for mobile
            .subtotal-row {
              flex-direction: column !important;
              padding: 0.3rem !important;
              border-radius: 4px !important;
              
              [class*="col-"] {
                text-align: center !important;
                margin-bottom: 0.25rem;
                padding: 0.25rem !important;
                border-radius: 0 !important;
                height: auto !important;
              }
              
              [class*="col-"]:first-child {
                background-color: transparent !important;
                border-bottom: 1px solid rgba(222, 226, 230, 0.4);
                font-weight: 600;
                font-size: 0.95rem;
              }
              
              [class*="col-"]:not(:first-child) {
                color: #007bff !important; // Ensure blue color in mobile view
              }
              
              .subtotal-value {
                justify-content: center !important;
                padding-right: 0 !important; // Remove right padding since we're centering
                height: 30px !important;
              }
            }
          }
        }
      }
      
      // Make the add custom item section stack better
      .add-custom-item-section {
        .row {
          flex-direction: column !important;
          
          .custom-category-col, 
          .custom-description-col,
          .custom-amount-col, 
          .custom-person-col,
          .custom-button-col {
            width: 100% !important;
            max-width: 100% !important;
            flex: 1 0 100% !important;
            padding: 0.2rem 0 !important;
          }
        }
      }
    }
    
    // Tablet-sized screens
    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 95%;
      width: 95%;
      
      // Adjust column widths for tablet
      .budget-column-headers,
      .budget-item-row,
      .new-budget-item-row,
      .subtotal-row {
        // Keep the row format but reduce minimum widths
        .category-header,
        .budget-item-description,
        [class*="col-"]:first-child {
          min-width: 150px !important;
        }
        
        .client-header, 
        .ex-header,
        .budget-amount-col,
        [class*="col-"]:not(:first-child) {
          min-width: 70px !important;
        }
      }
    }
    
    // Budget editor header
    .budget-editor-header {
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      
      h5 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #212529;
      }
      
      p {
        color: #495057;
        font-size: 0.9rem;
      }
      
      .view-mode-toggle {
        label {
          color: #495057;
          font-size: 0.9rem;
        }
      }
    }
    
    // Column headers styling
    .budget-column-headers {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      background-color: #f8f9fa;
      border-radius: 6px 6px 0 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      border: 1px solid #dee2e6;
      
      [class*="col-"] {
        padding: 0.65rem 0.5rem;
        font-weight: 600;
        color: #495057;
        font-size: 0.95rem;
        position: relative;
        z-index: 1;
        border-right: 1px solid #dee2e6;
        box-sizing: border-box !important;
        
        &:last-child {
          border-right: none;
        }
      }
      
      .category-header {
        width: $description-column-width !important;
        flex: $description-column-flex !important;
        min-width: $description-column-min-width !important;
        max-width: $description-column-max-width !important;
        text-align: left !important;
        padding: 0 5px 0 15px !important; // Updated: removed vertical padding, kept horizontal
        box-sizing: border-box !important; // Ensure padding is included in width calculation
      }
      
      .client-header, .ex-header {
        width: $amount-column-width !important;
        min-width: $amount-column-min-width !important;
        max-width: $amount-column-max-width !important;
        flex: $amount-column-flex !important;
        text-align: right !important;
        padding: 0.65rem 15px 0.65rem 5px !important;
        white-space: nowrap !important; // Prevent text wrapping
        font-size: 0.85rem !important; // Slightly smaller font size to fit
        position: relative !important;
        
        // Override Bootstrap's text-center class with higher specificity
        &.text-center {
          text-align: right !important;
        }
      }
      
      .client-header {
        background-color: rgba(236, 237, 245, 0.8);
      }
      
      .ex-header {
        background-color: rgba(246, 246, 251, 0.9);
      }
    }
    
    // Expand/collapse all button
    .expand-collapse-all {
      font-size: 0.85rem;
      color: #007bff;
      font-weight: 500;
      padding: 0.3rem 0.5rem;
      margin-bottom: 0.5rem;
      
      &:hover {
        background-color: rgba(0, 123, 255, 0.1);
        border-radius: 4px;
        text-decoration: none;
      }
    }
    
    // Budget categories
    .budget-categories {
      margin-bottom: 1rem;
      
      .budget-category {
        
        // New button styling - initially hidden
        .btn-add-item {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          top: 0;
          transform: none;
          z-index: 2;
          opacity: 0;
          transition: opacity 0.2s ease;
          padding: 0.2rem 0.5rem !important;
          background-color: white !important;
          color: #007bff !important;
          border-color: #007bff !important;
          border-radius: 30px;
          font-weight: 500;
          font-size: 0.75rem;
          margin-left: 0.5rem;
          line-height: 1;
          height: 1.5rem;
          
          &:hover {
            background-color: #007bff !important;
            color: white !important;
          }
        }
        
        // Show the button when hovering over the category
        .category-header:hover .btn-add-item {
          opacity: 1;
        }
        
        // Keep the original selector for compatibility
        &:hover .btn-add-item {
          opacity: 1;
        }
        
        .category-header {
          background-color: #f0f4f8 !important;
          color: #495057 !important;
          font-weight: 600;
          border-radius: 4px 4px 0 0;
          border-left: 4px solid #007bff !important;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
          padding: 0 1rem !important;
          position: relative; /* Added position relative to allow absolute positioning of the button */
          
          /* Override any Bootstrap classes that might affect this */
          &.bg-primary {
            background-color: #f0f4f8 !important;
          }
          
          &.text-white {
            color: #495057 !important;
          }
          
          h5 {
            font-size: 1rem;
            margin: 0;
          }
          
          // Category name input styling
          .category-name-input {
            &:focus {
              background-color: rgba(255,255,255,0.2) !important;
              color: #212529 !important;
            }
          }
          
          // Icon styling
          .icon {
            margin-right: 0.5rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #007bff !important;
          }
        }
        
        .category-items {
          position: relative;
          border-radius: 0 0 4px 4px;
          padding: 0; // Remove default padding
          overflow: visible; // Change from hidden to visible to allow delete icon to show
          
          // Remove any additional padding coming from parent classes
          &.px-2 {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
          
          &.py-1 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
          
          // Add proper padding to inner content but not the container itself
          .budget-item-row, .new-budget-item-row, .subtotal-row {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
          }
          
          // Column shading elements - match family property calculator
          .column-shade-1, .column-shade-2, .column-shade-3, .column-shade-4 {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 0;
            width: $amount-column-width;
            min-width: $amount-column-min-width;
            max-width: $amount-column-max-width;
          }
          
          // First client column - yearly amount
          .column-shade-1 {
            right: calc(#{$amount-column-width} * 3); // Position from right
            background-color: rgba(236, 237, 245, 0.8); // Match header shade exactly
            
            @media (max-width: 991px) {
              display: none; // Hide on smaller screens where columns might stack
            }
          }
          
          // Second client column - monthly amount
          .column-shade-2 {
            right: calc(#{$amount-column-width} * 2); // Position from right
            background-color: rgba(236, 237, 245, 0.8); // Match header shade exactly
            
            @media (max-width: 991px) {
              display: none; // Hide on smaller screens where columns might stack
            }
          }
          
          // First ex column - yearly amount
          .column-shade-3 {
            right: $amount-column-width; // Position from right
            background-color: rgba(246, 246, 251, 0.9); // Match header shade exactly
            
            @media (max-width: 991px) {
              display: none; // Hide on smaller screens where columns might stack
            }
          }
          
          // Second ex column - monthly amount
          .column-shade-4 {
            right: 0; // Position from right
            background-color: rgba(246, 246, 251, 0.9); // Match header shade exactly
            
            @media (max-width: 991px) {
              display: none; // Hide on smaller screens where columns might stack
            }
          }
          
          // New item form - when adding to category
          .new-budget-item-row {
            position: relative;
            z-index: 1;
            padding: 0.5rem 0.25rem;
            background-color: rgba(248, 249, 250, 0.7);
            
            // Ensure the delete icon is properly positioned
            .delete-icon-container {
              left: -25px; // Force consistent positioning in new items
            }
            
            // Add proper space for the description input in new items
            .budget-item-description {
              padding-left: 25px !important; // Add extra padding for the delete icon space
            }
            
            [class*="col-"] {
              position: relative;
              z-index: 1;
            }
            
            .action-buttons {
              display: flex;
              align-items: center;
              position: relative;
              z-index: 2;
              
              .btn {
                margin-left: 0.25rem;
              }
            }
          }
          
          // Budget item rows - make sure these match family property calculator exactly
          .budget-item-row, .new-budget-item-row {
            display: flex !important;
            flex-wrap: nowrap !important;
            width: 100% !important;
            position: relative !important;
            z-index: 1 !important;
            margin: 0 !important;
            padding: 0.25rem 0.5rem 0.25rem 10px !important; // Reduce left padding to prevent pushing items right
            overflow: visible !important; // Ensure the delete icon can overflow outside the row
            
            // Override Bootstrap row negative margins
            &.row {
              margin-left: 0 !important;
              margin-right: 0 !important;
              width: 100% !important;
            }
            
            &:nth-child(odd) {
              background-color: rgba(248, 249, 250, 0.7) !important;
            }
            
            &:nth-child(even) {
              background-color: rgba(255, 255, 255, 0.8) !important;
            }
            
            // Add delete icon container
            .delete-icon-container {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              transition: opacity 0.2s ease;
              z-index: 10;
              width: 28px;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              border-radius: 50%;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              left: -25px; // Add default left position for all delete icons
              
              &:hover {
                background-color: #f8d7da;
                color: #dc3545;
              }
              
              .icon {
                font-size: 14px;
                color: #6c757d;
                position: relative;
                left: 2px; /* Small adjustment to center the icon */
                display: flex;
                align-items: center;
                justify-content: center;
              }
              
              &:hover .icon {
                color: #dc3545;
              }
            }
            
            // Alternative position for delete icon when needed 
            &.align-delete-icon-left .delete-icon-container {
              left: -25px; // Keep consistent with standard position
            }
            
            // Show delete icon on row hover
            &:hover {
              background-color: rgba(236, 242, 252, 0.5) !important;
              transition: background-color 0.2s ease;
              
              .delete-icon-container {
                opacity: 1;
              }
            }
            
            // Add left padding to description to avoid overlap with delete icon
            .budget-item-description {
              /* Remove all overriding padding here to avoid conflicts */
              /* padding-left: 5px !important; */ // Reduced padding since we now have row padding
            }
            
            // All columns should have position relative and proper z-index
            [class*="col-"] {
              position: relative;
              z-index: 1;
              padding-top: 0.25rem !important;
              padding-bottom: 0.25rem !important;
            }
            
            // Description column
            .budget-item-description {
              width: $description-column-width !important;
              flex: $description-column-flex !important;
              min-width: $description-column-min-width !important;
              max-width: $description-column-max-width !important;
              box-sizing: border-box !important; // Ensure padding is included in width calculation
              padding: 0.25rem 15px 0.25rem 15px !important; // Consolidate padding rules
              
              .description-input {
                border: 1px solid transparent;
                background-color: transparent;
                padding: 0.25rem 0.5rem;
                height: auto;
                min-height: 30px;
                width: 100%;
                
                &:focus {
                  border-color: #80bdff;
                  background-color: #fff;
                }
              }
              
              // Ensure input containers are properly aligned
              .form-group {
                margin-bottom: 0;
              }
            }
            
            // Value columns with fixed width
            .budget-amount-col {
              width: $amount-column-width !important;
              min-width: $amount-column-min-width !important;
              max-width: $amount-column-max-width !important;
              flex: $amount-column-flex !important;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              box-sizing: border-box !important;
              padding: 0.25rem 15px 0.25rem 5px !important; // Increase right padding to align with subtotals
              position: relative;
              border: none !important; // Ensure no border on the column
              
              // Remove border from any potential input containers
              .form-group, .input-group, .currency-input-container {
                border: none !important;
                box-shadow: none !important;
                background-color: transparent !important;
              }
              
              .currency-input {
                border: 1px solid #ced4da !important; // Keep border on the input itself
                background-color: #fff !important; // Solid white background
                text-align: right;
                padding: 0.2rem 0.4rem; // Reduce horizontal padding
                height: 30px !important;
                min-height: 30px !important;
                max-height: 30px !important;
                min-width: 60px !important;
                width: $currency-input-width !important; // Use variable instead of 100%
                max-width: $currency-input-width !important; // Constrain max width
                margin-right: 0 !important; // Remove margin to align with subtotal
                box-sizing: border-box !important;
                position: relative;
                z-index: 2; // Ensure input is above column background
                opacity: 1 !important; // Ensure full opacity
                
                &:focus {
                  border-color: #80bdff;
                  background-color: #fff !important; // Maintain solid white background when focused
                  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                  z-index: 3; // Higher z-index when focused
                }
              }
              
              // Ensure input containers are properly aligned
              .form-group {
                margin-bottom: 0;
                width: $currency-input-width !important; // Match input width
                margin-right: 0 !important; // Remove margin to align with subtotal
                background-color: transparent !important; // Container can be transparent
                position: relative;
                z-index: 1;
                border: none !important; // Ensure no border on container
                box-shadow: none !important;
              }
            }
            
            // Client and Ex budget item styling - remove backgrounds and ensure proper z-index
            .client-budget-item, .ex-budget-item {
              position: relative;
              z-index: 1;
              background-color: transparent !important; // Remove any background to avoid conflicts
              
              &.text-right {
                text-align: right !important;
              }
              
              // Any child inputs should also have solid white backgrounds
              input, .form-control, .currency-input {
                background-color: #fff !important; // Solid white background
                position: relative;
                z-index: 2;
                opacity: 1 !important; // Ensure full opacity
              }
            }
          }
          
          // Subtotal row
          .subtotal-row {
            position: relative;
            z-index: 2;
            padding: 0.3rem 0.25rem !important; // Match family property calculator padding
            background-color: rgba(248, 249, 250, 0.85) !important;
            border-top: 1px solid rgba(222, 226, 230, 0.8) !important;
            border-bottom: none !important; // Remove bottom border to match family property calculator
            font-weight: 600;
            box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.02), 0 1px 4px rgba(0, 0, 0, 0.02);
            margin: 0 !important; // Ensure no margins
            display: flex !important;
            flex-wrap: nowrap !important;
            width: 100% !important;
            border-radius: 4px !important; // Add border-radius like family property calculator
            
            // Override Bootstrap row negative margins
            &.row {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
            
            // Override any Bootstrap padding on py-2
            &.py-2 {
              padding-top: 0.3rem !important;
              padding-bottom: 0.3rem !important;
            }
            
            &:hover {
              background-color: rgba(236, 242, 252, 0.7) !important;
            }
            
            [class*="col-"] {
              position: relative;
              z-index: 1;
            }
            
            // Match column widths
            [class*="col-"]:first-child {
              width: $description-column-width !important;
              flex: $description-column-flex !important;
              min-width: $description-column-min-width !important;
              max-width: $description-column-max-width !important;
              text-align: right; // Right align the text
              padding-right: 15px !important; // Match right padding with input columns
            }
            
            [class*="col-"]:not(:first-child) {
              width: $amount-column-width !important;
              min-width: $amount-column-min-width !important;
              max-width: $amount-column-max-width !important;
              flex: $amount-column-flex !important;
              text-align: right;
              color: #007bff !important; // Add blue color to all subtotal values
              padding-right: 15px !important; // Consistent padding for subtotal values
              font-family: inherit;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 30px !important; // Adjust height to match family property calculator
              
              // Force blue color on all elements inside the value columns
              * {
                color: #007bff !important;
              }
            }
            
            strong {
              font-size: 0.95rem;
              color: #343a40;
              font-weight: 600;
              display: inline-block; // Ensure text aligns properly
            }
            
            // Add subtotal value specific class to match family property calculator
            .subtotal-value {
              font-weight: 600;
              font-size: 0.95rem;
              color: #007bff;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              
              &.difference-note {
                justify-content: flex-start; /* Align text to the left */
                padding-left: 15px;
                font-style: italic;
                font-weight: normal;
                /* Make the difference note span two columns */
                width: calc(#{$amount-column-width} * 2) !important;
                min-width: calc(#{$amount-column-min-width} * 2) !important;
                max-width: calc(#{$amount-column-max-width} * 2) !important;
                flex: 0 0 calc(#{$amount-column-width} * 2) !important;
                
                /* Ensure text fills the cell properly */
                text-align: left;
                white-space: normal;
                
                span {
                  width: 100%;
                  display: inline-block;
                  text-align: left;
                }
              }
            }
            
            // Ensure label classes have black text
            .total-label,
            .difference-label {
              color: #343a40 !important;
              font-weight: 600;
              
              * {
                color: #343a40 !important;
              }
            }
          }
        }
      }
    }
    
    // Add custom budget item section
    .add-custom-item-section {
      background-color: #f8f9fa;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      margin-bottom: 1.5rem;
      border: 1px solid #dee2e6;
      padding: 1rem;
      
      h5 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #212529;
        margin-bottom: 1rem;
      }
      
      label {
        font-weight: 500;
        font-size: 0.85rem;
        color: #495057;
      }
      
      .btn-primary {
        background-color: #007bff;
        border-color: #007bff;
        font-weight: 500;
        
        &:hover {
          background-color: #0069d9;
          border-color: #0062cc;
        }
      }
      
      // Button icon styling for utility buttons
      .btn-sm {
        position: relative;
        
        .icon {
          width: 16px;
          height: 16px;
          display: inline-block;
          vertical-align: -3px; /* Negative value pulls icon up */
          margin-right: 3px;
          position: relative;
          top: -1px; /* Shift icon up slightly */
        }
      }
      
      // Custom column styles for the add item form
      .custom-category-col, 
      .custom-description-col {
        flex: 1 1 25%;
        max-width: 25%;
        min-width: 180px;
        padding: 0 10px;
      }
      
      .custom-amount-col, 
      .custom-person-col {
        flex: 1 1 20%;
        max-width: 20%;
        min-width: 140px;
        padding: 0 10px;
      }
      
      .custom-button-col {
        flex: 0 0 auto;
        min-width: 120px;
        padding: 0 10px;
      }
      
      // Make the form responsive
      @media (max-width: 991px) {
        .custom-category-col, 
        .custom-description-col,
        .custom-amount-col, 
        .custom-person-col,
        .custom-button-col {
          flex: 1 1 100%;
          max-width: 100%;
          margin-bottom: 15px;
        }
      }
      
      // Make form elements consistent with the rest of the UI
      .form-control {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
      }
      
      // Ensure currency inputs match
      .currency-input {
        min-width: 60px !important;
        width: 100% !important;
        max-width: 120px !important;
        text-align: right;
        padding: 0.2rem 0.6rem;
      }
    }
    
    // Budget disclaimer
    .budget-editor-disclaimer {
      background-color: #f8f9fa;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      border: 1px solid #dee2e6;
      padding: 1rem;
      
      h5 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #212529;
      }
      
      p {
        color: #495057;
        font-size: 0.9rem;
      }
    }
  }
} 