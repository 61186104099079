.pro-plan {
  position: relative;

  padding: 20px 24px;
  border-radius: 12px;
  background-color: $white;
  box-shadow: $shadow-400;

  flex: 1 0;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &-badge {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 3px 7px;
      border-radius: 6px;

      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.1em;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      &.primary {
        color: $primary-100;
        border: 1px solid $primary-400;
        background-color: $primary-400;
      }

      &.secondary {
        color: $gray-800;
        border: 1px solid $gray-100;
        background-color: $gray-100;
      }
    }

    &-seat {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 18.23px;
      color: $gray-900;

      span {
        color: $gray-600;
        text-decoration: line-through;
        margin-right: 8px;
      }
    }
  }

  &__header {
    margin-top: 18px;

    &-price {
      margin: 0;
      font-size: 48px;
      font-weight: 700;
      line-height: 46px;
      color: $gray-900;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 18.23px;
        color: $gray-600;
      }
    }

    &-description {
      margin: 0;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.23px;

      b {
        color: $primary-400;
      }
    }
  }

  &__button {
    width: 100%;
    margin: 24px 0;
    border-radius: 8px !important;
  }

  &__features {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-item {
      display: flex;
      align-items: center;
      gap: 6px;

      &__icon {
        width: 24px;
        height: 24px;

        &.primary {
          color: $primary-400;
        }

        &.black {
          color: $gray-900;
        }

        &.gray {
          color: $gray-600;
        }
      }

      &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.23px;
        color: $gray-900;

        &.line-through {
          color: $gray-600;
          text-decoration: line-through;
        }
      }
    }
  }

  &__note {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    display: inline-block;
    width: 100%;
    margin-top: 16px;
    padding: 0 20px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18.23px;
    color: $neutral-600;
  }
}
