@mixin clear-btn-styles {
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0;
  display: block;
}

@mixin btn-primary {
  background-color: $primary-500;
  border-color: $primary-500;
  color: $white;

  &:hover:not(:disabled) {
    background-color: $primary-700;
    border-color: $primary-700;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $primary-900;
    border-color: $primary-900;
  }
}

@mixin btn-secondary {
  background-color: $primary-100;
  border-color: $primary-200;
  color: $primary-500;

  &:hover:not(:disabled) {
    background-color: $primary-100;
    border-color: $primary-400;
    color: $primary-500;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $primary-100;
    border-color: $primary-700;
    color: $primary-700;
  }
}

@mixin btn-white {
  background-color: $white;
  border-color: $neutral-200;
  color: $neutral-black;

  &:hover:not(:disabled) {
    background-color: $neutral-100;
    border-color: $neutral-200;
    color: $neutral-black;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $neutral-200;
    border-color: $neutral-200;
    color: $neutral-black;
  }
}

@mixin btn-stroke {
  background-color: $white;
  border-color: $link-500;
  color: $link-500;

  &:hover:not(:disabled) {
    background-color: $link-100;
    border-color: $link-500;
    color: $link-500;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $link-100;
    border-color: $link-700;
    color: $link-700;
  }
}

@mixin btn-link {
  background-color: transparent;
  border-color: transparent;
  color: $link-500;
  text-decoration: none !important;

  &:hover:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $primary-500;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $primary-900;
  }
}

@mixin btn-dashed-link {
  width: 100%;
  height: 100%;
  border: 1px dashed $link-500 !important;
  border-radius: 4px !important;

  &:hover {
    border-color: $primary-500 !important;
    color: $primary-500 !important;
  }

  &:focus {
    border-color: $primary-900 !important;
    color: $primary-900 !important;
  }

  @include btn-link();
}

@mixin btn-dashed-link {
  width: 100%;
  height: 100%;
  border: 1px dashed $link-500 !important;
  border-radius: 4px !important;

  &:hover,
  &:focus {
    border-color: $primary-500 !important;
    color: $primary-500 !important;
  }

  @include btn-link();
}

@mixin btn-white-link {
  background-color: transparent;
  border-color: transparent;
  color: $white;

  &:hover:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $neutral-300;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $neutral-400;
  }
}

@mixin btn-red-link {
  background-color: transparent;
  border-color: transparent;
  color: $negative-500;

  &:hover:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $negative-600;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $negative-700;
  }
}

@mixin btn-size-lg {
  height: 42px;
  padding: 12px 24px 11px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  &.dropdown-btn {
    padding: 12px 12px 13px;
  }

  &.left-icon {
    svg:not(.svg-inline--fa) {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    .svg-inline--fa {
      margin-right: 8px;
    }
  }

  &.right-icon {
    svg:not(.svg-inline--fa) {
      width: 24px;
      height: 24px;
      margin-left: 6px;
    }

    .svg-inline--fa {
      margin-left: 8px;
    }
  }

  &.btn-link,
  &.btn-white-link,
  &.btn-red-link {
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin btn-size-md {
  height: 42px;
  padding: 9px 24px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;

  &.dropdown-btn {
    padding: 9px 12px 10px;
  }

  &.left-icon {
    padding: 9px 16px 10px 12px;

    svg:not(.svg-inline--fa) {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    .svg-inline--fa {
      margin-right: 8px;
    }
  }

  &.right-icon {
    padding: 9px 12px 10px 16px;

    svg:not(.svg-inline--fa) {
      width: 24px;
      height: 24px;
      margin-left: 6px;
    }

    .svg-inline--fa {
      margin-left: 8px;
    }
  }

  &.only-icon {
    padding: 9px 10px 10px;
  }

  &.all-icons {
    padding: 9px 16px 10px 16px;

    svg {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &.btn-link,
  &.btn-white-link,
  &.btn-red-link {
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin btn-size-sm {
  height: 34px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  &.left-icon {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .svg-inline--fa {
      margin-right: 8px;
    }
  }

  &.right-icon {
    svg {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }

    .svg-inline--fa {
      margin-left: 8px;
    }
  }

  &.only-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.btn-link,
  &.btn-white-link,
  &.btn-red-link {
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin btn-size-xs {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  line-height: 16px;

  &.left-icon {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}
