.client-plan {
  position: relative;

  display: inline-flex;
  flex-direction: column;
  align-items: center;

  padding: 24px;
  border-radius: 4px;
  background-color: $white;

  box-shadow: 0px 2px 12px -6px #18274b14;

  flex: 1 0 256px;

  &.popular {
    border: 1px solid $primary-500;
  }

  @include media-breakpoint-down(lg) {
    flex: 0 0 344px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  @include media-breakpoint-down(xs) {
    padding: 16px;
  }

  &__popular {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    padding: 5px 10px;
    border-radius: 4px;
    background-color: $primary-500;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.2em;
    color: $white;

    text-align: center;
  }

  &__title {
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
    white-space: nowrap;
  }

  &__price {
    margin-bottom: 8px;

    font-family: 'DM Sans', sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 1;

    span {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  &__subtitle {
    font-family: Roboto, sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: $neutral-500;

    margin-bottom: 25px;
  }

  &__features {
    margin-bottom: 32px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;

      &__icon {
        width: 24px;
        height: 24px;

        &.primary {
          color: $primary-400;
        }

        &.black {
          color: $gray-900;
        }

        &.gray {
          color: $gray-600;
        }
      }

      &__text {
        font-size: 14px;
        line-height: 16px;
        color: $neutral-black;

        &.line-through {
          color: $gray-600;
          text-decoration: line-through;
        }
      }
    }
  }

  button {
    border-color: $primary;
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-width: 1px;

    &:active,
    &:focus {
      background-color: $btn-primary-hover !important;
      border-color: $btn-border-hover !important;
      color: $white;
      box-shadow: $custom-box-shadow, 0 0 0 0 transparentize($primary, 0.5) !important;
    }

    &:disabled {
      background-color: $primary;
      color: $white;
    }

    &:hover {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
  }

  .best-value,
  .title,
  .price,
  .sub-price {
    text-align: center;
  }
}
