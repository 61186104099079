.modal .modal-dialog.upgrade-plan-modal {
  min-width: 703px;

  @include media-breakpoint-down(md) {
    min-width: 644px;
  }

  @include media-breakpoint-down(sm) {
    min-width: 548px;
  }

  &.pro {
    .modal-content {
      &::after {
        background-image: url('../../../img/backgrounds/pro-tools.png');
        width: 400px;
        height: 268px;
      }
    }
  }

  &.client {
    .modal-content {
      &::after {
        background-image: url('../../../img/backgrounds/client-plan-modal.png');
        width: 398px;
        height: 325px;
      }
    }
  }

  .modal-content {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 52%;
      right: -77px;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
      transform: translateY(-50%);
    }

    .modal-header {
      padding: 64px 64px 16px;
      display: flex;
      flex-direction: row;
      border-bottom: 0;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        padding: 42px 42px 16px;
      }

      .modal-title {
        font-size: 32px;
        line-height: 44px;
      }
    }

    .modal-body {
      padding: 0 64px;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        padding: 0 42px;
      }

      .upgrade-plan-container {
        .upgrade-plan-span {
          margin-bottom: 58px;
          display: block;
          max-width: 500px;
          color: #747474;
          font-size: 16px;
          line-height: 23px;

          .upgrade-plan-link {
            color: $primary;
          }
        }

        .upgrade-plan-list {
          padding: 0 0 0 36px;
          margin: 0;
          list-style: none;

          .upgrade-plan-list-item {
            position: relative;
            font-size: 16px;
            line-height: 19px;

            &:before,
            &:after {
              position: absolute;
              content: '';
              width: 2px;
              border-radius: 2px;
              background-color: #56cf42;
            }
            &:before {
              top: 4px;
              left: -25px;
              height: 14px;
              transform: rotate(45deg);
            }
            &:after {
              top: 8.9px;
              left: -31.6px;
              height: 8px;
              transform: rotate(-45deg);
            }

            &:not(:last-child) {
              margin-bottom: 18px;
            }
          }
        }
      }
    }

    .modal-footer {
      padding: 45px 64px 64px;
      border-top: 0;
      flex-direction: row;
      justify-content: start;

      @include media-breakpoint-down(sm) {
        padding: 45px 42px 42px;
      }

      gap: 30px;
      z-index: 1;

      .btn-cancel {
        height: 48px;
        padding: 16px 32px;
        background-color: transparent;
        border: 0;
        color: #a2a2a2;

        &:hover {
          background-color: transparent;
          color: $primary;
        }

        &:active {
          background-color: transparent !important;
        }

        &:focus {
          background-color: transparent !important;
        }
      }

      .btn-submit {
        position: relative;
        height: 48px;
        padding: 16px 32px;
        margin: 0;
        box-shadow: 0px 6px 12px -6px rgba(98, 39, 245, 0.12),
          0px 8px 24px -4px rgba(98, 39, 245, 0.08) !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.pro {
      .modal-content {
        &::after {
          background-image: url(../../../img/backgrounds/pro-tools.png);
          width: 303px;
          top: 56%;
          right: -11px;
        }
      }
    }

    &.client {
      .modal-content {
        &::after {
          background-image: url(../../../img/backgrounds/client-plan-modal.png);
          width: 283px;
          top: 59%;
          right: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    min-width: min-content;
    margin: 0;

    .modal-content {
      margin: 10px;
      width: 100%;
      height: 100%;

      .modal-header {
        padding: 21px 21px 16px;
        flex-direction: column-reverse;
        flex: 1;

        .modal-title {
          margin-top: 60px;
          font-size: 24px;
          line-height: 31px;
          width: 100%;
        }

        .modal-header-title-row {
          display: flex;
          justify-content: center;
          align-self: center;

          .modal-title {
            text-align: center;
            align-self: center;
          }
        }
      }

      &::after {
        right: 50%;
        transform: translate(50%, -50%);
      }

      .modal-body {
        flex: 0;
        padding: 0 21px 21px;

        .upgrade-plan-container {
          .upgrade-plan-span {
            margin-bottom: 31px;
            text-align: center;
            font-size: 12px;
            line-height: 18px;
          }

          .upgrade-plan-list {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .upgrade-plan-list-item {
              font-size: 12px;
              line-height: 18px;

              &:before {
                left: -14px;
              }
              &:after {
                left: -20.6px;
              }
            }

            .upgrade-plan-list-item:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }

      .modal-footer {
        padding: 20px 10px 30px !important;

        .btn-cancel,
        .btn-submit {
          height: 42px;
          padding: 8px 16px;
        }
      }
    }

    &.pro {
      .modal-content {
        &::after {
          width: 140px;
          height: 105px;
          top: 75px;
          right: 50%;
        }
      }
    }

    &.client {
      .modal-content {
        &::after {
          width: 150px;
          height: 131px;
          top: 68px;
          right: 50%;
        }
      }
    }
  }
}
