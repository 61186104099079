.share-calculation {
  padding-bottom: 0;
  margin-bottom: 8px;

  .share-calculation-row {
    border-top: none;
    padding-top: 17px;

    & > div.col-md-12 {
      & > .title,
      & > p.text-muted {
        display: none;
      }

      .share-calculation-form {
        .form-row {
          max-width: 466px;
          align-items: flex-end;
          margin: 0 0 4px 0;

          .form-group {
            margin-bottom: 0;
            line-height: 16px;
          }
        }

        .description {
          line-height: 16px;
          max-width: 348px;

          p.text-muted {
            display: inline;
            color: $neutral-500;
            margin-bottom: 0;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .shared-with {
        .shared-with-header {
          display: none;
        }
      }
    }
  }
}
