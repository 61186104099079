.plan-settings-modal {
  max-width: 416px !important;

  &__header {
    position: relative;

    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    h4 {
      margin: 0;

      font-family: DM Sans;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;

      color: $neutral-black;
    }

    svg {
      cursor: pointer;
      color: $neutral-500;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $neutral-600;
      }

      &:focus {
        color: $neutral-700;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 24px 12px;

    label {
      margin-bottom: 8px;

      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;

      color: $neutral-800;
    }

    p {
      margin: 8px 0 16px;

      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;

      color: $neutral-700;

      &:last-child {
        margin-top: 16px;
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 16px 24px 24px;
  }

  .modal-body {
    padding: 0 !important;
  }
}
