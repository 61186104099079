.background-section {
  .background-section-row {
    width: 100%;

    .name {
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      margin-bottom: 2px;
    }

    .short-info {
      display: block;
      color: $neutral-500;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .background-form {
    padding-bottom: 24px;

    & > div {
      &.right-col {
        padding-left: 0;
        margin-left: -5px;
      }

      & > div {
        max-width: 279px;
      }
    }

    .more-options {
      display: flex;
      flex-direction: column;

      .title {
        display: flex;
        align-items: center;
        color: $neutral-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 2px;

        svg {
          width: 23px;
          height: 23px;
          margin-right: 6px;
          color: $link-500;
        }
      }

      .description {
        color: $neutral-600;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
