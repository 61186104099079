.income-section-form {
  @import 'section-columns-container';
}

.finance-income-form {
  .description {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: $neutral-black;
  }

  .react-datepicker__input-container {
    .custom-date-picker {
      padding: 10px 12px;
      height: 42px;
      border-color: $neutral-200;
      font-size: 16px;
      line-height: 21px;

      &:hover {
        border-color: $neutral-400;
      }

      &::placeholder {
        color: $neutral-400;
        font-size: 16px;
        line-height: 21px;
      }

      &:focus {
        box-shadow: none !important;
        border-color: $neutral-400;
      }
    }
  }
}
