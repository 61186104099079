.modal {
  .modal-dialog.possession-valuation-modal {
    max-width: 500px;

    .modal-content {
      border: 0;

      .modal-header .title-with-tooltip {
        display: flex;
        align-items: center;

        svg {
          color: $primary-500;
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .modal-body {
        padding: 0;
        padding-top: 4px;

        .valuation-display {
          padding-left: 32px;
          display: flex;
          padding-bottom: 14px;

          .label {
            white-space: nowrap;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: $neutral-500;
            margin-right: 4px;
          }

          .custom-input.select-input {
            width: auto !important;
            min-width: 110px;

            .custom-select-input {
              .custom-select__control {
                min-height: 16px;
                height: 16px;
                border: 0;
                background-color: transparent;
                cursor: pointer;

                .custom-select__value-container {
                  padding: 0;
                  height: 16px;

                  .custom-select__single-value {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                  }

                  & > div:last-of-type {
                    margin: 0;
                    padding: 0;
                    height: 16px;

                    .custom-select__input {
                      height: 16px;
                      display: block !important;
                      margin-top: -8px;
                      font-size: 14px !important;
                      font-weight: 500 !important;

                      input {
                        height: 16px;
                      }
                    }
                  }

                  .custom-select__placeholder {
                    height: 16px;
                    line-height: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $gray-700;
                  }
                }

                .custom-select__indicators {
                  height: 16px;
                  padding: 0;
                  width: 12px;

                  .custom-select__indicator {
                    width: 16px;
                    padding: 0;

                    svg {
                      fill: $neutral-black !important;
                    }
                  }
                }
              }

              .custom-select__menu {
                z-index: 200;
                .custom-select__option {
                  padding: 6px 12px;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16px;
                }
              }
            }
          }
        }

        .additional-info-badge {
          margin: 0 32px 17px;
          padding: 2px 6px;
          background-color: $neutral-500;
          border-radius: 4px;
          width: max-content;

          color: $white;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          line-height: 16px;
        }

        .tab-navigation-container {
          .tab-navigation {
            padding-left: 16px;

            .tab {
              white-space: nowrap;
              color: $neutral-500;
              font-family: 'Roboto', sans-serif;
              font-size: 14px;
              line-height: 16px;
              font-weight: 500;
              padding-bottom: 8px;
              padding-top: 0;

              &.active {
                color: $primary-500;
              }
            }
          }
        }

        .inputs-container {
          padding: 24px 32px 0;

          .inputs-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 8px;

            & > svg {
              min-width: 32px;
              height: 32px;
              margin-bottom: 6px;
              color: $primary-500;
            }
          }
        }
      }
    }
  }
}
