.current-plan {
  background-color: $white;
  border-radius: 4px;
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 30px;
  }

  .current-plan-row {
    padding: 16px 54px 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      padding: 15px 14px 15px 18px;
    }

    .plan-desc-list {
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 20px;

      @include media-breakpoint-down(xs) {
        justify-content: space-around;
        align-items: flex-start;
      }

      .plan-description {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-right: 60px;
        }

        @include media-breakpoint-down(sm) {
          &:nth-child(3) {
            display: none;
          }
        }

        @include media-breakpoint-down(xs) {
          &:nth-child(2),
          &:nth-child(5) {
            display: none;
          }
        }

        .label {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 8px;
          white-space: nowrap;
        }

        .description {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;

          @media screen and (max-width: 400px) {
            max-width: 150px;
          }
        }
      }
    }

    .row-icon {
      width: 15px;
      margin-right: -20px;

      @include media-breakpoint-down(sm) {
        margin-right: 0px;
      }
    }
  }

  .current-plan-content {
    border-top: 1px dashed $neutral-300;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .title {
      font-size: 14px;
      line-height: 18px;
      color: $neutral-600;
      max-width: 379px;
      margin-right: 16px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    .subtitle {
      font-size: 14px;
      line-height: 18px;
      color: $neutral-600;
      max-width: 388px;
      margin-bottom: 0;
      margin-left: 16px;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }

      a {
        font-size: 14px;
        line-height: 18px;
        color: $link-500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &.privacy-link {
          margin-top: 24px;
          display: block;

          @include media-breakpoint-down(sm) {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.billing-container {
  .billing-trial-description {
    margin: 20px auto 0px;
    display: block;
    width: max-content;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }

    @include media-breakpoint-down(xs) {
      margin: 20px auto 0;
    }

    & + .billing-cards .billing-plan-card {
      margin-top: 30px !important;
    }
  }

  .billing-cards {
    gap: 32px;

    .billing-plan-card {
      position: relative;
      min-width: 255px;
      padding: 24px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      background-color: $white;
      border-radius: 4px;
      margin-top: 0 !important;

      @include media-breakpoint-down(xs) {
        padding: 16px;
      }

      &.popular {
        border: 1px solid $primary-500;
      }

      @include media-breakpoint-down(md) {
        margin-top: 0 !important;
      }

      .row {
        justify-content: center;
      }

      .best-value {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 25px;
        margin-bottom: 23px;
        padding: 0 15px;

        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;

        background-color: $primary-500;
        color: white;
      }

      .title {
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        white-space: nowrap;
        margin-bottom: 4px;
      }

      .price {
        font-family: "DM Sans", sans-serif;
        margin-bottom: 8px;

        .price-prepend {
          font-size: 16px;
          margin-right: 8px;
        }

        span:not(.price-prepend) {
          font-size: 60px;
          font-weight: 700;
          line-height: 72px;
        }
      }

      .sub-price {
        font-size: 12px;
        line-height: 14px;
        color: $neutral-500;

        margin-bottom: 25px;
      }

      .features {
        margin-bottom: 25px;

        .feature {
          position: relative;
          display: flex;
          align-items: center;
          height: 24px;
          padding-left: 32px;
          margin-bottom: 8px;

          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;

          svg {
            position: absolute;
            left: 0;

            path {
              stroke: $success-600;
            }
          }

          &.disabled {
            color: $neutral-300;

            svg path {
              stroke: $neutral-400;
            }
          }
        }
      }

      .active-subscription {
        width: 100%;
        border: 1px solid $success-600;
        border-radius: 4px;

        color: $success-600;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: 8px;
        }
      }

      button {
        border-color: $primary;
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border-width: 1px;

        &:active,
        &:focus {
          background-color: $btn-primary-hover !important;
          border-color: $btn-border-hover !important;
          color: $white;
          box-shadow: $custom-box-shadow, 0 0 0 0 transparentize($primary, 0.5) !important;
        }

        &:disabled {
          background-color: $primary;
          color: $white;
        }

        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: $white;
        }
      }

      .best-value,
      .title,
      .price,
      .sub-price {
        text-align: center;
      }
    }
  }

  .custom-btn-group {
    margin: 0 auto 24px;
    max-width: 272px;

    @include media-breakpoint-up(xs) {
      margin: 24px auto 36px;
    }

    .btn.btn-primary {
      font-family: Roboto, sans-serif !important;
    }
  }
}

.billing-plan-page {
  .billing-plan-card {
    @include media-breakpoint-down(lg) {
      padding: 24px 16px !important;
    }
  }
}

.billing-container {
  .billing-trial-description {
    margin: 20px auto 0px;
    display: block;
    width: max-content;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }

    @include media-breakpoint-down(xs) {
      margin: 20px auto 0;
    }

    & + .billing-cards .billing-plan-card {
      margin-top: 30px !important;
    }
  }

  .billing-cards {
    gap: 32px;
  }

  .custom-btn-group {
    margin: 0 auto 24px;
    max-width: 272px;

    @include media-breakpoint-up(xs) {
      margin: 24px auto 36px;
    }

    .btn.btn-primary {
      font-family: Roboto, sans-serif !important;
    }
  }
}

.billing-plan-page {
  .billing-plan-card {
    @include media-breakpoint-down(lg) {
      padding: 24px 16px !important;
    }
  }
}
