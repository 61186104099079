.property-and-debt-section-form {
  @import 'section-columns-container';

  .section-columns-container {
    .section-switch-valuation {
      padding: 16px;
      padding-top: 0;

      color: $neutral-500;

      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .section-columns {
      .section-column.half {
        &:first-child {
          background-color: transparentize($link-800, 0.97) !important;
        }

        &:last-child {
          background-color: unset !important;
        }
      }

      .section-column.half.total-incomes-list {
        &:not(:last-child) {
          background-color: transparentize($link-800, 0.97) !important;
        }
      }

      .section-column .field {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.switch-valuation-btn {
  padding: 0;
  background: none;
  border: none;
  outline: none;

  text-align: left;

  color: $link-500;

  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  transition: 0.1s ease-in-out color;

  &:hover {
    color: $link-700;
  }

  &:active {
    outline: none;
  }
}
