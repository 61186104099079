@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .w#{$infix}-25 {
      width: 25% !important;
    }
    .w#{$infix}-50 {
      width: 50% !important;
    }
    .w#{$infix}-75 {
      width: 75% !important;
    }
    .w#{$infix}-100 {
      width: 100% !important;
    }
    .w#{$infix}-auto {
      width: auto !important;
    }
  }
}
