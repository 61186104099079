.biography-container {
  .biography-section-container {
    &:not(:last-child) {
      margin-bottom: 32px;
      padding-bottom: 32px;
      position: relative;

      @include media-breakpoint-down(sm) {
        padding-bottom: 24px;
        margin-bottom: 24px;
      }

      &::after {
        position: absolute;
        bottom: -32px;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 32px;
        background-image: linear-gradient(to right, white 60%, $neutral-300 0%);
        background-position: bottom;
        background-size: 15px 1px;
        background-repeat: repeat-x;
      }
    }

    .section-columns {
      display: flex;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      &:not(:last-child) {
        .section-column {
          position: relative;
          margin-bottom: 24px;
          padding-bottom: 24px;

          @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
            padding-bottom: 0;
          }

          &::after {
            position: absolute;
            bottom: 0;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to right, white 67%, $neutral-300 0%);
            background-position: bottom;
            background-size: 15px 1px;
            background-repeat: repeat-x;

            @include media-breakpoint-down(sm) {
              content: none;
            }
          }
        }
      }

      .section-name {
        width: 120px;
        max-width: 120px;
        min-width: 120px;
        font-size: 14px;
        line-height: 16px;
        color: $neutral-500;

        @include media-breakpoint-down(lg) {
          margin-bottom: 8px;
          width: 100%;
          font-weight: 500;
          color: $neutral-black;
          max-width: initial;
        }
      }

      .section-column {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
        width: 100%;

        .biography-section {
          width: 50%;
          display: flex;
          color: $neutral-500;
          font-size: 14px;
          line-height: 16px;
          padding-right: 10px;

          &:hover {
            text-decoration: none;
          }

          .field:hover {
            text-decoration: underline;
          }

          @include media-breakpoint-down(sm) {
            width: 100%;
            padding-right: 0;
          }

          @include media-breakpoint-down(xs) {
            flex-direction: column;
          }

          .section-fields-list {
            .merging-normal .field {
              .field-value,
              .value {
                color: $neutral-black;
                cursor: pointer;
              }
            }

            .merging-conflict .field {
              .field-value,
              .value {
                color: $negative-500;
                cursor: pointer;
              }
            }

            .merging-new .field {
              .field-value,
              .value {
                color: $success-500;
                cursor: pointer;
              }
            }

            .merging-normal,
            .merging-new,
            .merging-conflict {
              position: relative;

              &:not(:last-child) {
                margin-bottom: 4px;
              }

              .field:not(:last-child) {
                margin-bottom: 0;
              }
            }

            .field {
              &:not(:last-child) {
                margin-bottom: 8px;
              }

              &.sub-field:not(:last-child) {
                margin-bottom: 24px;
              }

              & > svg {
                margin-right: 4px;
              }

              .field-name {
                margin-right: 4px;
              }

              .field-value {
                color: $neutral-black;
                font-weight: 600;

                &.no-name {
                  font-weight: 400;
                }
              }

              .sub-field {
                &:not(:last-child) {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }

      &.one-column {
        .section-column {
          .biography-section {
            width: 680px;

            .section-fields-list {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              width: 100%;

              .merging-normal,
              .merging-conflict,
              .merging-new {
                width: 100%;

                .field {
                  width: 100% !important;
                }
              }

              .field {
                width: 50%;

                @include media-breakpoint-down(sm) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .btn.custom-button {
      justify-content: flex-start;
      padding-bottom: 18px;
      padding-top: 0;
    }
  }
}
