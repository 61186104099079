.oauth-page {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 60px auto;
  padding: 40px;
  background-color: $neutral;
  border-radius: 5px;
  text-align: center;

  h1 {
    font-size: 40px;
  }

  p {
    font-size: 18px;
  }
}
