.children-section {
  &::after {
    border-bottom: none !important;
  }

  .calculation-row {
    align-items: center;
  }

  .claim-benefits-btn {
    .btn {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .children-slider-container {
    width: 100%;
    display: flex;

    .children-slider {
      display: flex;
      width: 100%;
      height: 100%;

      &.children-0 {
        max-width: 0;
        margin-right: 0;
      }

      &.children-1 {
        max-width: 164px;
      }

      &.children-2 {
        max-width: 328px;
      }

      &.children-3 {
        max-width: 492px;
        min-width: 492px;
      }

      &.children-list {
        max-width: 476px;
      }

      .slick-list {
        min-width: 100%;

        .slick-track {
          display: flex;
          align-items: flex-start;
          width: 100%;

          .slick-slide {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .child {
              padding: 8px 12px 8px 8px;
              width: 148px;
              min-width: 148px;
              display: flex !important;
              align-items: center;
              border: 1px solid transparent;
              border-radius: 4px;

              transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

              &:hover {
                border-color: $neutral-300;
                background-color: $neutral-100;
              }

              &.active {
                border-color: $primary-500;

                .name {
                  color: $primary-500 !important;
                }
              }

              .child-avatar {
                margin-right: 12px;
              }

              .child-description {
                display: flex;
                flex-direction: column;

                .name {
                  color: $neutral-black;
                  margin-bottom: 2px;
                  display: inline-block;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19px;
                }

                .short-info {
                  color: $neutral-500;
                  font-size: 12px;
                  line-height: 14px;

                  small {
                    color: $neutral-500 !important;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      &.one-slide {
        .slick-track {
          margin-left: 0;
        }
      }
    }

    .add-child {
      padding-right: 40px;
      width: calc(100% - 20px);

      .btn.custom-button {
        height: 56px;
        width: 100%;
        min-width: 148px;
      }
    }

    .slider-buttons {
      display: flex;
      align-items: center;
      margin-right: -20px;
      position: relative;
      z-index: 2;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        width: 32px;
        height: 32px;
        border: 1px solid $neutral-200;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.15s ease-in-out;

        &:first-child {
          margin-right: 12px;

          svg {
            transform: rotate(-90deg);
          }
        }

        &:last-child {
          svg {
            transform: rotate(90deg);
          }
        }

        &:hover:not(:disabled),
        &:active:not(:disabled),
        &:focus:not(:disabled) {
          background-color: $neutral-200;
        }
      }
    }
  }
}
