.clients-search {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  margin: 0 16px 8px;
}

.clients-filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  .custom-filter-group {
    margin: 0;
  }

  .filter-sort-controls {
    font-size: 14px;

    .dropdown-toggle {
      color: #666;
      cursor: pointer;
      display: flex;
      align-items: center;
      
      &:hover {
        color: #333;
      }
    }

    .dropdown-menu {
      font-size: 14px;
      min-width: 160px;
      
      .dropdown-item {
        padding: 8px 16px;
        
        &.active {
          background-color: #f8f9fa;
          color: #333;
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .clients-filter-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

/* Mobile screens */
@media (max-width: 576px) {
  .clients-filter-row {
    max-height: 30px;
    min-height: 30px;
    overflow: visible;
    flex-direction: row;
    align-items: center;

    .filter-sort-controls {
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}
