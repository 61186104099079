@mixin roboto-sm-link($color: $link-500, $font-weight: 500, $line-height: 16px) {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
  font-weight: $font-weight !important;
  font-size: 14px !important;
  line-height: $line-height !important;
  color: $color !important;
}

@mixin roboto-md($font-weight: 600, $line-height: 24px) {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
  font-weight: $font-weight !important;
  font-size: 16px !important;
  line-height: $line-height !important;
}
