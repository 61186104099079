.plans {
  width: 100%;
  margin: 0 auto;
}

.plans-cards {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  &.pro {
    margin-bottom: 48px;
  }

  @include media-breakpoint-down(sm) {
    align-items: center;
    flex-direction: column;
  }
}

.plans-toggle {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.plans-counter {
  flex-shrink: 0;

  display: inline-flex;
  align-items: center;
  gap: 20px;

  padding: 4px 4px 4px 20px;
  border-radius: 12px;
  background-color: $white;

  &__text {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: $cool-gray-900;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    justify-content: space-between;
  }
}

@import 'current-plan';
@import 'client-plan';
@import 'pro-plan';
@import 'control';
