.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 50px;
  width: 50px;
  @include border-radius(0.25rem);

  &-sm {
    width: 31.25px;
    height: 31.25px;
    font-size: 0.75rem;
    border-radius: 0.2rem;
  }

  img {
    width: 100%;
    @include border-radius(0.25rem);
  }

  &.rounded-circle {
    img {
      border-radius: 50%;
    }
  }

  span {
    background-color: theme-color('primary');
  }

  + .avatar {
    margin-left: 0.25rem;
  }

  + .avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
  }
}

.avatar-selector {
  position: relative;
  max-width: 75px;
  max-height: 75px;

  img {
    max-width: 75px;
    max-height: 75px;
    object-fit: contain;
  }

  &:hover .btn-dashed-link {
    border-color: $primary-500 !important;
    color: $primary-500 !important;
  }

  .dropzone-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    outline: 0;
    z-index: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: $transition-base;
    border-radius: 50%;

    .text-container {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: $transition-base;

      .btn {
        border: 0;
        background-color: transparent;
        box-shadow: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      .update-user-image {
        font-size: 30px;
      }
    }
  }

  &:hover:not(.disabled) {
    .dropzone-container {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);

      .text-container {
        opacity: 1;
      }
    }
  }

  &.profile-avatar-selector {
    &:hover {
      .dropzone-container {
        z-index: 1;
        background-color: transparent;

        .text-container {
          opacity: 1;
        }
      }
    }
  }

  &.disabled {
    cursor: 'not-allowed';
    opacity: 0.9;
    pointer-events: none;

    .dropzone-container {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);

      .text-container {
        opacity: 1;
      }
    }
  }

  &.disabled.profile-avatar-selector {
    .dropzone-container {
      background-color: transparent;
    }
  }
}
