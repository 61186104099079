@import 'footer';
@import 'header';

.page-content {
  min-height: 100vh;
  position: relative;

  .main-section {
    & > .slice {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 30% -138px;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        background-color: $gray-900;
        opacity: 0.8;
      }
    }
  }

  .authorization-alert {
    border-radius: 4px 4px 0 0;
    margin: 25px 0 0 0;
  }
}

.client-type {
  padding: 2px 8px;
  font-size: 12px;
  line-height: 17px;
  border-radius: 4px;
  color: $white;

  &.retained {
    background-color: #34c342;
  }

  &.potential {
    background-color: #fdb833;
  }

  &.not-retained {
    background-color: #ec2b2b;
  }

  &.closed {
    background-color: #898989;
  }
}
