.loading {
  &.page {
    width: 100%;
    min-height: 60vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.calculation {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &.table-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .dots-container {
    width: 100%;
    max-width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      animation: loading 1.2s infinite;
      margin-right: 5px;
      background-color: $primary;

      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
      &:nth-child(4) {
        animation-delay: 0.3s;
      }
      &:nth-child(5) {
        animation-delay: 0.4s;
      }
    }
  }

  p {
    margin: 0;
    margin-top: 5px;
    font-size: 14px;
    text-align: left;
  }
}

@keyframes loading {
  0% {
    //   background-color: #66C6FF;
    transform: scale(1);
  }
  50% {
    //   background-color: #6ACD7F;
  }
  100% {
    //   background-color: #6DD400;
    transform: scale(0.3);
  }
}
