.modal {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 420px;
    }

    .modal-content {
      position: relative;
      border-radius: 8px;

      .modal-header {
        display: flex;
        flex-direction: column;
        border: 0;
        padding: 24px 32px 0;

        .modal-title {
          line-height: 24px;
        }

        .modal-description {
          margin-top: 10px;
          margin-bottom: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
        }

        &.with-close {
          position: relative;

          .modal-close {
            position: absolute;
            top: 22px;
            right: 26px;
            padding: 0;
            width: 14px;
            height: 14px;
            background-color: transparent;
            border: 0;
            opacity: 0.2;
            transition: opacity 0.2s ease;
            overflow: unset;
            color: transparent;

            &:hover {
              opacity: 0.8;
              background-color: transparent;
            }

            &:before,
            &:after {
              position: absolute;
              content: '';
              height: 14px;
              width: 2px;
              top: 35%;
              left: 50%;
              background-color: #333;
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .modal-body {
        position: initial;
        padding: 24px 32px 0;

        &.no-footer {
          padding-bottom: 32px;
        }

        .delete-btn {
          position: absolute;
          bottom: 33px;
          right: 33px;
          padding: 0;
          height: 24px;
        }
      }

      .modal-footer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        border: 0;
        padding: 40px 32px 24px;

        .btn {
          margin-top: 0;
          margin-bottom: 0;
          height: 42px;
          padding: 11px 24px;
          font-size: 16px;
          line-height: 19px;

          &:nth-child(1) {
            font-size: 16px;
            line-height: 19px;
            color: $white;
            box-shadow: 0px 6px 12px -6px rgba(98, 39, 245, 0.12),
              0px 8px 24px -4px rgba(98, 39, 245, 0.08);
            border-radius: 4px;

            &:hover {
              text-decoration: none;
            }
          }

          &:nth-child(2) {
            margin-left: 8px;
            text-decoration: none;
            color: $primary;
            background-color: $primary-100;
            font-weight: 400;
            border-color: $primary-200;

            &:hover {
              background-color: #efe4fe !important;
            }
          }

          &:first-child {
            margin: 0;
          }
        }

        @include media-breakpoint-only(xs) {
          .btn {
            padding: 8px 10px;
            width: calc(50% - 16px);
          }
        }
      }
    }
  }
}

@import 'upgrade-plan';
@import 'upload-image-modal';
@import 'possession-modal';
@import 'possession-valuation-modal';
@import 'inform-wopi-modal';
@import 'inform-download-modal';
@import 'plan-settings-modal';
@import 'mfa-modal';
