.interview-container {
  padding-top: 36px;

  .interview-section-container {
    &:not(:last-child) {
      margin-bottom: 32px;
      padding-bottom: 32px;
      position: relative;

      &::after {
        position: absolute;
        bottom: -32px;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 32px;
        background-image: linear-gradient(to right, white 60%, $neutral-300 0%);
        background-position: bottom;
        background-size: 15px 1px;
        background-repeat: repeat-x;
      }
    }

    &:last-child {
      padding-bottom: 20px;
    }

    .interview-section {
      display: flex;
      color: $neutral-500;
      font-size: 14px;
      line-height: 16px;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }

      &:not(:last-child) {
        padding-bottom: 24px;
      }

      .section-name {
        min-width: 156px;
        margin-right: 23px;

        @include media-breakpoint-down(xs) {
          margin-bottom: 10px;
        }
      }

      .section-fields-list {
        .field {
          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &.sub-field:not(:last-child) {
            margin-bottom: 24px;
          }

          & > svg {
            margin-right: 4px;
          }

          .field-name {
            margin-right: 4px;
          }

          .field-value {
            color: $neutral-black;
            font-weight: 600;
            padding-right: 8px;

            &.error {
              color: $negative-500;

              &:hover {
                text-decoration: underline;
              }
            }

            &.no-name {
              font-weight: 400;
            }
          }

          .field-value-description {
            margin-left: -4px;
          }

          .sub-field {
            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }

          .btn {
            padding: 0;
            background-color: transparent;
            color: $link-500;
            border: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            &.delete {
              color: $red;

              &:hover {
                color: #c7303f;
              }
            }
          }
        }
      }
    }
  }
}
