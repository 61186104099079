$max-width-content: 1140px;

$primary: #6e00ff;
$secondary: #eff2f7;
$black: #212529;

$primary-50: #f8f2ff;
$primary-100: #f1e6ff;
$primary-200: #e2ccff;
$primary-300: #c599ff;
$primary-400: #9a4dff;
$primary-500: #6e00ff;
$primary-600: #6300e6;
$primary-700: #5800cc;
$primary-800: #4d00b3;
$primary-900: #370080;

$neutral: #ffffff;
$neutral-100: #fafafa;
$neutral-150: #cdd4de;
$neutral-200: #e9eaea;
$neutral-300: #d3d4d5;
$neutral-400: #bcbfc1;
$neutral-500: #7a7e82;
$neutral-600: #64696d;
$neutral-700: #4d5359;
$neutral-800: #373e44;
$neutral-black: #21282f;

$link-100: #d8e4fd;
$link-200: #bfd5ff;
$link-300: #89affa;
$link-400: #6295f8;
$link-500: #3b7af6;
$link-600: #356edd;
$link-700: #2955ac;
$link-800: #1e3d7b;
$link-900: #12254a;

$success-50: #f0fdf4;
$success-100: #d2ffe7;
$success-200: #d6f3d9;
$success-400: #4ade80;
$success-500: #34c342;
$success-600: #2fb03b;
$success-800: #1a6221;

$negative-100: #fbd5d5;
$negative-200: #f7aaaa;
$negative-300: #f48080;
$negative-400: #f05555;
$negative-500: #ec2b2b;
$negative-600: #bd2222;
$negative-700: #8e1a1a;
$negative-800: #5e1111;
$negative-900: #2f0909;

$cool-gray-50: #f9fafb;
$cool-gray-100: #f3f4f6;
$cool-gray-200: #e5e7eb;
$cool-gray-400: #9ca3af;
$cool-gray-500: #6b7280;
$cool-gray-900: #111827;

$red-50: #fef2f2;
$red-400: #f87171;
$red-700: #b91c1c;

$warning-500: #fdb833;
$warning-600: #e4a62e;

$border-color: $secondary;
$border-color-accent: #e4e4f1;
$border-focus-color: transparentize($primary, 0.6);
$hover-border: darken($border-color, 30%);

$btn-primary: $primary;
$btn-primary-hover: #5e00d9;
$btn-primary-active: #5800cc;
$btn-border-hover: $btn-primary-active;
$btn-border-active: #5300bf;

$btn-secondary-focus: #cdd6e6;
$btn-secondary-border-color: #c5cfe2;

$custom-box-shadow: inset 0 1px 0 transparentize($white, 0.85);

$shadow-sm: 1px 2px 10px rgba(0, 0, 0, 0.1);
$shadow-400: 0px 7px 24px -4px rgba(24, 39, 75, 0.04), 0px 2px 12px -6px rgba(24, 39, 75, 0.08);

$error: #ff3b30;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);
