.react-datepicker-wrapper {
  display: block !important;

  .custom-date-picker {
    display: block;
    padding: 0.75rem 1.25rem;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $gray-700;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;

    &:focus,
    &.react-datepicker-ignore-onclickoutside {
      box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075), 0 0 20px rgba(110, 0, 255, 0.1);
      color: $gray-700;
      background-color: $white;
      border-color: $border-focus-color;
    }

    &.is-invalid {
      border-color: $error;
    }

    &:disabled {
      border-color: $gray-400;
      background-color: $neutral-100;
    }
  }

  & + .react-datepicker__tab-loop {
    .react-datepicker-popper {
      z-index: 20;
    }

    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
      border-top-color: $white !important;

      &::before,
      &::after {
        left: -33px;
      }
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
      border-bottom-color: $white !important;

      &::after {
        border-bottom-color: $white !important;
      }
    }

    .react-datepicker__header {
      padding-top: 4px;
      background-color: $white;

      .react-datepicker__current-month {
        display: none;
      }
    }

    .react-datepicker__navigation {
      top: 7px;

      &:focus {
        outline: none;
      }
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select {
      margin: 0 2px;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      padding: 2px 3px;
      margin-top: 2px;
      border-color: $gray-400;
      border-radius: 4.8px;
      outline: none;
      background-color: $white;
    }

    .react-datepicker__month {
      margin: 6.4px;
    }

    .react-datepicker__day {
      width: 27.2px;
      line-height: 27.2px;

      &-name {
        width: 27.2px;
        line-height: 27.2px;
      }

      &--selected,
      &--keyboard-selected {
        background-color: $primary;
        outline: none;
        color: $white;
      }
    }
  }
}
