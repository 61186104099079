.custom-switch {
  padding-left: 0;

  .custom-control-label {
    width: 100%;
    cursor: pointer;

    &::before {
      left: 0;
    }

    &::after {
      left: 2px;
    }
  }
}
