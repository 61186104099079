.document-card {
  padding: 24px 0 36px;

  @include media-breakpoint-down(xs) {
    padding: 24px 0;
  }

  .loading {
    margin: 0 auto;
    width: max-content;
  }

  .list-group-item {
    &:nth-child(odd) {
      background-color: #fafafa;
    }

    &:nth-child(even) {
      background-color: unset;
    }

    .list-line {
      &::after {
        left: -24px;
        right: -24px;
      }
    }

    &.highlighted {
      animation: highlight 2s ease-out;
      animation-delay: 0.1s;
    }

    .calculation-link {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      &.--bg {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;

        border-radius: 6px;
        background-color: $link-200;
      }
    }

    .document-actions {
      display: flex;

      .arrow-down-btn {
        cursor: pointer;
        margin-left: 24px;
      }
    }
  }

  @keyframes highlight {
    0% {
      background-color: transparentize($primary, 0.8);
    }
    100% {
      background-color: $neutral-100;
    }
  }

  .buttons-group {
    display: flex;
    align-items: center;
    justify-content: left;

    margin-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    .upload-document {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-left: 48px;

      @include media-breakpoint-down(xs) {
        margin-top: 8px;
        margin-left: 0;
        padding-right: unset;
      }
    }

    .new-document {
      @include media-breakpoint-down(xs) {
        margin-left: unset;
      }

      font-weight: 400;
      height: 24px;
      padding: 0;
      font-size: 14px;

      @include media-breakpoint-down(xs) {
        margin-top: 12px;
      }

      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .no-documents {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 16px;
    text-align: center;

    @include media-breakpoint-down(xs) {
      margin-bottom: 16px;
    }

    svg {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
  }

  .document-content {
    @include media-breakpoint-down(lg) {
      margin: 0 -16px;
    }

    & > .section-columns {
      padding-top: 30px;
    }

    .biography-container {
      @include media-breakpoint-down(lg) {
        padding: 0 16px 0;
      }

      .biography-section-container {
        .section-columns {
          .section-name {
            min-width: 140px;
          }
        }
      }
    }

    .finances-container {
      padding: 0;
      margin-top: 20px;

      &.disabled {
        .finances-section {
          &.budget-section-form {
            .category-item:hover {
              text-decoration: none !important;
              cursor: initial !important;
            }

            .category-item {
              margin-bottom: 0;

              &:last-child {
                margin-bottom: 8px;
              }

              .item-values {
                .merging-conflict,
                .merging-new,
                .merging-normal {
                  margin-bottom: 4px;
                }
              }
            }
          }

          .section-column {
            &.select:hover {
              cursor: text !important;
              text-decoration: none !important;
            }

            &.title {
              .field.text-underline:hover,
              .field.text-underline:hover .name,
              .date-picker-btn {
                cursor: text !important;
                text-decoration: none !important;
              }
            }

            .custom-select-input {
              .custom-select__indicators {
                display: none;
              }

              .custom-select__single-value--is-disabled {
                color: $neutral-black;
              }
            }

            .income {
              &:hover {
                text-decoration: none;
              }
            }
          }

          .merging-normal,
          .merging-new,
          .merging-conflict {
            position: relative;
            line-height: 16px;
          }

          .merging-conflict {
            .value {
              color: $negative-500 !important;
            }
          }

          .merging-new {
            .value {
              color: $success-500 !important;
            }
          }

          .merging-normal,
          .merging-conflict,
          .merging-new {
            cursor: pointer;

            margin-bottom: 4px;

            .field.pb-1 {
              padding-bottom: 0 !important;
            }

            &:last-child {
              margin-bottom: 0;
            }

            & + .ms-layer {
              display: none;
            }
          }
        }
      }
    }
  }
}

.merge-buttons {
  display: flex;
  align-items: center;
}

.document-badges-container {
  display: flex;
  align-items: center;
}

.document-outdated-badge {
  border: 1px solid $neutral-black;
  background: $neutral-100;
  color: $neutral-black;
}

.document-fields-badge {
  border: 1px solid $success-400;
  background: $neutral-100;
  color: $success-800;
}

.document-conflicts-badge {
  border: 1px solid $negative-400;
  background: $neutral-100;
  color: $negative-400;
}

.document-failed-badge {
  border: 1px solid $red-400;
  background: $red-50;
  color: $red-700;
}

.document-fields-badge,
.document-conflicts-badge,
.document-outdated-badge,
.document-failed-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;

  border-radius: 4px;
}

/* ===================
  Document Item
  ================= */
.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  &__download {
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    background: none;

    width: 40px;
    height: 40px;
    padding: 12px;

    border-radius: 8px;
    border: 1px solid $neutral-300;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: $neutral-200;
    }

    &:focus,
    &:active {
      background-color: $neutral-300;
    }

    svg {
      color: $neutral-black;
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    row-gap: 4px;
    column-gap: 12px;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 4px;

    text-align: left;

    padding: 0;
    outline: none;
    border: none;
    border: none;
    background: none;

    color: $link-500;

    &:hover {
      text-decoration: underline;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;

      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }

    svg {
      flex-shrink: 0;
      color: #494949;
    }
  }

  &__badges {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__meta-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
  }

  &__meta-separator {
    display: inline-block;
    width: 4px;
    height: 4px;

    background-color: $neutral-300;
    border-radius: 50%;
  }

  &__meta {
    position: relative;
  }

  &__other {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 40px;
  }

  &__analyzer {
    display: flex;
    align-items: center;
    gap: 12px;

    &-loader {
      color: $neutral-black;
    }

    &-text {
      color: $neutral-black;

      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__analyzed {
    display: flex;
    align-items: center;
    gap: 8px;

    &-icon {
      color: $success-600;
    }
  }
}

@include media-breakpoint-down(sm) {
  .document-item {
    &__other {
      gap: 12px;
    }
  }
}

/* ========================
  Document Content
  ======================= */
.document-content {
  &__analyzing {
    width: 100%;
    max-width: 404px;
    margin: 0 auto;
    text-align: center;

    &-lottie {
      svg {
        width: 200px !important;
        height: 200px !important;
      }
    }

    &-title {
      color: $neutral-black;
      text-align: center;

      font-family: DM Sans;
      font-size: 20px;
      font-weight: 700;
      line-height: 120%;
    }

    &-paragraph {
      color: $neutral-600;

      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
    }
  }
}
