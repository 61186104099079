.get-legal-help-section {
  width: 100%;

  .get-legal-help {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      width: calc(100% - 40px);
      height: 1px;
      background-color: $neutral-200;
    }

    .legal-help-section-header {
      display: flex;
      justify-content: space-between;

      .section-title {
        padding: 31px 20px 0;

        .title {
          font-size: 20px;
          font-weight: 700;
          line-height: 23px;
          margin-bottom: 4px;
        }

        .subtitle {
          color: $neutral-500;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 30px;
        }
      }

      .slider-buttons {
        display: flex;
        align-items: center;
        padding-right: 20px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          width: 32px;
          height: 32px;
          border: 1px solid $neutral-200;
          border-radius: 50%;
          background-color: transparent;
          transition: background-color 0.15s ease-in-out;

          &:first-child {
            margin-right: 12px;
          }

          &:hover,
          &:active,
          &:focus {
            background-color: $neutral-200;
          }
        }
      }
    }

    .professionals-slider-container {
      display: flex;
      flex-wrap: nowrap;
      width: 120%;
      padding-left: 20px;

      @include media-breakpoint-down(lg) {
        width: 135%;
      }

      @include media-breakpoint-down(xs) {
        width: 101%;
      }

      .professionals-slider {
        display: flex;
        width: 100%;
        height: 100%;

        .slick-list {
          min-width: 100%;
        }

        .slick-track {
          display: flex;
          align-items: flex-start;

          .slick-slide {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        &.one-slide {
          .slick-track {
            margin-left: 0;
          }
        }
      }
    }

    .links {
      margin-top: 32px;
      padding: 0 20px;

      a {
        font-size: 14px;
        line-height: 18px;

        @include media-breakpoint-down(xs) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  &.is-calculated {
    position: relative;
    z-index: 1;
    margin-top: -40px !important;

    .get-legal-help {
      &::before {
        @include media-breakpoint-down(xs) {
          left: 10px;
          right: 10px;
          width: calc(100% - 20px);
        }
      }

      .legal-help-section-header {
        .section-title {
          @include media-breakpoint-down(xs) {
            padding: 31px 10px 0;
          }
        }

        .slider-buttons {
          @include media-breakpoint-down(xs) {
            padding-right: 10px;
          }
        }
      }

      .professionals-slider-container {
        @include media-breakpoint-down(xs) {
          padding-left: 10px;
        }
      }

      .links {
        @include media-breakpoint-down(xs) {
          padding: 0 10px;
        }
      }
    }
  }
}
