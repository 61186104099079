.client-calculations-card,
.previous-calc-list,
.calculations-list {
  padding: 32px 0 48px;

  & > .previous-calc-list {
    padding: 0;

    & + .new-calculate-dropdown {
      margin-top: 24px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px 0 16px;
  }

  .list-group-item {
    border: 0;
    padding: 12px 24px !important;

    &:nth-child(even) {
      background-color: #fafafa;
    }

    .generate-pdf {
      .btn-red-link {
        margin-top: 24px;
        padding: 0;
        height: 24px;
        padding-left: 0;
      }

      .dashed-divider {
        width: 100%;
        height: 1px;
        margin: 32px 0;
        background-image: linear-gradient(to right, white 60%, $neutral-300 0%);
        background-position: bottom;
        background-size: 15px 1px;
        background-repeat: repeat-x;
      }

      .report-info {
        .report-title,
        .report-description {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          margin-bottom: 8px;
          color: $neutral-500;
        }

        .report-description {
          margin-bottom: 0;
        }
      }

      .calculator-section-container {
        margin-top: 0;
        background-color: transparent;
        box-shadow: none;
        margin-bottom: 8px;
        padding-bottom: 0;

        .share-calculation {
          padding: 0;
          background-color: transparent;
          margin-top: 32px;
          margin-bottom: 0;

          .share-calculation-row {
            border-top: none !important;
            padding-top: 0 !important;

            .share-calculation-form {
              & > .row {
                flex-direction: column !important;

                & > div {
                  width: 100%;
                  max-width: 100% !important;

                  .form-row {
                    flex-direction: row !important;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    margin: 0;

                    .form-group {
                      margin-bottom: 0;
                    }

                    .btn {
                      padding: 9px 12px 9px 16px;

                      @include media-breakpoint-down(sm) {
                        padding-left: 12px;

                        span {
                          display: none;
                        }

                        svg {
                          margin-left: 0;
                        }
                      }
                    }
                  }

                  .description {
                    line-height: 16px;
                    margin-bottom: 32px;

                    p {
                      margin-bottom: 8px;
                      font-size: 12px;
                      line-height: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      & > img {
        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }
    }

    .btn-red-link {
      font-weight: 400 !important;
      margin-bottom: 16px;
    }
  }

  .new-calculate-dropdown {
    display: flex;
    justify-content: center;

    .btn {
      display: flex;
      justify-content: center;
      background-color: transparent;
      height: 24px !important;
      border: 0;
      padding: 0;
      color: $link-500;
      font-size: 14px;
      font-weight: 400;
      margin-top: 32px;

      &:hover:not(:disabled) {
        color: $primary-500;
        background-color: transparent;
        border-color: transparent;
      }

      &:active:not(:disabled),
      &:focus:not(:disabled) {
        color: $primary-900;
        background-color: transparent;
        border-color: transparent;
      }

      @include media-breakpoint-down(sm) {
        margin: 16px 0;
      }

      &:focus {
        box-shadow: none;
      }

      &::after {
        display: none;
      }

      & > svg {
        width: 20px;
        height: 20px;

        &[name='arrow-down'] {
          display: none;
        }
      }
    }

    p {
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
        font-size: 27px;
        line-height: 19px;
        margin-top: -5px;
        font-weight: 300;
      }
    }
  }

  .no-calculations {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 16px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 12px;
    }

    svg {
      margin-bottom: 24px;
    }

    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
  }
}

.client-calculations-card {
  @include media-breakpoint-down(xs) {
    padding: 16px 0;
  }

  .previous-calc-list {
    .list-group-item {
      @include media-breakpoint-down(xs) {
        padding: 12px !important;
      }

      .list-line {
        @include media-breakpoint-down(xs) {
          &::after {
            position: absolute;
            content: '';
            top: -12px;
            right: -12px;
            left: -12px;
            bottom: -12px;
            cursor: pointer;
            z-index: 0;
          }
        }
      }

      & > .row {
        justify-content: space-between !important;
      }
    }
  }

  .new-calculate-dropdown {
    .btn {
      margin-top: 0;
    }
  }
}
