@import 'income-section-form';
@import 'property-and-debt-section-form';
@import 'child-expenses-section';
@import 'budget-section';
@import 'income-earners';

.finances-container {
  padding: 41px 24px 34px;

  .finances-section {
    display: flex;
    margin-bottom: 35px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    .section-name-column {
      width: 120px;
      min-width: 120px;

      @include media-breakpoint-down(lg) {
        padding-left: 16px;
      }

      .section-name {
        color: $neutral-500;
        font-size: 14px;
        line-height: 16px;

        @include media-breakpoint-down(lg) {
          font-weight: 500;
          color: $neutral-black;
        }
      }
    }

    .section-columns-container {
      width: 100%;

      .section-columns {
        width: 100%;
        display: flex;

        .section-column {
          width: 340px;

          &:last-child {
            background-color: transparentize($link-800, 0.97);
          }

          .sub-section {
            padding: 16px;
            min-height: 24px;

            .hint {
              color: $neutral-500;
              font-size: 12px;
              line-height: 16px;
              max-width: 188px;
              margin-bottom: 0;
              margin-top: 4px;

              &.long {
                max-width: 290px;
              }
            }
          }

          .btn.custom-button {
            padding: 0;
            height: 20px;
            font-size: 14px;
            line-height: 16px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .field {
        display: flex;
        .name,
        .value {
          font-size: 14px;
          line-height: 16px;
          color: $neutral-500;
        }

        .name {
          white-space: nowrap;
          margin-right: 8px;
        }

        .value {
          font-weight: 500;
          color: $neutral-black;

          &.type-modal {
            color: $link-500;
            cursor: pointer;
          }
        }
      }

      .custom-input.select-input {
        width: auto !important;
        min-width: 110px;

        .custom-select-input {
          .custom-select__control {
            min-height: 16px;
            height: 16px;
            border: 0;
            background-color: transparent;

            .custom-select__value-container {
              padding: 0;
              height: 16px;

              .custom-select__single-value {
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
              }

              & > div:last-of-type {
                margin: 0;
                padding: 0;
                height: 16px;

                .custom-select__input {
                  height: 16px;
                  display: block !important;
                  margin-top: -8px;
                  font-size: 14px !important;
                  font-weight: 500 !important;

                  input {
                    height: 16px;
                  }
                }
              }

              .custom-select__placeholder {
                height: 16px;
                line-height: 16px;
                font-size: 14px;
                font-weight: 500;
                color: $gray-700;
              }
            }

            .custom-select__indicators {
              height: 16px;
              padding: 0;
              width: 12px;

              .custom-select__indicator {
                width: 16px;
                padding: 0;

                svg {
                  fill: $neutral-black !important;
                }
              }
            }
          }

          .custom-select__menu {
            z-index: 200;
            .custom-select__option {
              padding: 6px 12px;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }
      }

      .react-datepicker-wrapper {
        height: 16px;
        line-height: 16px;
        width: 100%;

        .date-picker-btn {
          @include clear-btn-styles();

          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
}
