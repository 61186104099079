@import 'sidebar';
@import 'profile';
@import 'pro-profile';
@import 'organization';
@import 'profile-card';
@import 'profile-header';
@import 'reviews';
@import 'review-modal';
@import 'counsel-form';

.view-profile-page {
  margin-top: 50px;
}

.client-overview {
  margin-top: 27px;

  .calculations-card {
    @include media-breakpoint-down(xs) {
      margin-left: 15px;
      margin-right: 15px;
    }

    .welcome-card {
      padding: 12px 20px;
    }

    .previous-calc-list {
      border: 0;
    }
  }

  .sidebar-item {
    @include media-breakpoint-down(xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
