.options-form-section {
  padding: 24px 12px;
  display: flex;
  flex-wrap: wrap;

  .form-group {
    width: 25%;
    padding: 0 12px;
  }
}
