.relationship-section {
  &::after {
    border-top: 1px dashed $neutral-200;
    border-bottom: none !important;
    top: 0;
    height: 1px;
  }

  &-row {
    width: 100%;

    &-content {
      display: flex;
      align-items: center;

      .avatar {
        width: 40px;
        height: 40px;
      }

      .avatar-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          color: $neutral-black;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          margin-bottom: 2px;
        }

        .description {
          color: $neutral-500;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .relationship-form {
    .right-col {
      padding-left: 0;
      margin-left: -5px;
    }

    .form-group {
      max-width: 279px;
    }
  }
}
