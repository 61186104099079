.upgrade-page {
  position: relative;

  width: 100%;
  min-height: calc(100vh - 650px);
  padding: 50px 0 80px;

  background-image: url(./../../../img/backgrounds/bg-park.webp);
  background-size: 100%;
  background-position: 30% -77px;

  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparentize($gray-900, $amount: 0.4);
  }

  @include media-breakpoint-down(lg) {
    background-position: 30% -92px;
  }

  @include media-breakpoint-down(xs) {
    min-height: calc(100vh - 233px);
  }

  &__header {
    h1 {
      text-align: center;
      margin: 0 auto 8px;
    }

    p {
      width: 100%;
      max-width: 540px;
      text-align: center;
      margin: 0 auto 30px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $secondary;
    padding: 20px;

    h6 {
      text-align: center;
      margin-bottom: 12px;
    }

    p {
      text-align: center;
      width: 100%;
      max-width: 756px;
      margin: 24px auto;
    }
  }

  &__inner {
    position: relative;
    z-index: 3;

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 40px 32px;

    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    border-radius: 0.25rem;

    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: $shadow-400;
  }

  &__description {
    color: #6c757d;
    margin-bottom: 0 !important;
  }
}
