@import 'calculations';
@import 'proCalculation';
@import 'sections';
@import 'sidebar';
@import 'children';
@import 'incomes';
@import 'tax';
@import 'background';
@import 'support-report';
@import 'result';
@import 'shared-calculation-notification';
@import 'share-calculation';
@import 'legalHelp';

@import 'calculate-buttons';

.calculator-main {
  &.loading {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 15px;
      right: 15px;
      bottom: 0;

      background-color: $white;
      opacity: 0.4;
      z-index: 100000;
    }
  }
}

.calculator-main.filled {
  margin-top: 24px;
  margin-bottom: 3rem;

  @include media-breakpoint-down(xs) {
    padding-right: 2px !important;
  }

  .calculator-section-container {
    margin-top: 0;
    border-radius: 0;

    .section-header {
      border-radius: 0;
    }
  }
}

.select-row {
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  & > div:first-of-type {
    max-width: 220px;
  }
}

.angle-btn {
  position: relative;
  width: 48px;
  height: 48px;
  top: 13px;
  left: 10px;
  cursor: pointer;

  @include media-breakpoint-down(xs) {
    min-width: 48px;
  }

  i {
    position: absolute;
    left: calc(24px - 5px);
    top: calc(24px - 8px);
  }
}

.calculation-select-mobile {
  .calculation-select__option:not(.calculation-select__option--is-disabled) {
    cursor: pointer;
  }

  .upgrade {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      width: 16px;
      height: 16px;
      background: url(../../../img/icons/upgrade.svg) no-repeat center;
      transform: translateY(-50%);
    }

    .calculation-select__option {
      padding: 8px 36px 8px 12px;
    }
  }
}

.calculation-select {
  width: 100%;
  display: none !important;

  @include media-breakpoint-up(sm) {
    display: block !important;
  }
}

.result-section {
  background-color: white;
  padding: 20px;
  @include media-breakpoint-down(xs) {
    padding: 20px 10px;
  }
}

.row-collapse-content {
  padding: 1rem;
  @include media-breakpoint-down(xs) {
    padding: 0.25rem;
  }
}

.tax-results-row {
  margin-left: 10px;
  @include media-breakpoint-down(xs) {
    max-width: 100%;
    margin-left: 0px;
  }
  thead th:first-of-type {
    padding-left: 2px;
  }
  th {
    padding-left: 2px;
  }
  table,
  th,
  tr,
  td {
    padding: 2px;
    border-top: none;
    text-align: left;
  }
  th:first-of-type {
    @include media-breakpoint-down(xs) {
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  td {
    @include media-breakpoint-down(xs) {
      max-width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tax-section {
    font-weight: bold;
    padding-left: 2px !important;
  }
  .tax-row {
    font-weight: normal;
    padding-left: 12px !important;
    @include media-breakpoint-down(xs) {
      padding-left: 5px;
    }
  }
  .tax-total {
    font-weight: bold;
    padding-left: 2px !important;
  }
  .tax-subtotal {
    font-style: italic;
    font-weight: normal;
    padding-left: 12px !important;
    @include media-breakpoint-down(xs) {
      padding-left: 5px !important;
    }
  }
}

.calculator-section.spousal-section {
  .table > tbody > tr > td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
