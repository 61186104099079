.upgrade-account-cta {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  gap: 48px;
  transform: translateX(-50%);
  width: 100%;
  min-height: 362px;
  max-width: 783px;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
  padding: 47px 64px 47px 48px;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);

  .images-container {
    width: 50%;
    position: relative;

    img {
      position: absolute;
      width: 250px;
      height: auto;
      border-radius: 8px;
      box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);

      &:first-child {
        left: 0;
        top: 0;
      }

      &:last-child {
        left: 63px;
        top: 86px;
      }
    }
  }

  .description-container {
    width: 50%;

    .title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
      margin-top: 17px;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      color: $neutral-500;
      margin-bottom: 32px;
    }

    .buttons {
      display: flex;

      .btn {
        &:first-child {
          margin-right: 24px;
        }

        &:last-child {
          font-weight: 400;
        }
      }
    }
  }
}
