.counsel-form {
  .select-input {
    .custom-select__menu {
      .custom-select__option {
        .custom-option {
          .option-text {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
          }

          .option-description {
            display: flex;
            flex-wrap: wrap;
            margin-top: 4px;

            span {
              font-size: 12px;
              line-height: 14px;
              color: $neutral-500;
              white-space: nowrap;

              &:not(:last-child) {
                margin-right: 18px;
                position: relative;

                &::after {
                  content: '';
                  position: absolute;
                  right: -11px;
                  display: block;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: $neutral-300;
                }
              }
            }
          }
        }

        &--is-selected {
          .option-description {
            span {
              color: $white !important;

              &::after {
                background-color: $white !important;
              }
            }
          }
        }

        &:hover:not(.custom-select__option--is-selected) {
          .option-description {
            span {
              color: $neutral-500 !important;

              &::after {
                background-color: $neutral-300 !important;
              }
            }
          }
        }
      }
    }
  }
}
