.interview-card {
  padding: 32px 0 48px;

  @include media-breakpoint-down(xs) {
    padding: 24px 0;
  }

  .notification-card {
    margin: 0 24px;
    background-color: $neutral-100;
    padding: 4px;

    @include media-breakpoint-down(xs) {
      margin: 0 12px;
    }

    .card-footer {
      margin-top: 12px;
      justify-content: space-between;

      .custom-button {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  & > .notification-card .body-container {
    padding: 8px 12px;
  }

  .interview-collapse {
    .loading {
      width: 80px;
      margin: 30px auto 10px;
    }
  }

  .previous-calc-list {
    padding: 0;
    padding-bottom: 8px;

    .list-group-item {
      border: 0;
      padding: 12px 24px !important;

      @include media-breakpoint-down(xs) {
        padding: 12px !important;
      }

      .list-line {
        @include media-breakpoint-down(xs) {
          &::after {
            position: absolute;
            content: '';
            top: -12px;
            right: -12px;
            left: -12px;
            bottom: -12px;
            cursor: pointer;
            z-index: 0;
          }
        }
      }

      &:nth-child(even) {
        background-color: #fafafa;
      }
    }
  }

  .new-interview.btn {
    height: 24px;
    padding: 0;
    margin: 16px auto 0;
    font-size: 14px;
    font-weight: 400;

    @include media-breakpoint-down(xs) {
      margin-top: 12px;
    }

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .no-interviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 16px;
    text-align: center;

    @include media-breakpoint-down(xs) {
      margin-bottom: 16px;
    }

    svg {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
  }
}
