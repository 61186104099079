.calculation-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  background-color: $neutral-100;
  border-radius: 4px 4px 0 0;

  .calculation-header {
    display: flex;
    align-items: center;

    .title {
      color: $neutral-black;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      margin-bottom: 0;
    }

    .toggle-checkbox {
      margin-bottom: 0;
      flex-direction: row-reverse;

      .toggle-checkbox-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $neutral-black;
        margin-right: 12px;
      }
    }
  }

  .clients-select {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 50%;

    .label {
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-right: 24px;
    }

    .custom-select-input {
      margin-bottom: 0 !important;

      .custom-select__menu {
        z-index: 3;
      }
    }
  }
}
