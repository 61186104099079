.btn.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.75rem;
  box-shadow: none !important;
  appearance: none;
  font-weight: 600;

  &.btn-primary {
    @include btn-primary();
  }

  &.btn-white {
    @include btn-white();
  }

  &.btn-secondary {
    @include btn-secondary();
  }

  &.btn-stroke {
    @include btn-stroke();
  }

  &.btn-link {
    @include btn-link();
  }

  &.btn-white-link {
    @include btn-white-link();
  }

  &.btn-red-link {
    @include btn-red-link();
  }

  &.btn-dashed-link {
    @include btn-dashed-link();
  }

  &.btn-primary,
  &.btn-secondary {
    &:disabled {
      opacity: 0.3;
    }
  }

  &.btn-white,
  &.btn-stroke {
    &:disabled {
      background-color: $neutral-200;
      border-color: $neutral-200;
      color: $neutral-500;
    }
  }

  &.btn-link:disabled,
  &.btn-white-link:disabled,
  &.btn-red-link:disabled {
    background-color: transparent;
    border-color: transparent;
    color: $neutral-500;
  }

  &.btn-lg {
    @include btn-size-lg();
  }

  &.btn-md {
    @include btn-size-md();
  }

  &.btn-sm {
    @include btn-size-sm();
  }

  &.btn-xs {
    @include btn-size-xs();
  }
}

.custom-btn-group {
  display: block;

  &.default {
    .btn.custom-button {
      display: inline-block !important;

      &.btn-primary {
        background-color: $secondary;
        border-color: $secondary;
        color: $neutral-black;

        &:hover:not(:disabled),
        &.active,
        &:focus:not(:disabled),
        &:active:not(:disabled) {
          background-color: $btn-secondary-focus;
          border-color: $btn-secondary-focus;
        }
      }

      &.active {
        z-index: 0;
      }
    }
  }

  &.wide {
    padding: 4px;
    background-color: $neutral-100;
    display: flex;
    gap: 8px;
    border-radius: 4px;

    .btn.custom-button.btn-primary {
      padding: 9px 0;
      height: 36px;
      background-color: transparent;
      width: 100%;
      border: none;
      z-index: 0;

      font-family: 'DM Sans', sans-serif;
      color: $neutral-black;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      transition: font-weight 0.15s ease-in-out, color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:hover {
        font-weight: 500;
      }

      &.active {
        background-color: $neutral;
        border-radius: 4px;
        box-shadow: 0px 2px 8px -2px rgba(24, 39, 75, 0.07), 0px 4px 8px -2px rgba(24, 39, 75, 0.03) !important;

        color: $primary-500;
        font-weight: 400;
      }
    }
  }

  & + .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0px;
    color: $error;
    font-size: 80%;
    transition: margin-top 0.1s ease-in-out, opacity 0.1s ease-in-out;

    &.show {
      margin-top: 0.25rem;
    }
  }
}

.custom-toggle-buttons {
  .form-control-label.with-tooltip {
    display: flex;
    align-items: center;

    svg {
      color: $primary-500;
      margin-left: 4px;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}
