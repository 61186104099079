.action-window-container {
  width: 320px;
  position: absolute;
  z-index: 20;
  box-shadow: 0px 4px 8px rgba(24, 39, 75, 0.08), 0px 4px 24px rgba(24, 39, 75, 0.08);
  border-radius: 8px;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: rgb(255, 255, 255);
    box-shadow: inherit;
    box-sizing: border-box;
    transform: rotate(45deg);
    height: 16px;
    width: 16px;
    left: 24px;
  }

  &.placement-top {
    bottom: 30px;

    &::after {
      bottom: -8px;
    }
  }

  &.placement-bottom {
    top: 30px;

    &::after {
      top: -8px;
    }
  }

  .action-window {
    padding: 24px 32px;
    background-color: $white;
    border-radius: 8px;

    .window-header {
      position: relative;
      display: flex;

      .header-title {
        .title {
          color: $neutral-black;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px; /* 24px */
        }
      }

      .btn-close {
        position: absolute;
        right: -12px;
        top: 0;
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 24px;
        height: 24px;
        color: $neutral-500;
        box-shadow: none;

        &:hover {
          color: $neutral-400;
        }
      }
    }

    .window-body {
      padding-bottom: 24px;

      b {
        color: $neutral-black;
      }
    }

    .window-footer {
      display: flex;

      .btn {
        padding: 8px 12px !important;
        height: 32px !important;

        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
}
