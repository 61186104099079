.blurred-input-container {
  margin: 0 !important;
}

.blurred-input {
  color: $gray-700 !important;
  background-color: $white !important;

  &.blurred {
    pointer-events: none;
    user-select: none;
    filter: blur(3px);
  }
}
