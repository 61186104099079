.modal .modal-dialog.income-modal {
  max-width: 900px;

  .modal-content {
    .modal-body {
      .income-form {
        .delete-btn {
          position: static;
          height: 42px;
        }

        .form-text:not(small) {
          margin-top: 16px;
          color: $neutral-black;
          font-size: 16px;
          line-height: 21px;
        }

        .templates-selector {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          width: 100%;
          min-height: 304px;
          border: 1px solid $neutral-300;
          border-radius: 4px;

          @include media-breakpoint-down(xs) {
            min-height: 200px;
          }

          &.loading,
          &.empty {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .no-templates {
            max-width: 292px;

            font-family: 'DM Sans', sans-serif;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: $neutral-black;

            @include media-breakpoint-down(xs) {
              font-size: 16px;
              line-height: 21px;
            }
          }

          .templates-selector-list {
            max-height: 300px;
            overflow-y: auto;

            .template {
              display: flex;
              justify-content: space-between;
              width: 100%;
              transition: background-color 0.1s ease-in-out;
              cursor: pointer;
              border-bottom: 1px solid $neutral-300;

              &.active {
                background-color: $primary-400;

                .template-name,
                .template-number {
                  color: $white;
                }
              }

              @include media-breakpoint-down(xs) {
                font-size: 14px;
                line-height: 16px;
              }

              .template-name,
              .template-number {
                padding: 9.5px 15px;
                padding-right: 5px;

                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                color: $neutral-black;
                line-height: 19px;
              }

              .template-name {
                width: 100%;
                max-width: calc(100% - 100px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-right: 1px solid $neutral-300;
              }

              .template-number {
                width: 100px;
                min-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          .templates-selector-footer {
            border-top: 1px solid $neutral-300;
            padding: 9.5px 15px;
            background-color: $neutral-100;
            border-radius: 0 0 4px 4px;

            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: $neutral-700;
          }
        }

        .document-column {
          display: flex;
          flex-direction: column;
          gap: 0;

          @include media-breakpoint-down(xs) {
            margin: 24px 0 0;
          }

          .buttons {
            display: flex;
            margin-top: auto;

            @include media-breakpoint-down(xs) {
              margin-top: 63px;
            }

            .btn + .btn {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
