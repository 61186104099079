.modal {
  .modal-dialog.possession-modal {
    max-width: 680px;

    .modal-content {
      .modal-header {
        padding: 24px 24px 0;
      }

      .modal-body {
        padding: 24px 24px 0;

        .possession-form {
          .slider {
            .slider-thumb {
              z-index: 1 !important;
            }

            & + .invalid-feedback {
              position: relative;
              z-index: 0;
            }
          }

          & > .row > .col-md-6 {
            &:first-child {
              padding-right: 8px !important;
            }
            &:last-child {
              padding-left: 8px !important;
            }
          }

          .occupied-by-btn {
            .btn {
              padding-left: 16px;
              padding-right: 16px;
            }
          }
        }
      }

      .modal-footer {
        padding: 24px 0;
      }
    }
  }
}
