// Edit Budget Items Input Controls
// Contains all input, textarea, and select styles for the budget editor modal

// Define consistent column width variables for the budget editor
$description-column-flex: 1 1 calc(45% + 50px);
$description-column-min-width: 200px;
$description-column-max-width: 300px;

// Define amount column variables first
$amount-column-flex: 0 0 15%;
$amount-column-min-width: 80px;
$amount-column-width: 15%;
$amount-column-max-width: 150px;

// Use amount column variables to calculate the description column width
$description-column-width: calc(45% + 50px);

// Define a consistent currency input width based on amount column width
$currency-input-width: calc(100% - 10px);

/* Budget Editor Layout Styles - mimicking Family Property Calculator */
.budget-editor-content {
  // Total max-width is 900px (300px description + 150px × 4 value columns)
  
  // Column headers styling
  .budget-column-headers {
    background-color: #f8f9fa;
    padding: 0;
    border-radius: 4px;
    margin-bottom: 10px;
    border: 1px solid #dee2e6;
    font-weight: bold;
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    
    .category-header {
      width: $description-column-width !important;
      flex: $description-column-flex !important;
      min-width: $description-column-min-width !important;
      max-width: $description-column-max-width !important;
      padding: 0 5px 0 15px;
      text-align: left;
      position: relative;
      border-right: 1px solid #dee2e6;
    }
    
    .client-header, .ex-header {
      width: $amount-column-width !important;
      min-width: $amount-column-min-width !important;
      max-width: $amount-column-max-width !important;
      flex: $amount-column-flex !important;
      padding: 10px 15px 10px 5px;
      text-align: center;
      position: relative;
      border-right: 1px solid #dee2e6;
      
      &:last-child {
        border-right: none;
      }
    }
    
    .client-header {
      background-color: rgba(0, 123, 255, 0.05);
    }
    
    .ex-header {
      background-color: rgba(0, 123, 255, 0.05);
    }
  }
  
  // Budget category styling
  .budget-category {
    
    .category-header {
      cursor: pointer;
      border-radius: 4px 4px 0 0;
      
      h5 {
        margin-bottom: 0;
      }
    }
    
    .category-items {
      background-color: #fff;
      position: relative;
    }
  }
  
  // Budget items row styling
  .budget-item-row, .new-budget-item-row {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    z-index: 1;
    
    // Add proper styling for delete icon containers to prevent layout shifts
    .delete-icon-container {
      position: absolute;
      z-index: 10;
      pointer-events: auto;
      // Ensure the icon doesn't affect the flow of content
      box-sizing: content-box;
    }
    
    // Alternate row colors
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.03);
    }
    
    &:hover {
      background-color: rgba(236, 242, 252, 0.5);
      transition: background-color 0.2s ease;
    }
    
    .budget-item-description {
      width: $description-column-width !important;
      flex: $description-column-flex !important;
      min-width: $description-column-min-width !important;
      max-width: $description-column-max-width !important;
      display: flex;
      align-items: center;
      padding: 8px 5px 8px 15px;
      box-sizing: border-box !important;
      
      .form-control-plaintext {
        border: none;
        background: transparent !important;
        font-weight: 500;
        padding-left: 0;
        cursor: default;
      }
      
      .description-input {
        border: 1px solid transparent;
        background-color: transparent !important;
        transition: border-color 0.2s ease;
        font-weight: normal;
        width: 100%;
        
        &:hover {
          border-color: #ced4da;
        }
        
        &:focus {
          background-color: #fff !important;
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }
    
    // Budget amount column styling
    .budget-amount-col {
      width: $amount-column-width !important;
      min-width: $amount-column-min-width !important;
      max-width: $amount-column-max-width !important;
      flex: $amount-column-flex !important;
      padding: 8px 15px 8px 5px !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box !important;
      border: none !important;
      
      // Remove border from any potential input containers
      .form-group, .input-group, .currency-input-container {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
      }
      
      &:last-child {
        border-right: none;
      }
      
      // Currency input styling - updated to match family property calculator
      .currency-input {
        min-width: 60px !important;
        width: $currency-input-width !important;
        max-width: $currency-input-width !important;
        text-align: right;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding: 0.2rem 0.4rem;
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.1;
        background-color: #fff !important;
        box-shadow: none !important;
        box-sizing: border-box !important;
        overflow: visible;
        margin-right: 0 !important;
        position: relative;
        z-index: 2;
        opacity: 1 !important;
        
        // Force background to be solid for input and all its children
        * {
          background-color: #fff !important;
          opacity: 1 !important;
        }
        
        // Remove any input transparency 
        &:not(:focus):not(:hover) {
          background-color: #fff !important;
          opacity: 1 !important;
        }
        
        &:focus, &:active {
          background-color: #fff !important;
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          z-index: 3;
        }
      }
    }
  }
  
  // Subtotal row styling
  .subtotal-row {
    background-color: rgba(248, 249, 250, 0.85) !important;
    font-weight: 600;
    border-top: 1px solid rgba(222, 226, 230, 0.8) !important;
    border-bottom: none !important;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    z-index: 2;
    border-radius: 4px !important;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.02), 0 1px 4px rgba(0, 0, 0, 0.02);
    padding: 0.3rem 0.25rem !important;
    
    &:hover {
      background-color: rgba(236, 242, 252, 0.7) !important;
    }
    
    > div {
      padding: 0.3rem 0.25rem;
      
      &:not(:first-child) {
        background-color: transparent;
        width: $amount-column-width !important;
        min-width: $amount-column-min-width !important;
        max-width: $amount-column-max-width !important;
        flex: $amount-column-flex !important;
        text-align: right;
        color: #007bff !important;
        box-sizing: border-box !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 30px !important;
        padding-right: 15px !important;
        
        // Force blue color on all elements inside the value columns
        * {
          color: #007bff !important;
        }
      }
      
      &:first-child {
        width: $description-column-width !important;
        flex: $description-column-flex !important;
        min-width: $description-column-min-width !important;
        max-width: $description-column-max-width !important;
        text-align: right;
        padding-right: 15px;
        box-sizing: border-box !important;
      }
    }
    
    strong {
      font-size: 0.95rem;
      color: #343a40;
      font-weight: 600;
      display: inline-block; // Ensure text aligns properly
    }
    
    .subtotal-value {
      font-weight: 600;
      font-size: 0.95rem;
      color: #007bff; // Blue text for subtotal values
      padding-right: 0 !important;
      font-family: inherit;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 30px;
    }
    
    // Target specifically span, div, or p elements that might contain the numeric value
    span, div, p {
      &:not(.subtotal-label) {
        color: #007bff !important;
      }
    }
    
    // Target common budget total classes that might be used
    .amount, .value, .total, .budget-total, .sum, .subtotal, .col-total {
      color: #007bff !important;
    }
  }
  
  // Expand/collapse button styling
  .expand-collapse-all {
    color: #007bff;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    margin-bottom: 5px;
    
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 123, 255, 0.1);
    }
  }
  
  // Add custom item section styling
  .add-custom-item-section {
    background-color: #f8f9fa;
    
    .btn {
      margin-top: 0;
    }
  }
  
  // Add new responsive behavior
  @media (max-width: 767px) {
    // Allow columns to wrap on small screens
    .budget-column-headers,
    .budget-item-row,
    .new-budget-item-row,
    .subtotal-row {
      flex-wrap: wrap !important;
      
      .category-header,
      .budget-item-description,
      [class*="col-"]:first-child {
        width: 100% !important;
        flex: 1 0 100% !important;
        max-width: 100% !important;
        border-right: none;
        text-align: left !important;
        padding-right: 0.5rem;
      }
      
      .client-header, 
      .ex-header,
      .budget-amount-col,
      [class*="col-"]:not(:first-child) {
        width: 50% !important;
        flex: 1 0 50% !important;
        min-width: 50% !important;
        max-width: 50% !important;
        border-top: 1px solid rgba(222, 226, 230, 0.3);
        justify-content: center;
      }
    }
    
    // Special styling for subtotal rows on mobile
    .subtotal-row {
      flex-direction: column !important;
      padding: 0.3rem !important;
      border-radius: 4px !important;
      
      > div {
        text-align: center !important;
        margin-bottom: 0.25rem;
        padding: 0.25rem !important;
        height: auto !important;
        
        &:first-child {
          background-color: transparent !important;
          border-bottom: 1px solid rgba(222, 226, 230, 0.4);
          font-weight: 600;
          font-size: 0.95rem;
        }
        
        &:not(:first-child) {
          color: #007bff !important; // Ensure blue color in mobile view
        }
      }
      
      .subtotal-value {
        justify-content: center !important;
        padding-right: 0 !important;
        height: 30px !important;
      }
    }
    
    // Change the column shading for responsive layout
    .category-items {
      .column-shade-1, 
      .column-shade-2, 
      .column-shade-3, 
      .column-shade-4 {
        display: none; // Hide background shading on mobile
      }
    }
  }
}

/* Global highest priority rule for collapsed textareas */
html body textarea.auto-resize-textarea.collapsed,
html body .budget-editor-modal textarea.collapsed,
html body .budget-editor-form textarea.collapsed,
html body .description-field textarea.collapsed,
html body textarea.form-control.collapsed {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  overflow: hidden !important;
  resize: none !important; /* Keep collapsed textareas non-resizable */
  box-sizing: border-box !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  transition: none !important;
}

/* Custom stronger text-muted class to override Bootstrap's text-muted */
.stronger-text-muted {
  color: #495057 !important; 
  opacity: 0.9 !important;
}

// General input control styles
.budget-editor-form {
  // General form control styles that need to be overridden for textareas
  .form-control {
    border-radius: 4px;
    border-color: #ced4da;
    transition: border-color 0.2s ease;
    padding: 0.25rem 0.5rem;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    font-size: 0.9rem;
    position: relative;
    z-index: 2;
    background-color: #fff !important;

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  input[type="text"], input[type="number"] {
    padding: 0.25rem 0.5rem;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color: #fff !important;
  }

  .align-items-center {
    padding: 0.2rem 0;
    
    // Apply height constraints to all form controls EXCEPT textareas
    .form-control:not(textarea), 
    input:not(textarea), 
    select, 
    .input-group, 
    .currency-input {
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      background-color: #fff !important;
    }
  }

  input, .form-control {
    line-height: normal;
  }
}

// React-Select Styles
.budget-editor-modal {
  // Improved styles for React-Select menu portals, properly scoped to this component
  // Instead of using :global selectors, use descendant selectors
  .react-select__menu-portal,
  div[class*="menu-portal"],
  #react-select-menu-portal {
    z-index: 9999 !important; // Higher z-index to appear above other elements
    
    // Style consistency for menu content in portals
    .react-select__menu,
    [class*="menu"] {
      background-color: #fff !important;
      border: 1px solid #ced4da !important;
      border-radius: 4px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
      margin-top: 2px !important;
      
      // Force menu to be wider than parent select on narrow screens
      min-width: 100% !important; // At minimum, match parent width
      width: auto !important; // Allow menu to expand beyond parent width
      max-width: 220px !important; // Set a specific wider width for select menus
    }
    
    // Consistent option styling in portals
    .react-select__option,
    [class*="option"] {
      font-size: 0.9rem !important; // Match font size with other form elements
      padding: 6px 12px !important;
      line-height: 1.2 !important;
      white-space: normal !important; // Allow text to wrap if needed
      word-break: break-word !important; // Ensure long words break properly
      
      &:active, &:focus, &:hover {
        background-color: rgba(0, 123, 255, 0.1) !important;
      }
      
      &[class*="option--is-selected"] {
        background-color: #007bff !important;
        color: white !important;
      }
    }
  }
  
  // Style for React-Select controls - Fix dropdown selected value disappearing issue
  .custom-select__control {
    min-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 0 !important;
    overflow: visible !important;
    box-sizing: border-box !important;
    position: relative !important;
    z-index: 1 !important;
  }
  
  .custom-select__value-container {
    padding: 0 8px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    overflow: hidden !important;
    flex: 1 !important;
    position: relative !important;
    z-index: 1 !important;
    box-sizing: border-box !important;
    min-width: 0 !important; /* Ensures truncation works */
  }
  
  .custom-select__single-value {
    color: #212529 !important;
    position: absolute !important;
    max-width: 90% !important; 
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-weight: 400 !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 50% !important;
    left: 8px !important;
    transform: translateY(-50%) !important;
    opacity: 1 !important; 
    visibility: visible !important;
    line-height: 1 !important;
    font-size: 0.9rem !important;
    z-index: 1 !important;
    display: block !important; /* Use block instead of flex for proper truncation */
    max-height: 20px !important; /* Ensure it fits within the container */
  }
  
  .custom-select__indicator {
    padding: 2px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    z-index: 1 !important; // Ensure indicators are visible
  }
  
  .custom-select__placeholder {
    color: #6c757d !important;
    font-weight: normal !important;
    opacity: 0.7 !important;
    font-size: 0.9rem !important;
    z-index: 1 !important; // Ensure placeholder is visible
  }
  
  // Fix for select options in React-Select
  .type-select {
    width: 100%;
    font-size: 0.9rem;
    
    & > div {
      box-sizing: border-box !important;
    }
    
    .custom-select__control,
    div[class*="-control"] {
      border: 1px solid #ced4da !important;
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      box-sizing: border-box !important;
      overflow: visible !important;
      position: relative !important;
      
      &::after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        border: 1px solid #ced4da;
        pointer-events: none;
        border-radius: 4px;
        z-index: 0;
      }
    }
    
    // Restore value container styling
    .css-1hwfws3,
    [class*="ValueContainer"] {
      padding: 0 8px !important;
      height: 30px !important;
      overflow: hidden !important;
      position: relative !important;
      z-index: 1 !important;
    }
    
    // Restore single value styling
    .css-1uccc91-singleValue,
    .css-dvua67-singleValue,
    [class*="singleValue"] {
      position: absolute !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      margin: 0 !important;
      max-width: 90% !important;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
  
  // Styles for React-Select menus when opened directly (not in portal)
  .custom-select__menu {
    z-index: 9999 !important; // Higher z-index to appear above other elements
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
    margin-top: 2px !important;
    
    .custom-select__option {
      padding: 6px 12px !important;
      font-size: 0.9rem !important;
      
      &:active, &:focus, &:hover {
        background-color: rgba(0, 123, 255, 0.1) !important;
      }
      
      &.custom-select__option--is-selected {
        background-color: #007bff !important;
        color: white !important;
      }
    }
  }
}

/* TEXTAREA SPECIFIC STYLES */
.budget-editor-form {
  // Budget item description textarea styles
  .budget-item-row {
    .description-field {
      flex: 1;
      min-width: 0; // Allow field to shrink if needed
      margin-left: 0.5rem;
      
      // Override textarea styling for auto-resizing
      textarea.form-control,
      textarea.auto-resize-textarea {
        // These override existing constraints
        min-height: 30px !important;
        max-height: none !important;
        overflow: hidden !important;
        transition: height 0.15s ease !important;
        resize: vertical !important; /* Always show resize handle to indicate expandability */
        
        // Styling for appearance
        padding: 5px 8px !important;
        font-size: 0.9rem !important;
        line-height: 1.4 !important;
        white-space: pre-wrap !important;
        word-break: break-word !important;
        
        // Remove fixed height constraints that might be coming from other styles
        height: auto !important;
        
        // Improve focus styling
        &:focus {
          border-color: #80bdff !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
          z-index: 10 !important;
          position: relative !important;
        }
        
        // Remove the conditional hiding of resize handle for single line textareas
        // Instead, only hide resize handle for collapsed textareas
        &.collapsed {
          resize: none !important;
        }
      }
    }
    
    /* CRITICAL OVERRIDE for all textareas - this is the most important section */
    /* Using the strongest possible specificity to ensure these rules are applied */
    .description-field textarea.form-control,
    .description-field textarea.auto-resize-textarea,
    textarea.auto-resize-textarea,
    .budget-item-description textarea.form-control {
      /* Simplify to basic necessary styles */
      min-height: 30px !important;
      /* Allow height to be controlled by JS when needed */
      height: auto !important;
      max-height: none !important;
      resize: vertical !important; /* Always show resize handle to indicate expandability */
      overflow: hidden !important;
      width: 100% !important;
      
      /* Add smooth transition for height changes */
      transition: height 0.15s ease-in-out, border-color 0.2s ease !important;
      
      /* Basic appearance styles */
      padding: 5px 8px !important;
      font-size: 0.9rem !important;
      line-height: 1.4 !important;
      white-space: pre-wrap !important;
      word-break: break-word !important;
      
      /* Focus styling */
      &:focus {
        border-color: #80bdff !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
        position: relative !important;
        z-index: 5 !important;
      }
      
      /* Force-resize class for extreme cases */
      &.force-resize {
        height: attr(data-height) !important; /* Fallback */
        min-height: 30px !important;
        max-height: none !important;
        overflow: hidden !important;
      }
      
      /* CRITICAL: Allow the collapsed class to override everything else */
      &.collapsed {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        overflow: hidden !important;
        resize: none !important;
        transition: none !important;
      }
    }
    
    /* NEW: Simple auto-resize mechanism that will override all other styles */
    .description-field {
      textarea {
        transition: height 0.1s ease-in-out !important;
        min-height: 30px !important;
        /* Allow height to be controlled by JS when needed */
        height: auto !important;
        max-height: none !important;
        resize: vertical !important; /* Always show resize handle to indicate expandability */
        
        /* Override any existing important height constraints EXCEPT inline styles */
        &.form-control, &[class*="form-control"], &[class] {
          /* Allow inline styles to take precedence */
          height: auto !important;
          max-height: none !important;
          overflow: hidden !important;
        }
        
        /* When focused, ensure it can grow */
        &:focus {
          min-height: 30px !important; /* Changed from 60px to allow collapsing even when focused */
          /* Allow inline styles to take precedence */
          height: auto !important;
          overflow: hidden !important;
          resize: vertical !important; /* Always show resize handle when focused */
        }
        
        /* CRITICAL: Ensure collapsed class overrides all other styles */
        &.collapsed {
          height: 30px !important;
          min-height: 30px !important;
          max-height: 30px !important;
          overflow: hidden !important;
          resize: none !important;
          transition: none !important;
        }
      }
    }
    
    /* ADDITIONAL: Explicitly ensure inline styles take precedence */
    .description-field textarea[style*="height"] {
      /* Always show resize handle, even at single line height */
      resize: vertical !important;
      
      /* Only hide resize for collapsed textareas */
      &.collapsed {
        resize: none !important;
      }
    }
    
    /* CRITICAL: Super-high specificity rule for .collapsed that will override everything */
    body .budget-editor-form .budget-categories .budget-item-row .description-field textarea.collapsed,
    body .description-field textarea.auto-resize-textarea.collapsed,
    body textarea.auto-resize-textarea.collapsed,
    body .budget-editor-modal textarea.collapsed {
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      overflow: hidden !important;
      resize: none !important;
      box-sizing: border-box !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      transition: none !important;
    }
    
    /* Ensure the description field container can properly position the textarea */
    .description-field {
      flex: 1 1 auto !important;
      min-width: 0 !important;
      margin-left: 0.5rem !important;
      position: relative !important;
      height: auto !important;
    }
  }
}

/* CURRENCY INPUT STYLES */
.budget-editor-form {
  // Updated currency input styling with fixed width constraint and proper borders
  .currency-input {
    text-align: right;
    font-size: 0.9rem;
    font-weight: 500;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    padding: 0.2rem 0.6rem;
    line-height: 1.1;
    font-family: inherit;
    background-color: #fff !important;
    border: 1px solid #ced4da !important; // Ensure border is visible
    border-radius: 4px;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    width: $currency-input-width !important; // Fixed width to match family property calculator
    margin-left: auto !important; // Right align within container
    margin-right: 0 !important; // Remove right margin to avoid causing overflow
    box-sizing: border-box !important; // Ensure padding is included in width
  }
  
  .compact-currency {
    width: 100%;
    margin: 0 auto;
    padding: 0 !important;
    display: flex !important;
    justify-content: flex-end !important; // Right align in column
    padding-right: 0 !important; // Remove padding
    
    .currency-input {
      width: $currency-input-width !important; // Fixed width to match family property calculator
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      text-align: right;
      overflow: visible;
      background-color: #fff !important;
      box-shadow: none !important;
      border: 1px solid #ced4da !important; // Ensure border is visible
      box-sizing: border-box !important; // Ensure padding is included in width
    }
    
    .input-group {
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      overflow: visible;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 4px;
      border: none;
      box-shadow: none;
      width: $currency-input-width !important; // Fixed width to match family property calculator
      box-sizing: border-box !important; // Ensure padding is included in width
      margin-right: 0 !important; // Remove right margin
      
      .form-control {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        padding: 0.2rem 0.6rem;
        text-align: right;
        width: $currency-input-width !important; // Fixed width to match family property calculator
        border: 1px solid #ced4da !important; // Ensure border is visible
        box-sizing: border-box !important; // Ensure padding is included in width
      }
    }
    
    input.form-control {
      text-align: right;
      padding-right: 0.6rem;
      font-weight: 500;
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      border-radius: 4px;
      background-color: #fff !important;
      width: $currency-input-width !important; // Fixed width to match family property calculator
      border: 1px solid #ced4da !important; // Ensure border is visible
      box-sizing: border-box !important; // Ensure padding is included in width
    }
  }
}

/* DATEPICKER STYLES */
.budget-editor-form {
  .react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container {
      input {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        font-size: 0.9rem;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}

/* Budget-specific styling */
.budget-editor-form {
  // Custom styling for budget-specific inputs
  .budget-amount-input {
    text-align: right;
    font-weight: 500;
    width: 120px !important;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    padding: 0.2rem 0.6rem;
    border: 1px solid #ced4da !important;
    border-radius: 4px;
    background-color: #fff !important;
  }
  
  .budget-frequency-select {
    width: 100px;
    min-width: 100px;
  }
  
  // Help text and hints
  .form-text {
    font-size: 0.8rem;
    color: #6c757d;
    margin-top: 0.25rem;
  }

  // Button styling within forms
  .btn {
    &.btn-sm {
      padding: 0.25rem 0.5rem;
      font-size: 0.85rem;
      position: relative;
      
      // Icon styling for small buttons
      .icon {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: -3px; /* Negative value pulls icon up */
        margin-right: 3px;
        position: relative;
        top: -1px; /* Shift icon up slightly */
      }
    }
    
    &.btn-icon {
      width: 30px;
      height: 30px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* Make currency inputs consistent across both contexts */
.currency-input {
  text-align: right !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  padding: 0.2rem 0.6rem !important;
  line-height: 1.1 !important;
  font-family: inherit !important;
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: $currency-input-width !important; // Fixed width to match family property calculator
  box-sizing: border-box !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

// Icon styling for small buttons
.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: -3px; /* Negative value pulls icon up */
  margin-right: 3px;
  position: relative;
  top: -1px; /* Shift icon up slightly */
}

// Budget headers container (matching the BudgetSummaryCalculation.jsx styling)
.budget-headers-container {
  background-color: #f8f9fa;
  overflow: hidden;
  margin-bottom: 0;
  
  // Main headers (Category, Client's Budget, Ex's Budget)
  .main-headers {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    
    .category-header {
      flex: 0 0 33.333%;
      width: 33.333%;
      text-align: left;
      font-weight: 600;
      font-size: 1rem;
      color: #495057;
      padding: 0.75rem 0.5rem 0.75rem 15px;
      border-right: 1px solid #dee2e6;
    }
    
    .budget-header {
      flex: 0 0 33.333%;
      width: 33.333%;
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
      color: #495057;
      padding: 0.75rem 0.5rem;
      border-right: 1px solid #dee2e6;
      
      &:last-child {
        border-right: none;
      }
    }
  }
  
  // Sub headers (Yearly, Monthly for each budget)
  .sub-headers {
    display: flex;
    align-items: center;
    
    .category-spacer {
      flex: 0 0 33.333%;
      width: 33.333%;
      border-right: 1px solid #dee2e6;
    }
    
    .period-header {
      flex: 0 0 16.666%;
      width: 16.666%;
      text-align: center;
      font-weight: 500;
      font-size: 0.9rem;
      color: #6c757d;
      padding: 0.5rem 0.25rem;
      border-right: 1px solid #dee2e6;
      background-color: rgba(248, 249, 250, 0.7);
    }
    
    // Enhanced background colors for yearly vs. monthly columns in sub-headers
    // Yearly columns (Client and Ex)
    .period-header:nth-child(2),
    .period-header:nth-child(4) {
      background-color: rgba(236, 242, 252, 0.7); // Light blue for yearly columns
    }
    
    // Monthly columns (Client and Ex)
    .period-header:nth-child(3),
    .period-header:nth-child(5) {
      background-color: rgba(248, 249, 250, 0.7); // Light gray for monthly columns
    }
    
    .period-header:last-child {
      border-right: none;
    }
  }
} 