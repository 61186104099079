.custom-focused {
  border-color: $border-focus-color;
  color: #510fa8;
  box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075), 0 0 20px rgba(110, 0, 255, 0.1);
}

.no-result-clients-list {
  padding: 35px 25px;

  text-align: center;
  img {
    height: 100px;
  }
}
