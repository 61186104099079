.select-input {
  margin-bottom: 0;

  .custom-select-input {
    .custom-select__control {
      font-size: 16px;
      border-color: $neutral-300;
      transition: all 0.15s ease-in-out;

      &:hover {
        border-color: $neutral-400;
      }

      &.custom-select__control--is-focused {
        border-color: $primary-400;
        box-shadow: none;

        .custom-select__dropdown-indicator {
          svg {
            fill: $primary-400;
          }
        }
      }

      &.custom-select__control--menu-is-open {
        .custom-select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }

      .custom-select__indicators {
        .custom-select__indicator-separator {
          display: none;
        }
      }

      .custom-select__value-container {
        overflow: visible;

        .custom-select__single-value {
          font-size: 16px;
        }

        .custom-select__placeholder {
          font-size: 16px;
          color: $neutral-400;
        }

        .custom-select__multi-value {
          background-color: $neutral-200;

          .custom-select__multi-value__label {
            font-size: 12px;
            color: $neutral-black;
          }

          .custom-select__multi-value__remove {
            padding-left: 0;

            &:hover {
              background-color: transparent;

              svg path {
                fill: $negative-500;
              }
            }
          }
        }
      }

      &--is-disabled {
        background-color: $neutral-100;
      }
    }

    .custom-select__menu {
      border-color: $neutral-300;
      z-index: 10;

      .custom-select__menu-list {
        .custom-select__option {
          padding: 7px 16px;
          font-size: 16px;
          line-height: 21px;

          &:hover:not(.custom-select__option--is-selected) {
            background-color: $neutral-100;
          }

          &--is-focused {
            background-color: $primary-100;
            color: $primary-600;
          }

          &--is-selected {
            background-color: $primary-400;
            color: $white;
          }
        }
      }
    }

    &.size-lg {
      .custom-select__control {
        min-height: 42px;

        .custom-select__value-container {
          padding-left: 16px;
        }
      }
    }

    &.size-md {
      .custom-select__control {
        min-height: 42px;

        .custom-select__value-container {
          padding-left: 12px;
        }
      }
    }

    &.size-sm {
      .custom-select__control {
        min-height: 34px;
        height: 34px;

        .custom-select__value-container {
          padding-left: 12px;
          height: 32px;
          font-size: 14px;

          .custom-select__single-value {
            font-size: 14px;
          }
        }

        .custom-select__indicators {
          height: 32px;
          .custom-select__dropdown-indicator {
            padding: 6px;
          }
        }
      }
    }

    &.is-invalid {
      .custom-select__control {
        border-color: $negative-500;
      }
    }

    &.filter-dropdown,
    &.sort-dropdown {
      .custom-select__indicators {
        display: none;
      }
    }
  }
}

/* Override the "active" state for better visibility: */
.dropdown-menu {
  .dropdown-item {
    /* When the item is hovered/focused: */
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $primary-100; /* Or whichever color suits your design */
      color: $primary-600;           /* Adjust for contrast */
    }

    /* When the item is actually "active" (selected): */
    &.active,
    &:active {
      background-color: $primary-400 !important; /* A bolder highlight color */
      color: $white !important;                  /* Ensure good contrast */
    }
  }
}

/* Hide the Bootstrap caret for these specific dropdowns */
.filter-dropdown .dropdown-toggle::after,
.sort-dropdown .dropdown-toggle::after,
.settings-dropdown .dropdown-toggle::after {
  display: none !important;
}

/* Increase top padding & left margin for the filter and sort dropdowns */
.clients-filter-row {
  .filter-dropdown,
  .sort-dropdown,
  .settings-dropdown {
    margin-top: 8px;
    margin-left: 0; /* Removed left margin to prevent layout issues */
  }
}

/* Mobile-specific adjustments */
@media (max-width: 576px) {
  .clients-filter-row {
    .filter-dropdown,
    .sort-dropdown,
    .settings-dropdown {
      margin-top: 0;
      margin-right: 10px;
      
      &:last-child {
        margin-right: 0;
      }
      
      .dropdown-toggle {
        font-size: 12px;
      }
    }
  }
}