.tooltip-inner {
  background-color: $neutral-black;
  border-radius: 4px;

  p {
    font-size: 16px;
    line-height: 21px;
  }
}

.document-tooltip {
  .tooltip-inner {
    max-width: 310px;
    font-size: 14px;
    line-height: 18px;

    @include media-breakpoint-down(xs) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .arrow {
    left: -60% !important;
  }
}
