.child-expenses-section {
  @import 'section-columns-container';

  .section-columns-container {
    .section-columns {
      .section-column.half {
        &:first-child {
          background-color: transparentize($link-800, 0.97) !important;
        }

        &:last-child {
          background-color: unset !important;
        }
      }
      .section-column.total-expenses-list {
        span.name {
          font-weight: 500;
          color: black;
        }
      }

      .section-column.half.total-expenses-list {
        &:not(:last-child) {
          background-color: transparentize($link-800, 0.97) !important;
        }
      }

      .section-column .field {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
