.custom-badge {
  border-radius: 4px;
  font-weight: 400;
  line-height: 16px;
  padding: 2px 4px;
  size: 12px;

  &.uploaded {
    background-color: $primary-500;
  }

  &.shared-by-you {
    background-color: $primary-200;
    color: $primary-500;
  }

  &.shared-with-you {
    background-color: $link-200;
    color: $link-500;
  }
}
