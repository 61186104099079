.toggle {
  &__label {
    display: block;
    margin-bottom: 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    color: $neutral-800;
  }

  &__hint {
    display: block;
    margin-top: 4px;

    color: $gray-600;
    font-size: 80%;
    font-weight: 400;
  }

  &__wrapper {
    overflow: hidden;

    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    padding: 4px;

    &-button {
      flex-shrink: 0;
      flex-grow: 1;

      font-family: DM Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      color: $neutral-black;

      padding: 8px 24px;
      border-radius: 10px;

      border: 1px solid transparent;

      margin: 0;
      background: none;
      outline: none;

      opacity: 0.7;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
        outline: none;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

/* ====================
  Colors
  ================= */
@mixin toggle-button-color($color, $background-color, $border) {
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $color;
    background-color: $background-color;
    border: $border;
  }
}

.toggle.white {
  .toggle__wrapper {
    background-color: $white;
  }

  .toggle__wrapper-button {
    @include toggle-button-color($neutral-black, $cool-gray-50, 1px solid $cool-gray-200);
  }
}

.toggle.gray {
  .toggle__wrapper {
    background: $cool-gray-100;
    border: 1px solid $cool-gray-200;
  }

  .toggle__wrapper-button {
    @include toggle-button-color($neutral-black, $white, 1px solid $cool-gray-200);
  }
}

.toggle.dark-gray {
  .toggle__wrapper {
    background: $secondary;
  }

  .toggle__wrapper-button {
    @include toggle-button-color($neutral-black, $btn-secondary-focus, 1px solid transparent);
  }
}

/* ====================
  Radius
  ================= */
.toggle.radius-sm {
  .toggle__wrapper {
    border-radius: 6px;
  }
}

.toggle.radius-md {
  .toggle__wrapper {
    border-radius: 12px;
  }
}

.toggle.radius-lg {
  .toggle__wrapper {
    border-radius: 18px;
  }
}

/* ====================
  Size
  ================= */
.toggle.size-sm {
  .toggle__wrapper-button {
    padding: 4px 24px;
  }
}

.toggle.size-md {
  .toggle__wrapper-button {
    padding: 8px 24px;
  }
}

.toggle.size-lg {
  .toggle__wrapper-button {
    padding: 12px 18px;
  }
}

/* ====================
  Properties
  ================= */
.toggle.merged {
  .toggle__wrapper {
    gap: 0;
    padding: 0;
  }

  .toggle__wrapper-button {
    border-radius: 0 !important;
    border: none !important;
  }
}
