.calculation-section {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid $neutral-200;

  .calculation-title-input,
  .tax-year-select-container {
    display: flex;
    align-items: center;

    .calculation-title,
    .tax-year-select-title {
      display: flex;
      flex-direction: column;
      margin-right: 24px;

      .title {
        color: $neutral-800;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }

      .description {
        color: $neutral-500;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }

  .input-title-container {
    display: none;
  }

  .tax-year-select-container {
    width: 33%;
  }
}
