.calculation-row-container {
  position: relative;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    border-bottom: 1px dashed $neutral-200;
    width: 100%;
    bottom: 0;
  }

  .calculation-row {
    padding: 22px 24px;

    .row-title {
      display: flex;
      flex-direction: column;

      .title {
        color: $neutral-black;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
      }

      .description {
        margin-bottom: 4px;
        color: $neutral-500;
        font-size: 12px;
        line-height: 14px;
        max-width: 185px;
      }
    }

    .arrow {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn.custom-button {
        border: 0;
        padding: 0;
        height: 24px;
        background-color: transparent;
        color: $neutral-black;

        &:hover,
        &:active,
        &:focus {
          border: 0;
          background-color: transparent;
          color: $neutral-black;
        }
      }
    }
  }
}
