@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.icon {
  &.spin {
    animation: spin 1s linear infinite;
  }
}
