.professional-item {
  max-width: 458px;

  .professional-item-content {
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;

    @include media-breakpoint-down(md) {
      padding-right: 20px;
    }

    .professional-avatar {
      position: relative;
      width: 184px;
      min-width: 184px;
      height: 184px;
      margin-right: 24px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        margin-right: 16px;
      }

      @include media-breakpoint-down(md) {
        width: 123px;
        min-width: 123px;
        height: 121px;
        margin-right: 10px;
      }

      @include media-breakpoint-down(sm) {
        width: 88px;
        min-width: 88px;
        height: 88px;
      }

      &.no-image {
        background-color: $neutral-800;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          @include media-breakpoint-down(md) {
            width: 100px;
            min-width: 100px;
            height: 100px;
          }

          @include media-breakpoint-down(sm) {
            width: 80px;
            min-width: 80px;
            height: 80px;
          }

          @include media-breakpoint-down(xs) {
            width: 68px;
            min-width: 68px;
            height: 68px;
          }
        }
      }

      &:not(.no-image) {
        img {
          width: 184px;
          min-width: 184px;
          height: 184px;

          @include media-breakpoint-down(md) {
            width: 123px;
            min-width: 123px;
            height: 121px;
          }

          @include media-breakpoint-down(sm) {
            width: 88px;
            min-width: 88px;
            height: 88px;
          }
        }
      }
    }

    .professional-info {
      display: flex;
      flex-direction: column;

      .name {
        width: 240px;
        margin-top: 8px;
        margin-bottom: 3px;
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0;
        color: $neutral-black;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          width: 170px;
          margin-top: 0;
        }

        @include media-breakpoint-down(xs) {
          margin-top: 2px;
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 16px;
          width: 100%;
        }

        @media screen and (max-width: 400px) {
          width: 170px;
        }
      }

      .short-info {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        span {
          position: relative;
          color: $neutral-500;

          &:not(:last-child) {
            margin-right: 20px;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: -12px;
              transform: translateY(-50%);
              display: block;
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: $neutral-300;
            }
          }
        }

        @include media-breakpoint-down(xs) {
          font-size: 12px;
        }
      }

      .rating {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 14px;
        margin-bottom: 12px;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          margin: 5px 0;
        }

        @include media-breakpoint-down(xs) {
          margin-top: 4px;
        }

        .rating-details {
          margin-left: 9px;
          font-size: 12px;
          line-height: 16px;
          color: #878787;
          white-space: nowrap;

          @include media-breakpoint-down(xs) {
            margin-left: 0px;
          }
        }
      }

      .biography {
        margin-top: 0;
        display: inline-block;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .view-profile {
        margin-top: 8px;
        font-size: 14px;
        line-height: 18px;

        @include media-breakpoint-down(xs) {
          margin-top: 0;
        }
      }
    }
  }

  .biography {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: $neutral-700;
    margin-top: 12px;
    display: none;

    @include media-breakpoint-down(md) {
      display: inline-block;
      max-width: 270px;
      padding-right: 20px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 500px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 12px;
      font-size: 12px;
      line-height: 16px;
      max-width: 285px;
    }
  }
}
