@import 'income-form';
@import 'tax-amount-modal';

.pro-calculation-form {
  @import 'calculation-header';
  @import 'calculation-section';
  @import 'error-section';
  @import 'section-row';
  @import 'background-section';
  @import 'income-section';
  @import 'tax-input-section';
  @import 'relationship-section';
  @import 'children-section';
  @import 'options-form-section';

  & > .container {
    @media screen and (min-width: 1170px) {
      min-width: 1140px !important;
    }
  }

  .calculator-main {
    background-color: $neutral;
    border: 1px solid $neutral-200;
    border-radius: 4px;

    @import 'share-calculation';
  }

  .calculation-navigation {
    position: relative;
    background-color: $neutral;

    .calculation-status {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);

      .status-text {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        margin-left: 4px;
      }
    }

    .tab {
      padding-bottom: 8px;

      svg {
        margin-left: 4px;
      }

      &:first-child {
        margin-left: 12px;
      }
    }
  }

  .more-options-section {
    .calculation-row {
      .row-title {
        justify-content: center;
      }

      .more-options-section-row {
        color: $neutral-500;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .more-options-content {
      .tab-navigation-container {
        .tab-navigation {
          padding-left: 0;

          .tab {
            padding-top: 2px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .no-calculations {
    padding: 48px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin-bottom: 0;
      margin-top: 24px;
      color: $neutral-black;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
  }

  .calculation-content {
    .report-section-row,
    .calculate-section {
      padding: 24px;
      border-top: 1px solid $neutral-200;
      border-bottom: 1px solid $neutral-200;
      background-color: $neutral-100;
    }

    .calculate-section {
      border-bottom: none;
      border-radius: 0 0 4px 4px;
    }

    .calculation-sections-list {
      position: relative;

      &.submitting {
        user-select: none;
        pointer-events: none;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1000;
          background-color: $neutral-black;
          opacity: 0.025;
          top: -134px;
          position: absolute;
        }
      }
    }

    @import 'support-report';
  }
}
