.income-section {
  .income-section-row {
    width: 100%;

    .income-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &.empty {
        justify-content: center;
      }

      .incomes-list {
        width: 100%;
        max-width: 255px;
      }

      .income,
      .total {
        width: 100%;
        max-width: 255px;
        display: flex;
        justify-content: space-between;

        .key,
        .value {
          font-size: 14px;
          line-height: 16px;
        }

        .key {
          padding-right: 10px;
        }
      }

      .income {
        &:not(:last-child) {
          margin-bottom: 4px;
        }

        &:hover {
          cursor: pointer;

          .key,
          .value {
            text-decoration: underline;
          }
        }
      }

      .total {
        margin-top: 10px;

        .key,
        .value {
          font-weight: 500;
        }
      }

      .btn.custom-button {
        font-weight: 400;
        padding: 0;
        height: 16px;
        margin-left: -4px;

        svg {
          margin-right: 4px;
        }

        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }
  }
}
