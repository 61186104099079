.profile-sidebar-container {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 16px;
    height: fit-content;
  }
}

.profile-sidebar {
  border-radius: 4px;
  border: 0;

  @include media-breakpoint-up(lg) {
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .card-header {
    height: 45px;
    background-color: $white;
    padding: 12px 0 12px 16px;
    border-bottom: 0;

    h6 {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .list-group {
    border: 0;

    .list-group-item {
      border: 0;
      padding: 10px 13px 10px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $neutral-black;

      .main-item-content {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }

      &.active {
        background-color: $primary-500;
        color: $white;
        margin-top: 0;
      }

      &:first-child {
        border-radius: 0;
      }

      & > svg[name='arrow-down'] {
        transform: rotate(270deg);
      }
    }
  }
}
