.scroll-link {
  &.blue {
    color: $blue;
  }

  &.underline {
    &:hover {
      text-decoration: underline !important;
    }
  }
}
