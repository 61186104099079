@import 'calculations';
@import 'clients-search';
@import 'new-client-modal';
@import 'tab-navigation';
@import 'finances';
@import 'section-header';
@import 'client-actions-dropdown';
@import 'select-client-modal';

.pro-tools-card::before {
  content: '';
  background: url(./../../../../../img/icons/dusk/svg/maintenance.svg);
  background-position: center;
  background-size: contain;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  display: inline-block;
}

.infinite-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .loading {
    margin: 10px 0;
  }

  .clients-list {
    animation: fadeIn 0.15s ease;

    width: 100%;

    & > .list-group-item {
      padding: 0;
      border: 0;
      border-radius: 4px;
      margin-left: 16px;
      margin-right: 16px;

      @include media-breakpoint-down(xs) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .client-list-item.border {
      border: none !important;
      outline: 1px solid $neutral-300;
      transition: box-shadow 0.2s ease-in-out !important;

      &.chosen {
        outline: 2px solid $primary-500;
      }
    }

    .client-item {
      padding: 16px 72px 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background-color: $white;
      border-radius: 4px;

      @include media-breakpoint-down(xs) {
        padding: 8px 36px 8px 12px;
      }

      .client-item-content {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .pro-description {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          line-height: 21px;

          @include media-breakpoint-down(xs) {
            line-height: 18px;
            align-items: center;
          }

          .progress-text {
            margin-bottom: 0;
            line-height: 21px;

            @include media-breakpoint-down(xs) {
              font-size: 14px;
              line-height: 18px;
            }
          }

          .list-inline-item {
            color: $neutral-500;
          }

          .client-type {
            @include media-breakpoint-down(xs) {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }

        .progress {
          margin-top: 8px;
        }

        .client-profile-short-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 14px;
          margin-top: 9px;

          .short-info {
            display: flex;

            @include media-breakpoint-down(xs) {
              &:last-child {
                display: none;
              }
            }

            .info-item {
              position: relative;
              font-size: 12px;
              line-height: 14px;
              color: $neutral-500;

              @include media-breakpoint-down(xs) {
                font-size: 10px;
                line-height: 12px;
              }

              &:not(:last-child) {
                margin-right: 20px;

                &::after {
                  content: '';
                  position: absolute;
                  right: -12px;
                  top: 50%;
                  transform: translateY(-50%);
                  display: block;
                  width: 4px;
                  height: 4px;
                  background-color: $neutral-200;
                  border-radius: 50%;

                  @include media-breakpoint-down(xs) {
                    right: -6px;
                  }
                }

                @include media-breakpoint-down(xs) {
                  margin-right: 11px;
                }
              }
            }
          }
        }
      }

      & > .btn-icon-only {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;

        @include media-breakpoint-down(xs) {
          right: -2px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .client-info-content {
      .client-info {
        @include media-breakpoint-only(xs) {
          padding: 16px 0 0;
        }

        .small {
          .client-type {
            display: none;
          }
        }

        .client-buttons-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;

          padding: 16px;
          border-top: 1px solid $neutral-200;
          border-bottom: 1px solid $neutral-200;

          .calculations-type-dropdown {
            flex-grow: 0;
          }
        }

        .clients-bio-container {
          padding: 24px 24px 48px 24px;

          @include media-breakpoint-down(xs) {
            padding: 24px 12px 32px;
          }

          .profile-links {
            display: flex;
            margin-left: 117px;
            margin-bottom: 24px;

            @include media-breakpoint-down(lg) {
              margin-left: 0;
            }

            @include media-breakpoint-down(sm) {
              display: none;
            }

            .client-column,
            .ex-column {
              width: 340px;

              .btn.custom-button {
                display: flex;
                justify-content: flex-start;
              }
            }

            .btn.custom-button {
              font-weight: 400;
              height: 24px;
            }

            @include media-breakpoint-down(xs) {
              margin-left: 0;
            }
          }

          .notification-card {
            margin-left: 117px;

            @include media-breakpoint-down(lg) {
              margin-left: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .body-container {
              @include media-breakpoint-down(xs) {
                padding: 8px !important;
              }

              .card-body p {
                @include media-breakpoint-down(xs) {
                  font-size: 14px;
                  line-height: 16px;
                  margin-bottom: 0;
                }
              }

              .card-footer {
                justify-content: space-between;
              }
            }
          }
        }

        @import 'intake';
        @import 'interview';
        @import 'documents';
      }
    }

    .notification-card {
      background-color: $neutral-100;
      padding: 4px;

      .card-footer {
        margin-top: 12px;
        justify-content: space-between;

        .custom-button {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &:not(:first-child) {
        margin-top: 24px;
      }

      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }
    }

    .list-group-item.hover-shadow-sm {
      transition: $transition-base;

      &:not(.opened):focus,
      &:not(.opened):hover {
        box-shadow: $shadow-sm;
      }
    }
  }
}

.modal .update-intake .modal-content {
  .modal-body {
    .update-intake-form .intake-form-input {
      &:last-of-type .custom-input {
        margin-bottom: 0;
      }
    }
  }
}

.help-tooltip {
  width: 100%;
  max-width: 60vw;

  .tooltip-inner {
    max-width: initial;
    max-width: 60vw;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 8px;
    padding: 12px;

    span {
      margin: 3px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.client-list-skeleton {
  display: flex;
  flex-direction: column;
  gap: 16px;

  br {
    display: none;
  }
}
