#tax.calculator-section-container {
  .avatar-sm {
    width: 31px;
    height: auto;
  }
  .amount-tabs {
    .badge {
      background-color: $white;
      color: $black;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    @include media-breakpoint-down(xs) {
      .nav-item {
        padding: 8px 11px;
        font-size: 0.8rem;

        .badge {
          min-width: 0.9rem;
          min-height: 0.9rem;
          font-size: 0.6rem;
        }
      }
    }
  }

  .amount-table {
    .amount-table-header {
      padding: 16px;
      background-color: #f6f5f9;
      border-top: 1px solid $secondary;
    }
    @include media-breakpoint-down(xs) {
      .amount-table-header {
        padding: 8px;
      }
    }
    .amount-table-row {
      padding: 16px;
      border-top: 1px solid $secondary;
      user-select: none;

      & > .row {
        margin: -16px;
        padding: 16px;
      }

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          background: $secondary;
        }
      }

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      .btn-reset {
        margin-top: 8px;
        padding: 0 15px;
        background-color: $white;
        border-color: $black;
        color: $black;
        display: block;

        &:hover {
          background-color: $black;
          color: $white;
        }
      }

      .text-truncate {
        user-select: text;
      }
    }
    @include media-breakpoint-down(xs) {
      .amount-table-row {
        font-size: 0.8rem;
      }
    }
  }
}
