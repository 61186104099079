.table-panel {
  border-radius: 4px;
  background-color: $white;

  &__header {
    min-height: 56px;
    padding: 10px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__title {
    margin: 0;

    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }
}

.table-panel__body {
  table {
    width: 100%;
  }

  thead {
    tr {
      background: linear-gradient(0deg, $neutral-100, $neutral-100),
        linear-gradient(0deg, $neutral-200, $neutral-200);
      border-top: 1px solid $neutral-200;
      border-bottom: 1px solid $neutral-200;
    }

    th {
      padding: 8px;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid $neutral-200;

      &:first-child {
        border-top: none;
      }
    }

    td {
      vertical-align: top;

      padding: 16px 8px;

      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }
}
