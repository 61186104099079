.modal .modal-dialog.select-calculation-modal.modal-md {
  @include media-breakpoint-up(sm) {
    max-width: 672px;
  }

  .modal-content {
    .modal-body {
      .description {
        &:first-child {
          margin-bottom: 10px;
        }
      }

      .calculations-selector {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        width: 100%;
        min-height: 150px;
        max-height: 286px;
        border: 1px solid $neutral-300;
        border-radius: 4px;

        @include media-breakpoint-down(xs) {
          min-height: 200px;
        }

        &.empty {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .no-calculations {
          max-width: 292px;

          font-family: 'DM Sans', sans-serif;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $neutral-black;

          @include media-breakpoint-down(xs) {
            font-size: 16px;
            line-height: 21px;
          }
        }

        .calculations-selector-header {
          display: flex;
          background-color: $neutral-100;
          border-bottom: 1px solid $neutral-300;

          .header-cell {
            padding: 8px;
            color: $neutral-600;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;

            &.name {
              width: 100%;
              max-width: calc(100% - 186px);
            }

            &.type {
              width: 100%;
              max-width: 76px;
            }

            &.created-at {
              width: 100%;
              max-width: 110px;
            }
          }
        }

        .calculations-selector-list {
          max-height: 254px;
          overflow-y: auto;

          .calculation {
            display: flex;
            justify-content: space-between;
            width: 100%;
            transition: background-color 0.1s ease-in-out;
            cursor: pointer;
            border-bottom: 1px solid $neutral-300;

            &.active {
              background-color: $primary-400;

              .calculation-name,
              .calculation-type,
              .calculation-created-at {
                color: $white;
              }
            }

            @include media-breakpoint-down(xs) {
              font-size: 14px;
              line-height: 16px;
            }

            .calculation-name,
            .calculation-created-at,
            .calculation-type {
              padding: 10.5px 7px 9.5px 8px;
              padding-right: 5px;

              font-family: 'Roboto', sans-serif;
              font-size: 16px;
              color: $neutral-black;
              line-height: 19px;
            }

            .calculation-name {
              width: 100%;
              max-width: calc(100% - 186px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-right: 1px solid $neutral-300;
            }

            .calculation-type {
              width: 76px;
              min-width: 76px;
              overflow: hidden;
              border-right: 1px solid $neutral-300;
            }

            .calculation-created-at {
              width: 110px;
              min-width: 110px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .custom-select-input {
        .custom-select__menu-list {
          max-height: 130px;
        }
      }
    }
  }
}
