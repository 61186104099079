.report-container {
  padding: 24px;
  padding-right: 9px;
  border-radius: 0 0 4px 4px;
  position: relative;

  @import 'upgrade-cta';

  &.detailed {
    padding: 0;

    .upgrade-account-cta {
      top: 1684px;
    }
  }

  &.condensed {
    .upgrade-account-cta {
      top: 740px;
    }
  }

  .report-layout {
    width: 100% !important;

    table {
      max-width: unset;

      thead tr th:first-child {
        width: 400px !important;
      }
    }
  }

  .result-section {
    padding: 0;

    .doughnut-row {
      height: 240px;
    }
  }

  .support-report-pages {
    background-color: $white;

    .support-report-preloader-container {
      padding: 20px;
    }

    .report-cover,
    .report-contents,
    .spousal-slider {
      display: none;
    }

    .report-contents,
    .support-report-page,
    .report-cover {
      width: 1138px;
    }
  }

  &.condensed.free-report {
    .reportBody {
      filter: blur(5px);
      will-change: filter;
      user-select: none;
      pointer-events: none;
    }
  }

  &.detailed.free-report {
    .support-report-page {
      filter: blur(5px);
      will-change: filter;
      user-select: none;
      pointer-events: none;

      &:nth-child(3) {
        filter: none;
      }
    }
  }
}
