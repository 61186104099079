.plans-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 32px;

  &.pro {
    margin-bottom: 16px;
    justify-content: space-between;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
