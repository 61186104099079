// Edit Possessions Input Controls
// Contains all input, textarea, and select styles for the possessions modal

/* Global highest priority rule for collapsed textareas */
html body textarea.auto-resize-textarea.collapsed,
html body .family-property-calculator-modal textarea.collapsed,
html body .family-property-calculator-form textarea.collapsed,
html body .description-field textarea.collapsed,
html body textarea.form-control.collapsed {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  overflow: hidden !important;
  resize: none !important; /* Keep collapsed textareas non-resizable */
  box-sizing: border-box !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  transition: none !important;
}

/* Custom stronger text-muted class to override Bootstrap's text-muted */
.stronger-text-muted {
  color: #495057 !important; 
  opacity: 0.9 !important;
}

/* New hover-based edit button styling */
.family-property-calculator-form {
  .possession-row {
    position: relative; /* Ensure proper positioning */
    
    /* Hide toggle button by default */
    .toggle-button {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    
    /* Show button on row hover */
    &:hover {
      .toggle-button {
        opacity: 1;
      }
    }
  }
}

// General input control styles
.family-property-calculator-form {
  // General form control styles that need to be overridden for textareas
  .form-control {
    border-radius: 4px;
    border-color: #ced4da;
    transition: border-color 0.2s ease;
    padding: 0.25rem 0.5rem;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    font-size: 0.9rem;
    position: relative;
    z-index: 2;
    background-color: #fff !important;

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  input[type="text"] {
    padding: 0.25rem 0.5rem;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color: #fff !important;
  }

  .align-items-center {
    padding: 0.2rem 0;
    
    // Apply height constraints to all form controls EXCEPT textareas
    .form-control:not(textarea), 
    input:not(textarea), 
    select, 
    .input-group, 
    .currency-input {
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      background-color: #fff !important;
    }
  }

  input, .form-control {
    line-height: normal;
  }
}

// React-Select Styles
.family-property-calculator-modal {
  // Improved styles for React-Select menu portals, properly scoped to this component
  // Instead of using :global selectors, use descendant selectors
  .react-select__menu-portal,
  div[class*="menu-portal"],
  #react-select-menu-portal {
    z-index: 9999 !important; // Higher z-index to appear above other elements
    
    // Style consistency for menu content in portals
    .react-select__menu,
    [class*="menu"] {
      background-color: #fff !important;
      border: 1px solid #ced4da !important;
      border-radius: 4px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
      margin-top: 2px !important;
      
      // Force menu to be wider than parent select on narrow screens
      min-width: 100% !important; // At minimum, match parent width
      width: auto !important; // Allow menu to expand beyond parent width
      max-width: 220px !important; // Set a specific wider width for select menus
    }
    
    // Consistent option styling in portals
    .react-select__option,
    [class*="option"] {
      font-size: 0.9rem !important; // Match font size with other form elements
      padding: 6px 12px !important;
      line-height: 1.2 !important;
      white-space: normal !important; // Allow text to wrap if needed
      word-break: break-word !important; // Ensure long words break properly
      
      &:active, &:focus, &:hover {
        background-color: rgba(0, 123, 255, 0.1) !important;
      }
      
      &[class*="option--is-selected"] {
        background-color: #007bff !important;
        color: white !important;
      }
    }
  }
  
  // Style for React-Select controls - Fix dropdown selected value disappearing issue
  .custom-select__control {
    min-height: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 0 !important;
    overflow: visible !important;
    box-sizing: border-box !important;
    position: relative !important;
    z-index: 1 !important;
  }
  
  .custom-select__value-container {
    padding: 0 8px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    overflow: hidden !important;
    flex: 1 !important;
    position: relative !important;
    z-index: 1 !important;
    box-sizing: border-box !important;
    min-width: 0 !important; /* Ensures truncation works */
  }
  
  .custom-select__single-value {
    color: #212529 !important;
    position: absolute !important;
    max-width: 90% !important; 
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-weight: 400 !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 50% !important;
    left: 8px !important;
    transform: translateY(-50%) !important;
    opacity: 1 !important; 
    visibility: visible !important;
    line-height: 1 !important;
    font-size: 0.9rem !important;
    z-index: 1 !important;
    display: block !important; /* Use block instead of flex for proper truncation */
    max-height: 20px !important; /* Ensure it fits within the container */
  }
  
  .custom-select__indicator {
    padding: 2px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    z-index: 1 !important; // Ensure indicators are visible
  }
  
  .custom-select__placeholder {
    color: #6c757d !important;
    font-weight: normal !important;
    opacity: 0.7 !important;
    font-size: 0.9rem !important;
    z-index: 1 !important; // Ensure placeholder is visible
  }
  
  // Fix for select options in React-Select
  .type-select {
    width: 100%;
    font-size: 0.9rem;
    
    & > div {
      box-sizing: border-box !important;
    }
    
    .custom-select__control,
    div[class*="-control"] {
      border: 1px solid #ced4da !important;
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      box-sizing: border-box !important;
      overflow: visible !important;
      position: relative !important;
      
      &::after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        border: 1px solid #ced4da;
        pointer-events: none;
        border-radius: 4px;
        z-index: 0;
      }
    }
    
    // Restore value container styling
    .css-1hwfws3,
    [class*="ValueContainer"] {
      padding: 0 8px !important;
      height: 30px !important;
      overflow: hidden !important;
      position: relative !important;
      z-index: 1 !important;
    }
    
    // Restore single value styling
    .css-1uccc91-singleValue,
    .css-dvua67-singleValue,
    [class*="singleValue"] {
      position: absolute !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      margin: 0 !important;
      max-width: 90% !important;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
  
  // Styles for React-Select menus when opened directly (not in portal)
  .custom-select__menu {
    z-index: 9999 !important; // Higher z-index to appear above other elements
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
    margin-top: 2px !important;
    
    .custom-select__option {
      padding: 6px 12px !important;
      font-size: 0.9rem !important;
      
      &:active, &:focus, &:hover {
        background-color: rgba(0, 123, 255, 0.1) !important;
      }
      
      &.custom-select__option--is-selected {
        background-color: #007bff !important;
        color: white !important;
      }
    }
  }
}

/* AGGRESSIVE OVERRIDE FOR REACTSTRAP GRID */
.family-property-calculator-form {
  // !important overrides for reactstrap grid system to ensure responsive behavior
  .possession-row {
    position: relative !important; // Ensure the position context is established
    overflow: visible !important; // Allow children to overflow without being clipped
    
    .row {
      display: flex !important;
      flex-wrap: nowrap !important;
      width: 100% !important;
      margin: 0 !important;
      overflow: visible !important; // Ensure overflow content is visible
      position: relative !important; // Create positioning context
      
      // CRITICAL: Don't wrap on medium screens, just rearrange
      @media (max-width: 991px) {
        flex-wrap: nowrap !important; // Override any wrap settings
        width: 100% !important;
      }
    }
    
    // Target reactstrap col-4 containing possession-info
    [class*="col-"], .col, .col-4 {
      overflow: visible !important; // Important: Allow children to overflow
      position: relative !important; // Create positioning context
      
      @media (max-width: 991px) {
        flex-basis: auto !important; // Allow flex basis to adjust
        min-width: 0 !important; // Critical for proper flex shrinking
        padding: 0.1rem 0.25rem !important;
      }
    }
    
    // Fix padding on col-2 elements specifically
    [class*="col-2"], .col-2 {
      padding-left: 1px !important;
      padding-right: 1px !important;
      flex: 0 0 135px !important; // Further reduced from 140px to 135px
      max-width: 135px !important; // Match max-width
      width: 135px !important; // Add explicit width property
      flex-shrink: 0 !important; // Prevent shrinking
      
      @media (max-width: 991px) {
        padding-left: 1px !important;
        padding-right: 1px !important;
        flex: 0 0 auto !important; // Reset to auto for responsive views
        max-width: none !important; // Reset max-width for responsive views
        width: auto !important; // Reset width for responsive views
      }
    }
  }
}

/* FORCE SINGLE ROW LAYOUT */
.family-property-calculator-form .possession-row .possession-info {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  width: 100% !important;
  min-width: 0 !important;
  position: relative !important; // Establish positioning context
  overflow: visible !important; // Critical: Allow elements to overflow without clipping
  
  /* Force children to stay on one row */
  > * {
    flex-shrink: 1;
    min-width: 0; // Critical for proper shrinking
  }
  
  /* Control shrinking priority */
  .toggle-button {
    flex: 0 0 auto !important;
    margin-right: 5px !important;
  }
  
  .possession-actions {
    flex: 0 0 auto !important;
    margin-left: -20px !important;
    flex-shrink: 0 !important;
    order: 1; // First element in the flex container
    position: relative !important; // Position relative to allow z-index to work
    z-index: 10 !important; // Higher z-index to appear above borders
  }
  
  .category-label-field {
    flex: 0 1 150px !important;
    min-width: 0 !important;
    flex-shrink: 5 !important;
    order: 2; // Second element
  }
  
  .description-field {
    flex: 1 1 auto !important;
    min-width: 0 !important;
    flex-shrink: 1 !important;
    flex-grow: 1 !important;
    min-width: 180px !important;
    order: 3; // Third element
    padding-right: 12px !important; // Add right padding to create space between description and valuation columns
  }
}

/* CATEGORY LABEL INPUT STYLES */
.family-property-calculator-form {
  // Target the Col xs={4} parent that wraps our possession-info with a highly specific selector
  .possession-row > div > div.col-4,
  .possession-row > div > [class*="col-4"],
  .possession-row [class*="col-4"] {
    // Important: Allow the col to be flexible, overriding reactstrap fixed width behavior
    padding-right: 0 !important; // Remove right padding to fix button alignment
    flex: 0 0 calc(100% - 580px) !important; // Make sure all values match
    max-width: calc(100% - 580px) !important; // Match the max-width with the flex value
    width: calc(100% - 580px) !important; // Fixed to match other values
    flex-basis: calc(100% - 548px) !important; // Add explicit flex-basis
    // flex-shrink: 0 !important; // Prevent shrinking
    
    @media (max-width: 991px) {
      padding-left: 5px !important;
      padding-right: 0 !important; // Ensure no right padding in responsive view
      flex-basis: auto !important; // Reset to auto for responsive views
      max-width: none !important; // Reset max-width for responsive views
      width: auto !important; // Reset width property for responsive views
    }
  }
  
  // Styles for the typeAlias input field that appears only on hover
  .possession-row {
    // Hover effect for the entire row
    &:hover {
      .category-label-field {
        input.form-control,
        input.form-control-sm,
        .type-alias-input {
          border-color: rgba(206, 212, 218, 0.3) !important;
        }
      }
    }
    
    // Make the possession-info container more responsive
    .possession-info {
      // Use flexbox for better responsive behavior
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      min-width: 0 !important; // Critical for flex children to shrink properly
      flex-wrap: nowrap !important; // CRITICAL: Prevent wrapping to new line
      padding-right: 0 !important; // Remove any right padding that might create space
      
      // Prioritize description field by setting a proper shrink order
      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
      }
      
      // Ensure flex items can shrink properly
      > * {
        flex-shrink: 1;
      }
      
      // Style the possession actions container
      .possession-actions {
        flex-shrink: 0 !important;
        flex-grow: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 1; // First element in the flex container
        padding: 0 4px 0 0;
        margin-right: 5px !important;
        position: relative !important; // Enable z-index
        z-index: 10 !important; // Ensure it appears above any borders
        
        .toggle-button {
          width: 30px !important;
          min-width: 30px !important;
          height: 30px !important;
          padding: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border-radius: 50%;
          border: none;
          color: #6c757d;
          transition: all 0.2s ease-in-out;
          margin: 0;
          z-index: 10 !important; // Ensure the button itself has a high z-index
          
          &:hover {
            background-color: rgba(0, 123, 255, 0.1);
            color: #007bff;
          }
        }
      }

      // typeAlias should shrink before description
      .category-label-field {
        order: 2; // Second element
        flex-shrink: 5 !important; // Shrink this more aggressively than description
      }
      
      // Description should have highest priority to maintain width
      .description-field {
        order: 3; // Third element
        flex-shrink: 1 !important; // Shrink this less aggressively
        flex-grow: 1 !important; // Ensure it grows to fill available space
        min-width: 180px !important; // Ensure a reasonable minimum width to utilize the extra space
        padding-right: 12px !important; // Add right padding to create space between description and valuation columns
      }
    }
    
    // Styling for the input container
    .category-label-field {
      min-width: 0 !important; // Allow it to shrink properly
      width: 150px !important;
      max-width: 150px !important; // Add max-width to prevent unwanted growth
      margin-right: 5px !important;
      flex: 0 1 150px !important; // Allow shrinking but not growing
      transition: all 0.3s ease-in-out !important;
      
      // Target all potential input classes
      input.form-control, 
      input.form-control-sm,
      .type-alias-input {
        border: 1px solid transparent !important;
        background-color: transparent !important;
        transition: all 0.2s ease-in-out !important;
        width: 100% !important; // Use 100% instead of fixed width for better responsiveness
        padding: 0.25rem 0.5rem !important;
        height: 30px !important;
        color: #495057;
        font-size: 0.9rem !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        
        // When empty, make the placeholder more visible even without border
        &:placeholder-shown {
          color: #495057 !important;
        }
        
        // When directly hovered, show full border
        &:hover {
          border-color: #ced4da !important;
          background-color: rgba(255, 255, 255, 0.5) !important;
        }
        
        // When focused, show full styling
        &:focus {
          background-color: #fff !important;
          border-color: #80bdff !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
        }
        
        // Placeholder styling - make it appear more like normal text when not focused
        &::placeholder {
          color: #495057;
          opacity: 0.85;
          font-weight: 400;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }
    
    // Make the description field more responsive
    .description-field {
      flex: 1 1 auto !important; // Take up remaining space
      min-width: 100px !important; // Set a minimum width to prevent disappearing
      margin-left: 5px !important;
      width: auto !important; // Force auto width
      overflow: hidden !important; // Prevent overflow
      
      // Ensure the textarea can grow/shrink with its container
      textarea {
        width: 100% !important;
        box-sizing: border-box !important;
        min-width: 0 !important;
      }
    }
  }
}

// Responsive behavior for the possession-info containers
@media (max-width: 991px) {
  .family-property-calculator-form {
    .possession-row {
      // Force the col-4 to be more responsive
      [class*="col-4"] {
        width: auto !important;  
        max-width: none !important;
        flex: 1 1 auto !important;
      }
      
      .possession-info {
        // When screen gets medium small, adjust the layout
        .category-label-field {
          min-width: 0 !important; // Allow proper shrinking
          width: 130px !important;
          max-width: 130px !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .family-property-calculator-form {
    .possession-row {
      // Reduce padding in the main row
      padding: 0.15rem !important;
      
      .possession-info {
        // When screen gets smaller, let typeAlias shrink first
        .category-label-field {
          min-width: 0 !important; // Allow proper shrinking
          width: 100px !important;
          max-width: 100px !important;
        }
        
        // Adjust margin for smaller screens
        .description-field {
          margin-left: 4px !important;
        }
        
        // Slightly reduce edit button size
        .toggle-button {
          min-width: 28px !important;
          width: 28px !important;
          height: 28px !important;
          margin-right: 4px !important;
        }
      }
    }
  }
}

// When description field is narrower, shrink typeAlias further
@media (max-width: 576px) {
  .family-property-calculator-form {
    .possession-row {
      // Reduce padding further
      padding: 0.1rem 0.05rem !important;
      
      .possession-info {
        // When description gets really narrow, shrink typeAlias to minimum
        .category-label-field {
          min-width: 0 !important; // Allow proper shrinking
          width: 80px !important;
          max-width: 80px !important;
        }
        
        // Reduce margins even more
        .description-field {
          margin-left: 3px !important;
        }
        
        // Adjust toggle button
        .toggle-button {
          min-width: 26px !important;
          width: 26px !important;
          height: 26px !important;
          margin-right: 3px !important;
          padding: 0 !important;
        }
      }
    }
  }
}

// Extra small screens - optimize for mobile
@media (max-width: 480px) {
  .family-property-calculator-form {
    .possession-row {
      // Reduce padding on the entire row
      padding: 0.05rem 0 !important;
      
      .possession-info {
        // Tighten up spacing
        .category-label-field {
          min-width: 0 !important;
          width: 70px !important;
          max-width: 70px !important;
          flex: 0 1 70px !important; // Allow shrinking but not growing
          margin-right: 2px !important;
        }
        
        .description-field {
          margin-left: 2px !important;
          min-width: 80px !important; // Ensure it doesn't disappear completely
        }
        
        .toggle-button {
          min-width: 24px !important;
          width: 24px !important;
          height: 24px !important;
          margin-right: 2px !important;
          padding: 0 !important;
        }
        
        // Adjust input field sizes
        input.form-control, 
        input.form-control-sm,
        .type-alias-input {
          padding: 0.15rem 0.25rem !important;
          font-size: 0.85rem !important;
        }
        
        // Make textarea more compact
        textarea.form-control,
        textarea.auto-resize-textarea {
          padding: 0.15rem 0.25rem !important;
          font-size: 0.85rem !important;
        }
      }
      
      // Make currency inputs more compact
      .currency-input, 
      .compact-currency {
        padding: 0.15rem 0.50rem !important;
      }
    }
  }
}

/* TEXTAREA SPECIFIC STYLES */
.family-property-calculator-form {
  // Possession row textarea styles
  .possession-row {
    .possession-info {
      .description-field {
        flex: 1;
        min-width: 0; // Allow field to shrink if needed
        margin-left: 0.5rem;
        
        // Override textarea styling for auto-resizing
        textarea.form-control,
        textarea.auto-resize-textarea {
          // These override existing constraints
          min-height: 30px !important;
          max-height: none !important;
          overflow: hidden !important;
          transition: height 0.15s ease !important;
          resize: vertical !important; /* Always show resize handle to indicate expandability */
          
          // Styling for appearance
          padding: 5px 8px !important;
          font-size: 0.9rem !important;
          line-height: 1.4 !important;
          white-space: pre-wrap !important;
          word-break: break-word !important;
          
          // Remove fixed height constraints that might be coming from other styles
          height: auto !important;
          
          // Improve focus styling
          &:focus {
            border-color: #80bdff !important;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
            z-index: 10 !important;
            position: relative !important;
          }
          
          // Remove the conditional hiding of resize handle for single line textareas
          // Instead, only hide resize handle for collapsed textareas
          &.collapsed {
            resize: none !important;
          }
        }
      }
    }
    
    /* CRITICAL OVERRIDE for all textareas - this is the most important section */
    /* Using the strongest possible specificity to ensure these rules are applied */
    .description-field textarea.form-control,
    .description-field textarea.auto-resize-textarea,
    textarea.auto-resize-textarea,
    .possession-info .description-field textarea.form-control {
      /* Simplify to basic necessary styles */
      min-height: 30px !important;
      /* Allow height to be controlled by JS when needed */
      height: auto !important;
      max-height: none !important;
      resize: vertical !important; /* Always show resize handle to indicate expandability */
      overflow: hidden !important;
      width: 100% !important;
      
      /* Add smooth transition for height changes */
      transition: height 0.15s ease-in-out, border-color 0.2s ease !important;
      
      /* Basic appearance styles */
      padding: 5px 8px !important;
      font-size: 0.9rem !important;
      line-height: 1.4 !important;
      white-space: pre-wrap !important;
      word-break: break-word !important;
      
      /* Focus styling */
      &:focus {
        border-color: #80bdff !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
        position: relative !important;
        z-index: 5 !important;
      }
      
      /* Force-resize class for extreme cases */
      &.force-resize {
        height: attr(data-height) !important; /* Fallback */
        min-height: 30px !important;
        max-height: none !important;
        overflow: hidden !important;
      }
      
      /* Remove conditional hiding of resize handle for single line textareas */
      
      /* CRITICAL: Allow the collapsed class to override everything else */
      &.collapsed {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        overflow: hidden !important;
        resize: none !important;
        transition: none !important;
      }
    }
    
    /* NEW: Simple auto-resize mechanism that will override all other styles */
    .description-field {
      textarea {
        transition: height 0.1s ease-in-out !important;
        min-height: 30px !important;
        /* Allow height to be controlled by JS when needed */
        height: auto !important;
        max-height: none !important;
        resize: vertical !important; /* Always show resize handle to indicate expandability */
        
        /* Override any existing important height constraints EXCEPT inline styles */
        &.form-control, &[class*="form-control"], &[class] {
          /* Allow inline styles to take precedence */
          height: auto !important;
          max-height: none !important;
          overflow: hidden !important;
        }
        
        /* When focused, ensure it can grow */
        &:focus {
          min-height: 30px !important; /* Changed from 60px to allow collapsing even when focused */
          /* Allow inline styles to take precedence */
          height: auto !important;
          overflow: hidden !important;
          resize: vertical !important; /* Always show resize handle when focused */
        }
        
        /* Remove conditional hiding of resize handle for single line textareas */
        
        /* CRITICAL: Ensure collapsed class overrides all other styles */
        &.collapsed {
          height: 30px !important;
          min-height: 30px !important;
          max-height: 30px !important;
          overflow: hidden !important;
          resize: none !important;
          transition: none !important;
        }
      }
    }
    
    /* ADDITIONAL: Explicitly ensure inline styles take precedence */
    .description-field textarea[style*="height"] {
      /* Always show resize handle, even at single line height */
      resize: vertical !important;
      
      /* Only hide resize for collapsed textareas */
      &.collapsed {
        resize: none !important;
      }
    }
    
    /* CRITICAL: Super-high specificity rule for .collapsed that will override everything */
    body .family-property-calculator-form .possessions-list .possession-row .description-field textarea.collapsed,
    body .description-field textarea.auto-resize-textarea.collapsed,
    body textarea.auto-resize-textarea.collapsed,
    body .family-property-calculator-modal textarea.collapsed {
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      overflow: hidden !important;
      resize: none !important;
      box-sizing: border-box !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      transition: none !important;
    }
    
    /* Ensure the description field container can properly position the textarea */
    .description-field {
      flex: 1 1 auto !important;
      min-width: 0 !important;
      margin-left: 0.5rem !important;
      position: relative !important;
      height: auto !important;
    }
  }
  
  // Detailed textarea styles for possession details section
  .possession-details {
    textarea.form-control {
      resize: vertical;
      min-height: 60px;
      max-height: none; // Allow textarea to expand vertically
    }
  }
}

/* CURRENCY INPUT STYLES */
.family-property-calculator-form {
  // Updated currency input styling with fixed width constraint and proper borders
  .currency-input {
    text-align: right;
    font-size: 0.9rem;
    font-weight: 500;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    padding: 0.2rem 0.6rem;
    line-height: 1.1;
    font-family: inherit;
    background-color: #fff !important;
    border: 1px solid #ced4da !important; // Ensure border is visible
    border-radius: 4px;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    width: 120px !important; // Reduced width to ensure it doesn't overflow its column
    margin-left: auto !important; // Right align within container
    margin-right: 0 !important; // Remove right margin to avoid causing overflow
    box-sizing: border-box !important; // Ensure padding is included in width
  }
  
  .compact-currency {
    width: 100%;
    margin: 0 auto;
    padding: 0 !important;
    display: flex !important;
    justify-content: flex-end !important; // Right align in column
    padding-right: 0 !important; // Remove padding
    margin-right: 0 !important; // Remove negative margin that was causing layout issues
    
    .currency-input {
      width: 110px !important; // Reduced from 115px to fit the 135px column
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      text-align: right;
      overflow: visible;
      background-color: #fff !important;
      box-shadow: none !important;
      border: 1px solid #ced4da !important; // Ensure border is visible
      box-sizing: border-box !important; // Ensure padding is included in width
    }
    
    .input-group {
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      overflow: visible;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 4px;
      border: none;
      box-shadow: none;
      width: 110px !important; // Reduced from 115px
      box-sizing: border-box !important; // Ensure padding is included in width
      margin-right: 0 !important; // Remove right margin
      
      .form-control {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        padding: 0.2rem 0.6rem;
        text-align: right;
        width: 110px !important; // Fixed width to ensure proper alignment
        border: 1px solid #ced4da !important; // Ensure border is visible
        box-sizing: border-box !important; // Ensure padding is included in width
      }
    }
    
    input.form-control {
      text-align: right;
      padding-right: 0.6rem;
      font-weight: 500;
      height: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      border-radius: 4px;
      background-color: #fff !important;
      width: 110px !important; // Fixed width to ensure proper alignment
      border: 1px solid #ced4da !important; // Ensure border is visible
      box-sizing: border-box !important; // Ensure padding is included in width
    }
  }
}

/* DATEPICKER STYLES */
.family-property-calculator-form {
  .react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container {
      input {
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        font-size: 0.9rem;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}

/* Modern Edit Button Styles */
.family-property-calculator-form {
  /* Add global icon sizing constraints */
  .icon {
    /* Make sure all icons have reasonable default dimensions if not overridden */
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
  }
  
  .possession-row {
    .possession-actions {
      .toggle-button {
        &.btn-icon {
          width: 32px !important;
          height: 32px !important;
          border-radius: 50% !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          background-color: rgba(0, 123, 255, 0.05) !important;
          color: #6c757d !important;
          transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
          padding: 0 !important;
          margin-left: 8px !important;
          
          /* The container div that holds the icon - this is what we'll rotate */
          .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            transition: transform 0.2s ease-in-out;
            transform-origin: center center;
          }
          
          &:hover {
            background-color: rgba(0, 123, 255, 0.1) !important;
            color: #007bff !important;
            
            .icon-container {
              transform: rotate(15deg);
            }
          }
        }
      }
    }
  }
}

/* AGGRESSIVE GRID OVERRIDES - MUST BE AT END OF FILE */
.family-property-calculator-form .possession-row .row > .col-4,
.family-property-calculator-form .possession-row .row > [class*="col-4"] {
  width: calc(100% - 587px) !important;
  max-width: calc(100% - 587px) !important;  
  flex: 0 0 calc(100% - 587px) !important;
  flex-basis: calc(100% - 587px) !important;
}

/* DIRECT STYLE OVERRIDE FOR COMPUTED STYLE PROBLEM */
#FAMILY_PROPERTY_CALCULATOR .possessions-list div .possession-row .row > .col-4 {
  width: calc(100% - 587px) !important;
  max-width: calc(100% - 587px) !important;  
  flex: 0 0 calc(100% - 587px) !important;
  flex-basis: calc(100% - 587px) !important;
  box-sizing: border-box !important;
} 

.invalid-feedback.fade:empty {
  //display: none !important;
  //height: 0 !important;
  width: 15px !important;
  //position: absolute !important;
  //visibility: hidden !important;
}

/* FINAL ADJUSTMENTS FOR BUTTON VISIBILITY */
.family-property-calculator-form {
  /* Remove duplicate selectors and consolidate styles */
  .possession-row .possession-info .possession-actions {
    /* We don't need this anymore since it's covered in the comprehensive solution */
  }
  
  /* Clean up any conflicting styles */
  .possession-info {
    .possession-actions {
      /* We don't need this anymore */
    }
  }
}

/* COMPREHENSIVE SOLUTION FOR OVERLAPPING BUTTON */
.family-property-calculator-form {
  /* Ensure all parent containers allow overflow */
  .possessions-list,
  .possession-group,
  .subcategory,
  .possession-rows-container,
  .possession-row,
  .row,
  [class*="col-"],
  .possession-info {
    overflow: visible !important;
    position: relative !important;
  }
  
  /* Core styles for the possession row */
  .possession-row {
    /* No left padding - we want to maximize space and let button overlap edge */
    padding-left: 0 !important;
    
    /* Remove left border that might clip the button */
    border-left: none !important;
    
    /* Ensure the row elements position properly */
    .row {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
    
    /* Adjust the main column containing our elements */
    > div > .col-4,
    > div > [class*="col-4"] {
      overflow: visible !important;
      position: relative !important;
      padding-left: 0 !important;
    }
    
    /* Style the possession info container */
    .possession-info {
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      min-width: 0 !important;
      overflow: visible !important;
      padding-left: 0 !important;
      
      /* Position the actions to overlap the edge */
      .possession-actions {
        margin-left: -25px !important; /* Push button even further left to overlap more */
        position: relative !important;
        z-index: 20 !important; /* High z-index to ensure visibility */
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-shrink: 0 !important;
        order: 1 !important;
        opacity: 0 !important; /* Hide button by default */
        transition: opacity 0.2s ease-in-out !important;
        
        /* Style the button itself */
        .toggle-button {
          width: 28px !important;
          height: 28px !important;
          min-width: 28px !important;
          background-color: white !important;
          border: 1px solid rgba(0,0,0,0.1) !important;
          border-radius: 50% !important;
          box-shadow: 0 2px 4px rgba(0,0,0,0.08) !important;
          z-index: 20 !important;
          position: relative !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          padding: 0 !important;
          transition: all 0.2s ease-in-out !important;
          
          /* Hover effects */
          &:hover {
            background-color: rgba(0, 123, 255, 0.1) !important;
            border-color: rgba(0, 123, 255, 0.2) !important;
            color: #007bff !important;
            transform: none !important; /* Remove the translateX that caused the movement */
          }
          
          /* Style the icon container for animation */
          .icon-container {
            transition: transform 0.2s ease-in-out, color 0.2s ease-in-out !important;
            
            &:hover {
              transform: rotate(15deg) !important;
            }
          }
        }
      }
      
      /* Make button visible on row hover */
      &:hover .possession-actions {
        opacity: 1 !important;
      }
      
      /* Style the possession info container */
      .possession-info {
        gap: 0 !important; /* Remove gap between elements */
        
        /* Adjust the category field positioning to shift left and tighten spacing */
        .category-label-field {
          order: 2 !important;
          margin-left: -5px !important; /* Pull it 5px to the left */
          margin-right: 0 !important; /* Zero margin */
          padding-right: 0 !important; /* Zero padding */
        }
        
        /* Description field should maintain its width but with zero spacing */
        .description-field {
          order: 3 !important;
          margin-left: 0 !important; /* Zero margin */
          padding-left: 0 !important; /* Zero padding */
        }
      }
    }
    
    /* Show button on row hover */
    &:hover .possession-actions {
      opacity: 1 !important;
    }
  }
}

/* Additional targeted fix for deeper nested version that appears to be present */
.family-property-calculator-form .possession-row .possession-info .possession-info {
  /* This appears to be a duplicated/nested selector in the original file */
  .possession-actions {
    margin-left: -25px !important; /* Keep this the same to maintain button position */
  }
  
  .category-label-field {
    margin-left: -5px !important; /* Pull it 5px to the left */
  }
}

/* Make sure the button styling is explicit and not accidentally overridden */
.family-property-calculator-form .possession-row .possession-info .possession-actions {
  margin-left: -25px !important; /* Explicit override */
}

/* FINAL ADJUSTMENTS - PULL CATEGORY FIELD LEFT */
.family-property-calculator-form .possession-row .possession-info .category-label-field {
  margin-left: -5px !important; /* Pull the category field left by 5px */
}

/* Ensure button position stays the same */
.family-property-calculator-form .possession-row .possession-info .possession-actions {
  margin-left: -25px !important; /* Keep button position the same */
}

/* NEW: Enhanced Empty State and Add Button Styles */
.family-property-calculator-form {
  // Enhanced empty state message styling
  .empty-subcategory {
    .possession-rows-container:empty::before {
      content: attr(data-empty-message, "No items added yet. Click '+ New' to add your first item");
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      color: #6c757d;
      font-style: italic;
      background-color: rgba(248, 249, 250, 0.7);
      border-radius: 4px;
      margin: 10px 0;
      min-height: 60px;
      text-align: center;
      font-size: 0.95rem;
      border: 1px dashed #dee2e6;
      grid-column: span 4; // Span all 4 value columns
      position: relative; // Added for button positioning
    }
  }
  
  // Enhanced new button styling for better discoverability
  .new-button-container {
    .subcategory-new-button {
      // Larger button for better visibility
      padding: 0.3rem 0.8rem !important;
      font-size: 0.9rem !important;
      font-weight: 500 !important;
      border-width: 1.5px !important;
      background-color: white !important;
      color: #007bff !important;
      border-color: #007bff !important;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
      
      // Pulse animation for empty subcategories
      &.pulse-animation {
        animation: button-pulse 2s infinite;
      }
      
      small {
        font-size: 0.9rem !important;
        font-weight: 500 !important;
      }
      
      &:hover {
        background-color: #007bff !important;
        color: white !important;
        transform: translateY(-2px) !important;
      }
    }
  }
  
  // Making new button more prominent in empty subcategories
  .empty-subcategory {
    .new-button-container {
      // Position button container absolutely within the empty message
      position: absolute !important;
      margin-top: 0 !important;
      top: 55px !important; // Moved 40px lower (from 15px to 55px)
      left: 15px !important;
      height: auto !important;
      z-index: 1000 !important;
      
      .subcategory-new-button {
        opacity: 1 !important;
        visibility: visible !important;
        position: static !important; // Changed from absolute to static
        left: auto !important; // Let the container position the button
        top: auto !important;
        padding: 0.4rem 1rem !important; // Larger padding
        font-size: 1rem !important; // Larger font
        border-width: 1.5px !important;
        
        &:hover {
          transform: translateY(-2px) !important; // Just lift on hover without centering
        }
        
        small {
          font-size: 1rem !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

// Pulse animation for the new button in empty states
@keyframes button-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}