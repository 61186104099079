.ad-banner {
  position: relative;
  overflow: hidden;

  padding: 16px 20px;
  border-radius: 8px;
  background: url('../../../img/backgrounds/cubes.png') white;
  box-shadow: $shadow-400;

  &__close.icon {
    position: absolute;
    top: 8px;
    right: 8px;
    margin-right: 0;

    color: $primary-400;
    cursor: pointer;

    &:hover {
      color: $primary-500;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    padding: 8px 28px;
    margin-bottom: 16px;
  }

  &__icon {
    width: 64px;
    height: 64px;
    min-width: 64px;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    &.--purple {
      border: 1px solid $primary-400;
      background: $primary-50;
    }

    &.--blue {
      border: 1px solid #41a5ee;
      background: #f1f9ff;
    }
    
    &.--green {
      border: 1px solid #16A34A;
      background: #f0fdf4;
    }
  }

  &__cross {
    color: $neutral-500;
    margin-right: 0 !important;
    min-width: 16px;
  }

  &__body {
    margin-bottom: 20px;
  }

  &__title {
    margin: 0;
    margin-bottom: 8px;

    color: $neutral-black;

    font-family: 'DM Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  &__paragraph {
    margin: 0;

    color: $neutral-700;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 147%;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }
}
