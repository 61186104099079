.tax-section {
  .tax-section-row {
    width: 100%;

    .tax-section-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .section-name {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-family: 'DM Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
      }

      .no-inputs {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 12px;
        max-width: 250px;
        color: $neutral-500;
      }

      .taxes-list {
        width: 100%;
        max-width: 255px;
      }

      .tax,
      .total {
        width: 100%;
        max-width: 255px;
        display: flex;
        justify-content: space-between;

        .key,
        .value {
          font-size: 14px;
          line-height: 16px;
          color: $neutral-black;
        }

        .key {
          padding-right: 10px;
        }
      }

      .tax {
        &:not(:last-child) {
          margin-bottom: 6px;
        }

        &:hover {
          cursor: pointer;

          .key,
          .value {
            text-decoration: underline;
          }
        }
      }

      .total {
        margin-top: 16px;
        margin-bottom: 16px;

        .key,
        .value {
          font-weight: 500;
        }
      }

      .btn.custom-button {
        font-size: 14px;
        line-height: 16px;
        max-width: 255px;

        &.btn-link {
          padding: 0;
          height: 16px;
          font-weight: 400;
        }

        &.btn-dashed-link {
          svg {
            width: 16px;
            height: 16px;
          }
        }

        svg {
          margin-right: 4px;
        }

        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
