.share-calculation {
  padding-top: 0;
  margin-bottom: 24px;

  .share-calculation-row {
    padding-top: 24px;
    padding-bottom: 0;
    border-top: 1px solid $neutral-200;

    .share-calculation-form {
      .form-row {
        @include media-breakpoint-down(xs) {
          flex-direction: column;

          .form-group {
            margin-bottom: 16px !important;
          }
        }

        .custom-input {
          flex: 1;
        }

        .btn {
          margin-left: 16px;

          @include media-breakpoint-down(xs) {
            margin-left: 0;
          }
        }
      }

      .description {
        padding-top: 16px;
      }

      .shared-with {
        .title {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }

        .close-btn {
          :hover {
            cursor: pointer;
          }
        }

        .shared-with-header {
          border-bottom: 1px solid $neutral-200;

          small {
            display: inline-block;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 6px;
          }
        }

        .shared-with-list {
          display: flex;
          flex-wrap: wrap;

          .shared-email {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            margin-right: 8px;
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid $neutral-200;

            .email {
              margin-bottom: 0;
              font-size: 14px;
              line-height: 16px;
              color: $neutral-black;
            }

            .remove-email {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $neutral-200;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              margin-left: 8px;

              svg path {
                transition: stroke 0.15s ease-in-out;
                stroke: $neutral-500;
              }

              &:hover {
                svg path {
                  stroke: $neutral-black;
                }
              }
            }
          }
        }

        .shared-with-row {
          display: flex;
          align-items: center;

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            padding: 5px 0;
            color: $neutral-600 !important;
          }
        }

        .not-shared {
          font-size: 14px;
        }
      }
    }
  }
}
