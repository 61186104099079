.client-header {
  padding-bottom: 25px;

  .user-info {
    @include media-breakpoint-down(xs) {
      flex-wrap: nowrap;
    }

    @media (max-width: 500px) {
      p.h2 {
        font-size: 1.5rem;
      }
    }
  }

  .clients-count {
    margin-bottom: 0;
    flex: 1 1 auto !important;
    text-align: right;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    .badge {
      padding: 0;
    }
  }

  .profile-progress {
    margin-bottom: 0;
    flex: 1 1 auto !important;
    text-align: right;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    @include media-breakpoint-up(md) {
      text-align: right;
    }

    .badge {
      padding: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .header-title-description {
    display: block;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.sm-link {
  a {
    color: $white;

    &:hover {
      color: white;
    }
  }
}
