.budget-section-form {
  .section-name-column {
    padding-top: 10px;
  }

  .section-columns {
    &.total {
      background-color: transparentize($link-800, 0.97);

      .section-column {
        &:first-child {
          padding-right: 18px;

          .total-row {
            padding-left: 16px;
          }
        }

        &:last-child {
          padding-left: 18px;
        }
      }
    }

    &.budget-columns {
      gap: 36px;
    }

    .section-column {
      background-color: transparent !important;

      .sub-section {
        padding: 12px 0 !important;
        display: flex;
        justify-content: flex-end;
      }

      &:first-child:not(.budget-form) {
        .sub-section {
          padding-right: 18px !important;
        }
      }

      &.budget-form {
        width: 322px !important;

        &:last-child {
          background-color: transparent !important;
        }

        &:first-child {
          .sub-section {
            padding-left: 16px !important;
          }
        }

        .sub-section {
          padding-top: 0 !important;
          padding-right: 0 !important;
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &:first-child::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 87px;
            height: 100%;
            z-index: 0;
            background-color: transparentize($link-800, 0.97);
          }

          .budget-header {
            display: flex;
            justify-content: space-between;

            .header-title {
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
              padding-top: 12px;
              padding-bottom: 16px;
            }

            .header-columns {
              display: flex;

              .yearly,
              .monthly {
                width: 87px;
                text-align: right;
                padding: 12px 12px 16px;

                font-size: 14px;
                line-height: 16px;
                color: $neutral-500;
              }
            }
          }

          .budget-categories {
            width: 100%;

            .budget-category {
              .budget-category-title {
                width: 100%;
                display: flex;
                justify-content: space-between;

                font-size: 14px;
                font-weight: 500;
                line-height: 16px;

                span {
                  padding-bottom: 8px;
                }

                & > div {
                  width: 87px;
                  padding-bottom: 8px;
                }
              }

              .category-items {
                .category-item {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 4px;

                  &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                  }

                  &:last-child {
                    margin-bottom: 16px;
                  }

                  .item-name {
                    font-size: 14px;
                    line-height: 16px;
                    color: $neutral-500;
                  }

                  .item-values {
                    display: flex;
                    align-items: center;

                    .yearly,
                    .monthly {
                      position: relative;
                      z-index: 1;
                      width: 87px;
                      text-align: right;
                      padding: 0 12px;
                      font-size: 14px;
                      line-height: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .total-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .total-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }

        .total-columns {
          display: flex;
          align-items: center;

          .yearly,
          .monthly {
            width: 87px;
            text-align: right;
            padding: 0 12px;

            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
          }

          .monthly {
            padding: 4px 12px;
            background-color: transparentize($link-800, 0.97);
          }
        }
      }
    }
  }
}
