.wopi-page {
  height: 100vh;

  display: flex;
  flex-direction: column;

  padding-bottom: 0;
}

.wopi-header {
  .custom-breadcrumb {
    margin: 0;

    .breadcrumb-item a {
      font-size: 14px;
    }
  }
}

.wopi-viewer {
  flex-grow: 1;

  position: relative;

  width: 100%;
  height: 100%;

  overflow: auto;
}

#office_frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border: none;
  display: block;
}
