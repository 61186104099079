.disable-mfa-page {
  width: 100%;
  height: auto;
  margin: 60px auto;
  padding: 0 40px;

  .disable-mfa-page-content {
    width: 600px;
    display: flex;
    height: auto;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    margin: 60px auto;
    background-color: $neutral;
    border-radius: 5px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      padding: 16px;
    }
    
    h1 {
      font-size: 36px;
      
      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }
  
    p {
      font-size: 18px;

      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }
  
    .disable-mfa-buttons {
      display: flex;
      gap: 20px;

      @media screen and (max-width: 400px) {
        flex-direction: column-reverse;
      }
    }
  }
}