.modal .modal-dialog.create-document-counsel.modal-md {
  max-width: 540px;
}

.modal .modal-dialog.create-document.modal-md {
  @include media-breakpoint-up(sm) {
    max-width: 900px;
  }

  .modal-header {
    @include media-breakpoint-down(xs) {
      padding: 16px 8.5px 0;
    }

    .modal-header-title-row .modal-title .custom-modal-title {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 2px;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: flex-start;
      }

      .jurisdiction-toggle {
        color: $link-500;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        margin-bottom: 0.5px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.with-close {
      .modal-close {
        @include media-breakpoint-down(xs) {
          top: 24px;
          right: 16px;
        }
      }
    }
  }

  .modal-body {
    @include media-breakpoint-down(xs) {
      padding: 24px 10px;
    }

    &.no-footer {
      @include media-breakpoint-down(xs) {
        padding-bottom: 16px;
      }
    }

    .create-document-form {
      .row {
        .col-md-6:last-child {
          padding-left: 8px;
        }

        .col-md-6:first-child {
          padding-right: 8px;
        }
      }

      .form-group {
        line-height: 16px;

        .form-control-label {
          line-height: 16px;

          @include media-breakpoint-down(xs) {
            line-height: 14px;
            font-size: 12px !important;
          }
        }

        .custom-btn-group {
          .btn.custom-button.btn-primary {
            @include media-breakpoint-down(xs) {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .input-group .form-control {
          height: 40px;

          @include media-breakpoint-down(xs) {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            padding-left: 12px;
          }

          &::placeholder {
            @include media-breakpoint-down(xs) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        .custom-select__control .custom-select__value-container {
          @include media-breakpoint-down(xs) {
            padding-left: 12px;
          }

          .custom-select__placeholder {
            white-space: nowrap;
          }

          .custom-select__single-value,
          .custom-select__placeholder {
            @include media-breakpoint-down(xs) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      .templates-selector {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        min-height: 304px;
        border: 1px solid $neutral-300;
        border-radius: 4px;

        @include media-breakpoint-down(xs) {
          min-height: 200px;
        }

        &.loading,
        &.empty {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .no-templates {
          max-width: 292px;

          font-family: 'DM Sans', sans-serif;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $neutral-black;

          @include media-breakpoint-down(xs) {
            font-size: 16px;
            line-height: 21px;
          }
        }

        .templates-selector-list {
          max-height: 300px;
          overflow-y: auto;

          .template {
            display: flex;
            justify-content: space-between;
            width: 100%;
            transition: background-color 0.1s ease-in-out;
            cursor: pointer;
            border-bottom: 1px solid $neutral-300;

            &.active {
              background-color: $primary-400;

              .template-name,
              .template-number {
                color: $white;
              }
            }

            @include media-breakpoint-down(xs) {
              font-size: 14px;
              line-height: 16px;
            }

            .template-name,
            .template-number {
              padding: 9.5px 15px;
              padding-right: 5px;

              font-family: 'Roboto', sans-serif;
              font-size: 16px;
              color: $neutral-black;
              line-height: 19px;
            }

            .template-name {
              width: 100%;
              max-width: calc(100% - 100px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-right: 1px solid $neutral-300;
            }

            .template-number {
              width: 100px;
              min-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .templates-selector-footer {
          border-top: 1px solid $neutral-300;
          padding: 9.5px 15px;
          background-color: $neutral-100;
          border-radius: 0 0 4px 4px;

          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          color: $neutral-700;
        }
      }

      .document-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-down(xs) {
          margin: 24px 0 0;
        }

        .document-description-content {
          display: flex;
          flex-direction: column;

          .document-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: $neutral-black;
            margin-bottom: 6px;
          }

          .document-subtitle {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 2px;
          }

          .change-settings {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            color: $link-500;
            cursor: pointer;
            margin-top: 6px;

            &:hover {
              text-decoration: underline;
            }
          }

          .manage-sections {
            margin-top: 8px;
            margin-bottom: -2px;
            
            .custom-control-label {
              line-height: 17px;
            }
          }

          .divider {
            border-bottom: 1px solid $neutral-300;
            margin: 16px 0 12px;
          }

          .description {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-bottom: 4px;
          }

          .description-content {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 16px;
          }

          .revision-date {
            font-family: 'Roboto', sans-serif;
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 16px;

            .date-value {
              font-weight: 700;
              margin-left: 4px;
            }
          }

          .document-link {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            color: $link-500;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            &.help-link {
              margin-top: 12px;
            }
          }
        }

        .buttons {
          display: flex;

          @include media-breakpoint-down(xs) {
            margin-top: 63px;
          }

          .btn {
            &:last-child {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
