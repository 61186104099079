.document-section-header {
  margin: 24px 0;

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    width: 100%;
    padding: 12px 16px;
    background: $neutral-100;
    border-radius: 4px;

    &__title {
      width: 55%;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $neutral-black;
    }

    &__helper {
      width: 45%;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $neutral-600;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
    }
  }
}

/* Media */
@media screen and (max-width: 450px) {
  .section-header {
    &__title,
    &__helper {
      width: unset;
    }
  }
}
