.tab-navigation-container {
  position: relative;

  &::before {
    content: '';
    border-bottom: 1px solid $neutral-200;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 0;
  }

  .tab-navigation {
    overflow-x: auto;
    display: flex;
    width: 100%;
    padding: 0 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab {
      padding: 14px 0 10px;
      margin: 0 16px;
      border-bottom: 1px solid transparent;
      transition: border 0.15s ease-in-out;
      cursor: pointer;

      @include media-breakpoint-down(xs) {
        margin-left: 0;

        &:last-child {
          margin-right: 0;
        }
      }

      span {
        white-space: nowrap;
        display: flex;
        align-items: center;

        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $neutral-500;
        transition: color 0.15s ease-in-out;

        @include media-breakpoint-down(sm) {
          font-size: 12px;
          line-height: 14px;
        }

        span.d-sm-inline {
          margin-right: 5px;
        }

        span.count-badge {
          display: block;
          padding: 2px 6px;
          margin-left: 8px;
          border-radius: 10px;
          background-color: $neutral-200;

          font-size: 12px;
          line-height: 14px;

          @include media-breakpoint-down(xs) {
            line-height: 12px;
          }
        }
      }

      &:hover:not(.disabled) {
        span {
          color: $primary-500;

          span.count-badge {
            background-color: $primary-100;
          }
        }
      }

      &.active:not(.disabled) {
        position: relative;
        border-bottom-color: $primary-500;

        span {
          color: $primary-500;

          span.count-badge {
            background-color: $primary-100;
          }
        }
      }

      &.disabled {
        cursor: auto;

        span {
          color: $neutral-300;

          span.count-badge {
            background-color: $neutral-100;
          }
        }
      }
    }
  }
}
