@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('DM Sans'), url('/fonts/DM_Sans/DMSans-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: local('DM Sans'), url('/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* dm-sans-700 - latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: local('DM Sans'), url('/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('/fonts/Nunito/nunito-v12-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
