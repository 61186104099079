.select-client-modal {
  &.modal-md {
    max-width: 683px !important;
  }

  .modal-body {
    h5 {
      margin: 40px 0;
      text-align: center;
    }
  }

  .modal-footer {
    padding: 16px 36px 24px !important;
  }

  .infinite-scroll-container {
    padding: 2px 4px;
    max-height: 400px !important;
    overflow: auto !important;
  }

  /* ===============
    Reset Margins to fit in modal
    ============== */
  .clients-search-input,
  .no-result-clients-list {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  /* ===============
    Client List
    ============== */
  .infinite-scroll-container .clients-list > .list-group-item {
    margin-left: 0;
    margin-right: 0;
  }

  .infinite-scroll-container .clients-list .client-item {
    cursor: pointer;
    padding: 16px 24px;
  }
}
