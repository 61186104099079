.current-plan {
  background-color: $white;
  border-radius: 4px;
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 30px;
  }

  &__header {
    cursor: pointer;

    padding: 16px 24px;

    @include media-breakpoint-down(sm) {
      padding: 16px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__hint {
    margin-top: 16px;

    p {
      max-width: unset !important;
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $neutral-600;
      max-width: 379px;
    }
  }

  &__info {
    width: 100%;

    display: flex;
    align-items: center;
    gap: 40px;

    &-item {
      @include media-breakpoint-down(sm) {
        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
      }

      @include media-breakpoint-down(xs) {
        &:nth-child(5) {
          display: none;
        }
      }

      h3 {
        margin: 0;
        margin-bottom: 4px;

        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.06px;
        color: $neutral-600;
      }

      h4 {
        margin: 0;

        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.75px;
        color: $neutral-black;
      }

      button {
        font-family: Roboto, sans-serif;
        height: unset !important;
        padding: 0 !important;
      }
    }
  }

  &__collapse {
    transition: all 0.2s ease;

    &-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;

      border-top: 1px dashed $neutral-300;
      padding: 24px 16px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        gap: 8px;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $neutral-600;
      max-width: 379px;

      a {
        font-size: 14px;
        line-height: 18px;
        color: $link-500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &.privacy-link {
          margin-top: 24px;
          display: block;

          @include media-breakpoint-down(sm) {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
