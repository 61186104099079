header {
  background: linear-gradient(rgba(33, 40, 47, 0.6), rgba(33, 40, 47, 0.6)),
    url(./../../../../img/backgrounds/bg-park.webp);
  background-repeat: no-repeat;
  background-size: cover;

  .header-top {
    background: linear-gradient(rgba(33, 40, 47, 0.4), rgba(33, 40, 47, 0.4));

    a {
      display: block;
      color: white;

      font-size: 14px;
      line-height: 18px;

      @include media-breakpoint-down(xs) {
        font-size: 12px;
        line-height: 14px;
      }

      > img {
        vertical-align: unset;
      }
    }
  }

  .logo-column {
    a {
      line-height: 33px;

      @include media-breakpoint-up(sm) {
        line-height: 30px;
      }
    }
  }

  .public-header-auth-btns {
    a[type='button'] {
      width: 115px;
    }
  }

  .dropdown {
    button.dropdown-toggle.btn.btn-secondary {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      padding-right: 0;

      span {
        font-size: 16px;
        font-weight: 600;
        font-family: 'DM Sans';
        line-height: 20px;

        @include media-breakpoint-down(xs) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &:after {
        vertical-align: middle;
      }
    }
  }

  .header-account-page {
    .dropdown-toggle::after {
      vertical-align: 0.12em;
    }

    .btn-secondary {
      background-color: $white;
    }
  }
}
