.modal .modal-dialog.tax-amount-modal {
  max-width: 420px;

  .modal-content {
    position: relative;

    .modal-body {
      position: initial;

      .income-form {
        .form-text:not(small) {
          margin-top: 16px;
          color: $neutral-black;
          font-size: 16px;
          line-height: 21px;
        }

        .delete-btn {
          position: absolute;
          bottom: 33px;
          right: 33px;
          padding: 0;
          height: 24px;
        }

        .title,
        .description {
          font-size: 16px;
          line-height: 21px;
          color: $neutral-black;
        }

        .children-toggle-list {
          .child-toggle-container {
            border-bottom: none !important;
            margin-bottom: 16px !important;

            .child-toggle {
              border-radius: 4px;
              border: 1px solid $neutral-200;
              margin-top: 0 !important;
              padding-right: 0;

              .form-group {
                display: flex;
                padding: 8px;
                align-items: center;
                margin-bottom: 0;

                .child-avatar {
                  display: inline-block !important;
                  margin-right: 12px;
                  width: 40px;
                  height: 40px;
                  min-width: 40px;
                }

                .custom-switch {
                  .custom-control-label {
                    text-align: left !important;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    color: $neutral-black;
                    user-select: none;

                    &::before,
                    &::after {
                      content: none;
                    }

                    small {
                      margin-top: 2px;
                      font-size: 12px;
                      line-height: 14px;
                      color: $neutral-500;
                    }
                  }
                }
              }

              &.selected {
                border-color: $primary-500;
                .form-group {
                  .custom-switch {
                    .custom-control-label {
                      color: $primary-500;
                    }
                  }
                }
              }
            }

            hr {
              display: none;
            }

            & + .description {
              padding-top: 0 !important;
              color: $neutral-500;
              font-size: 14px;
              line-height: 18px;
            }
          }

          .btn {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            padding: 8px 12px;

            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
