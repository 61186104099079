.modal .modal-dialog.client-document-sections-form.modal-md {
  @include media-breakpoint-up(sm) {
    max-width: 900px;
  }

  .modal-body {
    .sections-modal-content {
      &.right {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .section-description-content {
          .section-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: $neutral-black;
            margin-bottom: 6px;
          }

          .section-description {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 2px;
          }
        }

        .sections-modal-footer {
          display: flex;
          gap: 8px;
        }
      }
    }
  }
}

.tree-wrapper {
  max-height: calc(100vh - calc(100vh * 0.4));
  overflow: auto;

  .tree-node {
    line-height: 16px;

    &.parent-node {
      & > .tree-node-title {
        max-height: 48px;
        height: 48px;
        padding: 12px 16px;
        background-color: $neutral-100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;

        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        svg {
          width: 24px;
          height: 24px;
          color: $neutral-600;
          margin-right: 0;
        }
      }
    }

    &:not(.tree-node-collapse) {
      &:hover {
        background-color: transparentize($primary, 0.9);
      }
    }

    &.tree-node-collapse:not(.parent-node) {
      padding: 0 !important;

      & > .tree-node-title {
        padding: 5px 0 5px 5px;
        border-radius: 4px;
        max-height: 26px;

        &:hover {
          background-color: transparentize($primary, 0.9);
        }
      }

      .collapse-content {
        padding: 0;
      }
    }

    .collapse-content {
      padding: 5px 0;

      .tree-node {
        border-radius: 4px;
        padding: 5px 0 5px 5px;
      }
    }

    &.highlighted:not(.parent-node):not(.tree-node-collapse) {
      background-color: transparentize($primary, 0.9);
    }

    &.highlighted.tree-node-collapse:not(.parent-node) {
      & > .tree-node-title {
        background-color: transparentize($primary, 0.9);
      }
    }

    .tree-node-title {
      // max-height: 16px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: $neutral-700;
      cursor: pointer;
      user-select: none;

      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        color: $neutral-700;
        margin-left: 5px;
      }

      .custom-checkbox {
        line-height: 16px;
        min-height: initial;
        margin-bottom: 0;

        .custom-control-input {
          z-index: 1;
          cursor: pointer;
        }

        .custom-control-label {
          cursor: pointer;
          padding-left: 6px;

          span {
            padding-left: 0;
          }
        }
      }
    }

    .node-leaf {
      display: flex;
      align-items: center;

      .custom-checkbox {
        margin-right: 4px;
      }
    }
  }
}