@import 'clients';
@import 'search-input';
@import 'sidebar';
@import 'calculations-card';
@import 'biography';
@import 'interview';
@import 'new-client-form';
@import 'create-interview-modal';
@import 'merge-conflict-field';
@import 'create-document-modal';
@import 'client-document-settings-modal';
@import 'client-document-sections-modal';
@import 'select-calculation-modal';

.home-page-content {
  background-color: $secondary;
  padding-bottom: 60px;

  & > .container {
    padding-left: 0;
    padding-right: 0;
  }

  .card-header,
  .card-footer {
    background-color: transparent;
  }

  .card-header {
    border-bottom-color: $border-color;
  }

  .card-footer {
    border-top-color: $border-color;
  }

  .btn.custom-button.btn-md.create-client-btn {
    height: 44px;
  }

  .card-body {
    .list-group-item {
      &:first-of-type {
        border-top: 0;
      }

      .custom-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
