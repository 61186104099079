.clio-integration-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;

  &.not-authorized {
    padding-top: 0;
    border-top: 1px solid $neutral-200;

    .update-form.settings-form {
      border-top: none;
      margin-top: 0;
    }
  }

  .integration-description {
    margin-top: -16px;
    margin-bottom: 16px;
  }

  .update-form {
    width: 100%;
    padding-bottom: 0;
  
    .inputs-container {
      display: flex;
      justify-content: space-between;
  
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
  
      .left-side {
        width: 100%;
  
        @include media-breakpoint-down(sm) {
          margin-bottom: 16px;
        }
  
        .title {
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          margin-bottom: 4px;
        }
  
        .subtitle {
          color: $neutral-500;
          font-size: 12px;
          line-height: 16px;
          max-width: 269px;
          margin-bottom: 0;
  
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
  
        a {
          color: $link-500;
          font-size: 12px;
          line-height: 16px;
        }
      }
  
      .right-side {
        min-width: 432px;
  
        @include media-breakpoint-down(lg) {
          min-width: 350px;
        }
  
        @include media-breakpoint-down(sm) {
          width: 100%;
          min-width: initial;
        }
  
        .clio-toggles {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 14px;

          .toggle-checkbox {
            align-items: flex-start;
          }
        }
        
        .save-btn-container {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          margin-top: 10px;

          .btn {
            padding-left: 30px;
            padding-right: 30px;
          }
        }

        .login-btn-container {
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      }
    }

    &.settings-form {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid $neutral-200;
    }

    &.disable-integration-form {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid $neutral-200;

      .right-side {
        display: flex;
        gap: 16px;

        .disable-btn-container, .suspend-btn-container {
          line-height: 16px;
  
          .btn.custom-button.btn-md {
            width: 100%;
            padding: 9px 10px 10px;
          }
  
          .btn-description {
            margin-top: 4px;
            color: $neutral-500;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
          }
        }
      }
    }

    &.request-migration {
      .right-side {
        max-width: 432px;
      }
    }
  }
}