.confirm-account-card {
  max-width: 538px;
  position: relative;
  z-index: 3;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem;

  @include media-breakpoint-down(md) {
    padding: 3rem 1.25rem;
  }

  .card-body {
    padding: 0;

    .client-bio {
      padding-left: 20px;
      max-width: 380px;
      margin: auto;

      .row {
        flex-wrap: nowrap;

        .col-sm-3 {
          min-width: 120px;
        }

        .col-sm-9 {
          max-width: 300px;
        }
      }
    }

    .buttons {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(xs) {
        flex-direction: column-reverse;
      }

      .custom-button {
        white-space: nowrap;

        @include media-breakpoint-down(xs) {
          &:first-child {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
