// Budget Summary Calculation Styles
// Adapted from _nfp-calculation-section.scss

.budget-summary-calculation {
  margin-top: 2rem;
  padding: 0;
  
  h5 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #212529;
    padding-bottom: 0.5rem;
  }
  
  // New simplified header structure
  .budget-headers-container {
    background-color: #f8f9fa;
    overflow: hidden;
    margin-bottom: 0;
    
    // Main headers (Category, Client's Budget, Ex's Budget)
    .main-headers {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dee2e6;
      
      .category-header {
        flex: 0 0 33.333%;
        width: 33.333%;
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        color: #495057;
        padding: 0 0.5rem;
        border-right: 1px solid #dee2e6;
      }
      
      .budget-header {
        flex: 0 0 33.333%;
        width: 33.333%;
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        color: #495057;
        padding: 0.75rem 0.5rem;
        border-right: 1px solid #dee2e6;
        
        &:last-child {
          border-right: none;
        }
      }
    }
    
    // Sub headers (Yearly, Monthly for each budget)
    .sub-headers {
      display: flex;
      align-items: center;
      
      .category-spacer {
        flex: 0 0 33.333%;
        width: 33.333%;
        border-right: 1px solid #dee2e6;
      }
      
      .period-header {
        flex: 0 0 16.666%;
        width: 16.666%;
        text-align: center;
        font-weight: 500;
        font-size: 0.9rem;
        color: #6c757d;
        padding: 0.5rem 0.25rem;
        border-right: 1px solid #dee2e6;
        background-color: rgba(248, 249, 250, 0.7);
      }
      
      // Enhanced background colors for yearly vs. monthly columns in sub-headers
      // Yearly columns (Sue and Brian)
      .period-header:nth-child(2),
      .period-header:nth-child(4) {
        background-color: rgba(236, 242, 252, 0.7); // Light blue for yearly columns
      }
      
      // Monthly columns (Sue and Brian)
      .period-header:nth-child(3),
      .period-header:nth-child(5) {
        background-color: rgba(248, 249, 250, 0.7); // Light gray for monthly columns
      }
      
      .period-header:last-child {
        border-right: none;
      }
    }
  }
  
  // Fix alignment with data rows
  .nfp-row {
    padding: 0;
    margin: 0;
    
    &:nth-child(odd) {
      background-color: rgba(248, 249, 250, 0.7);
    }
    
    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.8);
    }
    
    &:hover {
      background-color: rgba(236, 242, 252, 0.5);
    }
    
    &:last-child {
      border-bottom: 1px solid #dee2e6;
      border-radius: 0 0 6px 6px;
    }
    
    // Ensure columns align with headers and remove vertical padding
    .row {
      margin: 0 !important;
      width: 100% !important;
      padding: 0 !important; // Remove any padding on the row
      
      // Remove vertical margin/padding that causes gaps
      &.align-items-center {
        padding: 0 !important;
        margin: 0 !important;
      }
      
      [class*="col-"] {
        padding-top: 0 !important; // Remove vertical padding
        padding-bottom: 0 !important; // Remove vertical padding
      }
      
      .col-4 {
        flex: 0 0 33.333% !important;
        max-width: 33.333% !important;
        padding-left: 1rem !important;
      }
      
      // Add alternating background colors for yearly vs monthly columns in data cells
      // Yearly columns (Sue and Brian)
      .col-2:nth-child(2),
      .col-2:nth-child(4) {
        flex: 0 0 16.666% !important;
        max-width: 16.666% !important;
        text-align: right !important;
        padding-right: 1rem !important;
        background-color: rgba(236, 242, 252, 0.6); // Light blue for yearly columns
      }
      
      // Monthly columns (Sue and Brian)
      .col-2:nth-child(3),
      .col-2:nth-child(5) {
        flex: 0 0 16.666% !important;
        max-width: 16.666% !important;
        text-align: right !important;
        padding-right: 1rem !important;
        background-color: rgba(248, 249, 250, 0.6); // Light gray for monthly columns
      }
    }
  }
  
  .subtotal-row, .grand-total-row {
    padding: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    
    small.text-muted {
      font-size: 0.8rem;
      opacity: 0.9;
      font-weight: normal;
      color: #495057;
    }
    
    .formula-explanation {
      font-size: 0.75rem;
      opacity: 0.9;
      margin-top: 2px;
      font-style: italic;
      text-align: right;
      line-height: 1.2;
      font-weight: normal;
      color: #495057;
    }
    
    // Add border only to the top of subtotal rows
    &.with-top-border {
      border-top: 1px solid #dee2e6;
      padding-top: 0.4rem;
      margin-top: 0.4rem;
    }
  }
  
  // Grand total row should still stand out
  .grand-total-row {
    background-color: #f8f9fa;
    padding: 0;
    border-top: 2px solid #dee2e6;
    position: relative;
    z-index: 3;
    
    strong {
      font-size: 1.1rem;
      font-weight: 700;
      color: #212529;
    }
    
    .grand-total-value {
      font-weight: 700;
      font-size: 1.1rem;
      color: #007bff;
    }
    
    &:hover {
      background-color: rgba(236, 242, 252, 0.8);
    }
    
    // Ensure columns align with headers
    .row {
      margin: 0 !important;
      width: 100% !important;
      
      // Remove vertical margin/padding for grand total row
      &.align-items-center {
        padding: 0 !important;
        margin: 0 !important;
      }
      
      // Add back some vertical padding for the grand total row
      [class*="col-"] {
        padding-top: 0.6rem !important; // Add back vertical padding
        padding-bottom: 0.6rem !important; // Add back vertical padding
      }
      
      .col-4 {
        flex: 0 0 33.333% !important;
        max-width: 33.333% !important;
        padding-right: 1rem !important;
      }
      
      // Add alternating background colors for yearly vs monthly columns in grand total row
      // Yearly columns (Sue and Brian)
      .col-2:nth-child(2),
      .col-2:nth-child(4) {
        flex: 0 0 16.666% !important;
        max-width: 16.666% !important;
        text-align: right !important;
        padding-right: 1rem !important;
        background-color: rgba(236, 242, 252, 0.6); // Light blue for yearly columns
      }
      
      // Monthly columns (Sue and Brian)
      .col-2:nth-child(3),
      .col-2:nth-child(5) {
        flex: 0 0 16.666% !important;
        max-width: 16.666% !important;
        text-align: right !important;
        padding-right: 1rem !important;
        background-color: rgba(248, 249, 250, 0.6); // Light gray for monthly columns
      }
    }
  }

  // Special styling for equalization payment row - keep emphasis
  .equalization-payment-row {
    background-color: #f0f4fa;
    border: 2px solid #007bff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    margin-top: 1rem;
    
    // Remove vertical padding for continuous shading
    .row.align-items-center {
      padding: 0 !important;
      margin: 0 !important;
    }
    
    // Add back vertical padding for the equalization row
    [class*="col-"] {
      padding-top: 0.7rem !important;
      padding-bottom: 0.7rem !important;
    }
    
    .equalization-statement {
      font-size: 1.05rem;
      color: #212529;
      font-weight: 600;
      text-align: right;
      
      .amount {
        color: #007bff;
        font-size: 1.1rem;
        padding: 0 0.3rem;
        font-weight: 700;
      }
    }
    
    strong {
      font-size: 1.1rem;
      font-weight: 700;
    }
    
    // Use consistent formula explanation styling
    .formula-explanation {
      font-size: 0.75rem;
      opacity: 0.9;
      margin-top: 2px;
      font-style: italic;
      text-align: right;
      line-height: 1.2;
      font-weight: normal;
      color: #495057;
    }
    
    // Add background color variation to differentiate columns
    // Payment column styling with background color variation
    .col-4:nth-child(2) {
      background-color: rgba(236, 242, 252, 0.7); // Match yearly column styling
      border-radius: 4px;
      padding: 0.7rem;
    }
    
    // Second payment column styling
    .col-4:nth-child(3) {
      background-color: rgba(248, 249, 250, 0.7); // Match monthly column styling
      border-radius: 4px;
      padding: 0.7rem;
    }
    
    &:hover {
      background-color: #e6f0ff;
    }
  }
  
  // Mobile responsive styles
  @media (max-width: 767px) {
    .budget-headers-container {
      .main-headers {
        .category-header,
        .budget-header {
          font-size: 0.9rem;
          padding: 0.5rem 0.25rem;
        }
      }
      
      .sub-headers {
        .period-header {
          font-size: 0.8rem;
          padding: 0.4rem 0.15rem;
        }
      }
    }
    
    .nfp-row {
      [class*="col-"] {
        padding: 0.2rem !important;
      }
    }
    
    .equalization-payment-row {
      .col-4 {
        width: 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding: 0.7rem !important;
        
        &:not(:first-child) {
          border-top: 1px solid rgba(0, 123, 255, 0.2);
        }
      }
      
      .equalization-statement {
        text-align: center !important;
      }
    }
  }
} 