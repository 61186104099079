.section-columns-container {
  .section-columns {
    &.years-columns {
      .section-column {
        background-color: transparent !important;
        margin-bottom: 12px;
      }
    }

    .section-column {
      width: 340px;

      &.title {
        width: unset !important;
        min-width: 340px;
      }

      &.half {
        min-width: unset;
        width: calc(340px / 2) !important;
      }

      .sub-section {
        &.title {
          background-color: transparentize($link-800, 0.97);
          padding: 4px 0 4px 16px !important;
        }

        .party-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 0;
        }

        .placeholder {
          margin-top: -2px;
          font-weight: 500;
        }

        &.name-section {
          .field {
            &:first-of-type {
              margin-top: 12px;
            }

            &:nth-child(3) {
              margin-top: 6px;
            }
          }
        }

        .incomes-list {
          .income {
            &:not(:last-child) {
              margin-bottom: 32px;
            }

            .field:hover {
              cursor: pointer;
              text-decoration: underline;
            }

            .income-name {
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              margin-bottom: 12px;
            }

            .income-value {
              line-height: 16px;
              margin-bottom: 0;

              &:not(:last-child) {
                margin-bottom: 4px;
              }

              .name,
              .value {
                font-size: 14px;
                line-height: 16px;
                color: $neutral-500;
              }

              .value {
                font-weight: 500;
                color: $neutral-black;
              }
            }
          }
        }
      }

      &.total-incomes-list,
      &.total-expenses-list {
        .field {
          padding: 4px 16px;

          &:nth-child(odd) {
            background-color: transparentize($link-800, 0.97);
          }
        }
      }
    }
  }
}
