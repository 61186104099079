.income-earners-section-form {
  .section-name-column {
    padding-top: 12px;
  }

  .income-earners-form {
    .sub-section {
      &:first-child {
        padding-top: 12px !important;
        padding-bottom: 8px !important;

        .income-earners-header {
          line-height: 16px;

          .header-title {
            color: $neutral-black;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
          }
        }

        .income-earners-fields {
          .field {
            &:not(:last-child) {
              margin-bottom: 4px;
            }

            &:first-child {
              margin-top: 12px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.modal {
  .modal-dialog.income-earners-modal {
    max-width: 688px;

    .income-earners-items-form {
      .form-column {
        & > .invalid-feedback.show {
          padding-top: 4px;
          visibility: hidden;
        }
      }
    }
  }
}
