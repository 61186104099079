.update-form-container {
  &.intake {
    .intake-header-container {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $neutral-200;

      @include media-breakpoint-down(sm) {
        padding: 12px;
      }

      .title {
        margin-bottom: 0;
      }

      .toggle-checkbox {
        margin: 0 0 0 20px;
      }
    }

    .update-form.settings-form {
      .toggle-checkbox {
        display: flex;
        align-items: flex-start;

        .toggle-description {
          line-height: 16px;

          .title {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            color: $neutral-black;
          }

          .description {
            display: inline-block;
            margin-top: 4px;
            color: $neutral-500;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
            max-width: 350px;
          }
        }
      }
    }

    .logo-form-container {
      .right-side {
        .logo {
          padding: 0;

          .logo-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            &.empty {
              align-items: center;

              .buttons {
                justify-content: center;
                width: 100%;
                height: 100%;
                padding-top: 0;

                .dropzone-container {
                  width: 100% !important;

                  .text-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .org-logo-preloader {
                      font-size: 30px;
                    }

                    button {
                      width: 100% !important;
                      height: 100%;
                    }
                  }
                }
              }
            }

            .logo-container {
              padding: 10px 25px;
              box-shadow: $shadow-sm;
              border-radius: 4px;
              overflow: hidden;
            }

            .buttons {
              justify-content: flex-start;
              padding-top: 12px;
              gap: 15px;
            }
          }
        }
      }
    }

    .legal-issues-form-container {
      .select-input {
        .custom-select__menu {
          .custom-select__option {
            .custom-option-overlay {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .btn-container {
                display: flex;

                .btn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  color: $link-500;

                  &:first-child {
                    margin-right: 6px;
                  }
                }
              }
            }

            &--is-selected {
              .custom-option-overlay .btn-container .btn {
                color: $white;
              }
            }
          }
        }
      }
    }

    .color-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 15px;
    }

    .form-group {
      .input-container {
        position: relative;
        cursor: text;

        &::after {
          content: 'www.divorcepath.com/intake/';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 1.25rem;
          color: #d6d6d6 !important;
          z-index: 4;
          font-size: 14px;

          @include media-breakpoint-only(xs) {
            content: '';
          }
        }
      }

      &.custom-input-form-urn {
        display: flex;
        flex-direction: column;

        .form-control {
          &.custom-input-form-urn-control {
            position: relative;
            padding-left: 207px;

            @include media-breakpoint-only(xs) {
              padding-left: 20px;
            }
          }
        }

        & > .d-flex {
          align-items: center;

          .form-control-label {
            margin-bottom: 0 !important;
          }
        }

        .btn-copy {
          padding: 6px;
          margin-right: -6px;
          align-self: flex-end;
          background-color: transparent;
          border: 0;
          font-size: 12px;
          line-height: 14px;
          transition: color 0.2s ease;
          color: $link-500;

          &:focus {
            outline: none;
            color: $primary;
          }

          &:hover {
            color: $primary;
          }
        }

        .intake-description {
          margin-top: 4px;
        }

        &.invalid {
          .form-control {
            border-color: $error;
          }
        }
      }

      .form-control {
        font-size: 14px;
        line-height: 16px;

        &::placeholder {
          color: #d6d6d6;
        }
      }

      .form-control-label {
        margin-bottom: 0.2rem;
      }
    }

    .form-group.custom-input .input-group textarea {
      min-height: 88px;
      max-height: 200px;
      resize: vertical;
      padding: 8px 10px;

      @include media-breakpoint-only(xs) {
        min-height: 128px;
      }
    }

    input[name='intakeSubmittedTitle'],
    textarea {
      &::placeholder {
        font-size: 14px !important;
        color: $neutral-400 !important;
      }
    }
  }
}
