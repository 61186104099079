// Family Property Calculator Modal
@import 'nfp-calculation-section';
@import 'family-property-calculator-inputs';

.family-property-calculator-modal {
  &.fullscreen-modal {
    max-width: 95% !important;
    width: 95% !important;
    margin: 1rem auto;
    
    .modal-content {
      min-height: 90vh;
      max-height: 90vh;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 0.75rem 1.25rem;

      .modal-title {
        font-size: 1.25rem;
        font-weight: 600;
      }
      
      // Styles for the enhanced header with client info
      .modal-header-title-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        .modal-title {
          margin-bottom: 0.25rem;
        }
        
        .modal-file-info {
          font-size: 0.85rem;
          color: #6c757d;
          margin-top: 2px;
          
          .file-number, .matter-number {
            display: inline-block;
            margin-right: 1rem;
            font-weight: 500;
          }
        }
      }
    }

    .modal-body {
      overflow-y: auto;
      padding: 1rem 1.5rem;
      background-color: #fff;
    }
  }

  .family-property-calculator-form {
    // Fix for top header layout - ensure main headers stay on the same row
    .valuation-header-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      
      // Date select container - limit width and align right
      .date-select-container {
        flex: 0 0 auto;
        max-width: 250px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: auto;
        padding-right: 15px;
        
        .date-select {
          width: 250px !important;
          max-width: 250px !important;
        }
        
        // Fix for the w-100 class that might be applied to the form-group
        .form-group.w-100, 
        .custom-select-input.w-100 {
          width: 250px !important;
          max-width: 250px !important;
        }
      }
      
      // Container for section headers (Sue's Valuation, Brian's Valuation)
      .section-headers-container {
        width: 600px;
        display: flex;
        flex-direction: row;
        
        .section-header {
          width: 300px;
          text-align: center;
          padding: 0.75rem 0.5rem;
          font-weight: 600;
          color: #495057;
          font-size: 1rem;
          
          &:first-child {
            background-color: rgba(236, 237, 245, 0.8);
          }
          
          &:last-child {
            background-color: rgba(246, 246, 251, 0.9);
          }
        }
      }
    }
    
    // Improved header styling
    .possessions-table-header {
      padding: 0;  // Remove all padding to avoid breaks in shading
      border-radius: 6px 6px 0 0; // Rounded top only
      margin-bottom: 0; // Remove bottom margin
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      border-bottom: none;
      position: relative;
      overflow: hidden; // Prevent shadow from leaking
      
      .row-header {
        font-weight: 600;
      }

      .details-header {
        display: flex;
        align-items: center;
        height: 100%;
        padding-top: 0;
        font-size: 0.9rem;
        color: #495057;
      }
      
      .display-selection {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 10;
        width: 100%;
        justify-content: space-between;
        
        span {
          font-weight: 500;
          white-space: nowrap;
          margin-right: 0.5rem;
        }
        
        .font-weight-medium {
          font-weight: 500;
        }
        
        .date-select {
          width: 250px !important;
          max-width: 250px !important;
          font-size: 0.9rem;
          display: inline-block;
          
          &.w-100,
          .w-100 {
            width: 250px !important;
            max-width: 250px !important;
          }
        }
      }
      
      .info-text {
        display: inline-block;
        font-size: 0.9rem; // Increased from 0.8rem
        color: #6c757d;
        text-align: left;
        background-color: transparent;
        
        .edit-icon {
          font-size: 0.75rem;
          color: #6c757d;
          vertical-align: middle;
        }
      }
      
      // Improved valuation column headers
      .valuation-column-headers {
        margin: 0;
        position: relative;
        padding: 0; // Removed top padding completely
        
        // Use flexbox for proper alignment
        .row {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          margin: 0;
          position: relative;
          
          // Improve responsive behavior
          @media (max-width: 991px) {
            flex-wrap: wrap !important;
          }
        }
        
        // Define a fixed column layout with specific pixel sizes
        .col-4:first-child {
          width: calc(100% - (150px * 4)) !important; // Take remaining space after reserving for value columns
          flex: 0 0 calc(100% - (150px * 4)) !important;
          max-width: calc(100% - (150px * 4)) !important;
          position: relative;
          z-index: 1;
          padding-left: 0; // Remove padding to align with data
          padding-right: 0; // Remove padding to align with data
          
          // Add styles for date select container
          .date-select-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding-right: 15px;
            
            .date-select {
              width: 250px !important;
              max-width: 250px !important;
              
              &.w-100,
              .w-100 {
                width: 250px !important;
                max-width: 250px !important;
              }
            }
            
            // Fix for parent containers with w-100 class
            .form-group.w-100,
            .custom-select-input.w-100 {
              width: 250px !important;
              max-width: 250px !important;
            }
          }
        }
        
        // Value column headers - fixed pixel width
        .col-4:not(:first-child) {
          width: 300px !important; // Fixed width for valuation section headers
          flex: 0 0 300px !important;
          max-width: 300px !important;
          position: relative;
          z-index: 1;
          padding-left: 0; // Remove padding to align with data
          padding-right: 0; // Remove padding to align with data
          
          // Add column shading to match the columns below
          &:nth-child(2) {
            background-color: rgba(236, 237, 245, 0.8); // Match column backgrounds exactly
          }
          
          &:nth-child(3) {
            background-color: rgba(246, 246, 251, 0.9); // Match column backgrounds exactly
          }
        }
        
        .valuation-header {
          font-weight: 600;
          font-size: 1.1rem !important;
          margin-bottom: 0; // Remove margin to prevent gaps
          text-align: center;
          padding: 0.45rem 0; // Consistent padding all around 
          font-size: 0.9rem; // Increased from 0.9rem
          color: #495057;
          border-bottom: none;
          position: relative;
          z-index: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      
      // Improved styling for the subheader row
      .sub-header {
        font-size: 1rem;
        text-align: center;
        background-color: transparent;
        margin: 0; // Remove margins to ensure continuous shading
        padding: 0; // Remove all padding to avoid breaks in shading
        border-top: none;
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        
        // Fixed pixel-width value columns (name and value)
        .col-2 {
          text-align: center;
          font-weight: 500;
          color: #212529;
          width: 150px !important; // Fixed pixel width
          flex: 0 0 150px !important;
          max-width: 150px !important;
          position: relative;
          z-index: 1;
          padding: 0.45rem 0.25rem 0.65rem; // Increased padding for better spacing
          line-height: 1.2; // Ensure consistent height
        }
        
        // Main content column
        .col-4 {
          width: calc(100% - (150px * 4)) !important; // Take remaining space after reserving for value columns
          flex: 0 0 calc(100% - (150px * 4)) !important;
          max-width: calc(100% - (150px * 4)) !important;
          position: relative;
          z-index: 1;
          padding: 0.45rem 0.75rem 0.65rem; // Match top and bottom padding with col-2
        }
        
        .possession-details-header {
          font-size: 0.9rem;
          padding: 0; // Remove padding from inner element
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 100%; // Ensure full height
          display: flex;
          align-items: center;
          justify-content: flex-end; // Add this to properly align flex items to the right
        }
      }
      
      // Ensure consistent column widths and proper shading
      // Apply stronger, more consistent background colors - updated widths
      .col-2:nth-child(2), .col-2:nth-child(4) {
        background-color: rgba(236, 237, 245, 0.8); // Match exactly with other sections
        font-size: 0.95rem; // Consistent size for name labels
        font-weight: 500;
      }
      
      .col-2:nth-child(3), .col-2:nth-child(5) {
        background-color: rgba(246, 246, 251, 0.9); // Match exactly with other sections
        font-size: 0.95rem; // Consistent size for name labels
        font-weight: 500;
      }
      
      // Remove the separator between header and content
      &:after {
        display: none; // Remove the separator that might be creating visual breaks
      }
    }

    .possessions-list {
      margin-bottom: 1rem;
      position: relative;
      border-radius: 0 0 6px 6px;
      padding: 0; // Remove all padding
      background-color: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      margin-top: 0; // Ensure no gap between header and list
      
      // Ensure no horizontal scrolling
      overflow-x: hidden;
      
      // Column shading elements - Updated widths to match header
      .column-shade-1, .column-shade-2, .column-shade-3, .column-shade-4 {
        content: "";
        position: absolute;
        top: 0; // Ensure it starts from the very top
        bottom: 0;
        z-index: 0;
        width: 150px; // Fixed pixel width matching max-width of value columns
      }
      
      // First client column - Using exact positioning from right
      .column-shade-1 {
        right: 450px; // Position from right (150px * 3)
        background-color: rgba(236, 237, 245, 0.8); // Match header shade exactly
      }
      
      // First ex column - Using exact positioning from right
      .column-shade-2 {
        right: 300px; // Position from right (150px * 2)
        background-color: rgba(246, 246, 251, 0.9); // Match header shade exactly
      }
      
      // Second client column - Using exact positioning from right
      .column-shade-3 {
        right: 150px; // Position from right (150px)
        background-color: rgba(236, 237, 245, 0.8); // Match header shade exactly
      }
      
      // Second ex column - Using exact positioning from right
      .column-shade-4 {
        right: 0; // Position from right
        background-color: rgba(246, 246, 251, 0.9); // Match header shade exactly
      }
      
      // Add container for possession rows
      .possession-rows-container {
        position: relative;
        z-index: 1;
        border-radius: 0 0 4px 4px;
        overflow: hidden; // Keep alternating colors contained within parent border radius
        margin-bottom: 10px; // Add space after the rows container
        
        // Add minimum height for empty containers to ensure space for the new button
        &:empty {
          padding-bottom: 10px;
          //background-color: rgba(248, 249, 250, 0.5); // Light background to indicate empty area
          //border: 1px dashed rgba(222, 226, 230, 0.8); // Subtle dashed border
          //border-radius: 4px;
          margin-top: 5px; // Add a bit of space above for visual separation
        }
        
        // NEW: Empty state message for empty subcategories
        &:empty::before {
          content: "No items added yet. Click '+ New' to add your first item";
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 10px;
          color: #6c757d;
          font-style: italic;
          background-color: rgba(248, 249, 250, 0.7);
          border-radius: 4px;
          margin: 10px 0;
          min-height: 60px;
          text-align: center;
          font-size: 0.95rem;
          border: 1px dashed #dee2e6;
          position: relative; // Added position relative to contain the absolute button
        }
      }
      
      // New button positioning container
      .new-button-container {
        position: relative;
        height: 0px !important;
        margin-top: -15px;
        
        width: 100%;
        z-index: 999;
        
        .subcategory-new-button {
          position: absolute;
          left: 10px;
          top: -5px;
          z-index: 999;
          background-color: white !important;
          color: #007bff !important;
          border-color: #007bff !important;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.2s ease;
          pointer-events: auto;
          padding: 0.3rem 0.8rem !important;
          font-size: 0.9rem !important;
          
          &:hover {
            background-color: #007bff !important;
            color: white !important;
            transform: translateY(-2px);
          }
          
          small {
            font-weight: 500 !important;
            font-size: 0.9rem !important;
          }
        }
      }
      
      // Row alternating styles
      .row-even {
        background-color: rgba(255, 255, 255, 0.8);
      }
      
      .row-odd {
        background-color: rgba(248, 249, 250, 0.7);
      }
      
      .possession-row {
        position: relative;
        z-index: 1;
        
        // Enhanced hover state with transition
        &:hover {
          background-color: rgba(236, 242, 252, 0.5) !important;
          transition: background-color 0.2s ease;
        }
        
        // Add subtle top/bottom borders to better separate rows
        &:not(:last-child) {
          border-bottom: 1px solid rgba(222, 226, 230, 0.3);
        }
      }
      
      .empty-state {
        border: 2px dashed #dee2e6;
        background-color: #f8f9fa;
        padding: 2rem !important;
        position: relative;
        z-index: 1;
        
        .icon {
          font-size: 1.5rem;
          color: #adb5bd;
          margin-bottom: 0.75rem;
        }
        
        h5 {
          font-weight: 600;
          color: #495057;
          font-size: 1rem;
        }
        
        p {
          color: #6c757d;
          max-width: 400px;
          margin: 0 auto;
          font-size: 0.9rem;
        }
      }
    }

    // Modified styles for possession group headers (to make them less jarring)
    .possession-group {
     
      // Tone down the blue header appearance
      .group-header {
        background-color: #f0f4f8 !important; // Light blue-gray instead of bright blue
        color: #495057 !important; // Dark gray text instead of white
        font-size: 1rem !important; // Slightly smaller font
        font-weight: 600;
        padding: 0.5rem !important;
        border-radius: 4px !important;
        border-left: 4px solid #007bff !important; // Left border accent instead of full blue background
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
        margin-bottom: 0.2rem !important;
        
        // Hide the "+ New" dropdown in the top-level header
        .dropdown, .btn-sm, button[data-toggle="dropdown"] {
          display: none !important;
        }
      }
      
      // Simplified subcategory header styling
      .subcategory-header {
        padding: 0.4rem 0.5rem !important; // Increased padding for better spacing
        font-size: 1.1rem !important; // Increased from 0.95rem
        font-weight: 600 !important;
        color: #495057 !important;
        margin-bottom: 0.1rem !important; // Increased bottom margin
        border-bottom: none !important;
        background-color: transparent !important;
        display: flex !important;
        align-items: center !important;
        position: relative !important;
        justify-content: flex-start !important; // Changed back to flex-start to keep items together
        
        // Container for the subcategory label with fixed width
        .subcategory-label-container {
          width: 250px !important; // Fixed width for label container
          min-width: 250px !important; // Fixed minimum width for consistency
          max-width: 250px !important; // Fixed maximum width to ensure button position
          display: inline-flex !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          padding-right: 15px !important;
          box-sizing: border-box !important;
          margin-right: 0 !important;
          
          // Ensure text styling is consistent
          .text-uppercase {
            text-transform: none !important; // Remove uppercase styling
            letter-spacing: normal !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            display: block !important;
            width: 100% !important;
            font-size: 1.0rem !important;
          }
        }
        
        // Style for new button consistently positioned - Modified to hide by default
        .btn-sm, 
        .btn-outline-secondary {
          padding: 0.2rem 0.5rem !important;
          background-color: white !important;
          color: #007bff !important;
          border-color: #007bff !important;
          white-space: nowrap !important;
          min-width: 60px !important;
          text-align: center !important;
          position: relative !important;
          margin-left: 0 !important; // Remove auto margin
          flex-shrink: 0 !important; // Prevent button from shrinking
          
          // New: Hide button by default with opacity 0
          opacity: 0 !important;
          visibility: hidden !important;
          transition: opacity 0.3s ease, visibility 0.3s ease !important;
          
          &:hover {
            background-color: #007bff !important;
            color: white !important;
          }
          
          small {
            font-weight: 500 !important;
            font-size: 0.9rem !important; // UPDATED: Larger font for better visibility
          }
        }
      }
      
      // Add hover effect to show the button when hovering on parent elements
      // This will make the button visible when hovering anywhere in the group or subcategory
      &:hover {
        .subcategory-header {
          .btn-sm, 
          .btn-outline-secondary {
            opacity: 1 !important;
            visibility: visible !important;
          }
        }
        
        // Remove the group hover effect for the new button
        // The button will now only be visible when hovering on its specific subcategory
      }
      
      // UPDATED: Enhanced styling for empty subcategories
      .subcategory {
        padding: 0 0.5rem !important; // Adding horizontal padding for subcategories
        position: relative !important; // Add position relative to contain absolute elements
        
        &:last-child {
          margin-bottom: 0.5rem !important; // Increase bottom margin for the last subcategory
        }
        
        // Add special styling for empty subcategories
        &.empty-subcategory {
          // REMOVED: margin-bottom: 25px !important; // Add more space after empty subcategories
          
          // UPDATED: Custom message for empty subcategories
          .possession-rows-container:empty {
            &::before {
              content: attr(data-empty-message, "No items added yet. Click '+ New' to add your first item");
              display: flex;
              padding: 0px;
              color: #6c757d;
              font-style: italic;
              background-color: rgba(248, 249, 250, 0.7);
              border-radius: 4px;
              margin: 0px 0;
              min-height: 60px;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 0.95rem;
              border: 1px dashed #dee2e6;
            }
          }
          
          // Make the new button more visible in empty subcategories
          .new-button-container {
            // Adjusted positioning to ensure button appears within the message
            position: absolute !important;
            margin-top: 0 !important;
            top: 50px !important; // Moved 40px lower (from 15px to 55px)
            left: 15px !important;
            height: auto !important;
            
            .subcategory-new-button {
              // UPDATED: Make the button more discoverable
              opacity: 1 !important; // Always show button for empty subcategories
              visibility: visible !important;
              position: static !important; // Changed from absolute to static
              left: auto !important; // Let the container position the button
              top: auto !important;
              padding: 0.4rem 1rem !important;
              font-size: 0.9rem !important;
              
              small {
                font-size: 0.9rem !important; // Larger font for better visibility
              }
            }
          }
        }
        
        // Only show the new button when hovering directly on the subcategory
        &:hover {
          .subcategory-header {
            .btn-sm, 
            .btn-outline-secondary {
              opacity: 1 !important;
              visibility: visible !important;
            }
          }
          
          // Show new button when hovering on the subcategory
          .new-button-container .subcategory-new-button {
            opacity: 1 !important;
            visibility: visible !important;
          }
        }
      }
    }

    // Simplified row styling with bottom separator
    .possession-row {
      border: none;
      padding: 0.2rem 0.3rem;
      margin: 0;
      transition: all 0.2s ease;
      position: relative;
      z-index: 1;

      &:last-child {
        border-bottom: none;
      }
      
      &::before, &::after {
        display: none;
      }
      
      .column-shade-1, .column-shade-2 {
        display: none;
      }
      
      .row {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        margin: 0;
        
        // Improve responsive behavior
        @media (max-width: 991px) {
          flex-wrap: wrap !important;
        }
      }
      
      // Main content column with fixed calculation
      .col-4 {
        width: calc(100% - (150px * 4)) !important; // Take remaining space after accounting for value columns
        flex: 0 0 calc(100% - (150px * 4)) !important; 
        max-width: calc(100% - (150px * 4)) !important;
        position: relative;
        z-index: 1;
        background-color: transparent;
        display: block !important; // Force block display to prevent flex issues
        
        // Add better handling for narrow viewports
        @media (max-width: 1200px) {
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }
        
        // Make col-4 more responsive on medium screens
        @media (max-width: 991px) {
          width: 100% !important;
          flex: 1 1 100% !important;
          max-width: 100% !important;
          padding: 0.1rem 0.2rem !important;
        }
      }
      
      // Fixed pixel-width value columns
      .col-2 {
        width: 150px !important; // Fixed pixel width
        flex: 0 0 150px !important;
        max-width: 150px !important;
        position: relative;
        z-index: 1;
        padding-left: 0.25rem; // Restore original padding
        padding-right: 0.25rem; // Restore original padding
        background-color: transparent;
        
        // Reduce padding on smaller screens
        @media (max-width: 1200px) {
          padding-left: 0.15rem;
          padding-right: 0.15rem;
        }
        
        // Further optimize for very small screens
        @media (max-width: 576px) {
          width: 100px !important; // Reduce fixed width on small screens
          flex: 0 0 100px !important;
          max-width: 100px !important;
          padding-left: 0.1rem;
          padding-right: 0.1rem;
        }
        
        // Extra small screens
        @media (max-width: 480px) {
          width: 80px !important; // Minimum width on very small screens
          flex: 0 0 80px !important;
          max-width: 80px !important;
          font-size: 0.9rem !important;
        }
      }

      .toggle-button {
        color: #6c757d;
        transition: all 0.2s ease;
        padding: 2px;
        border-radius: 4px;
        min-width: 30px;
        width: 30px;
        height: 30px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        margin: 0 4px;
        flex-shrink: 0;
        font-size: 0.85rem;
        position: relative;
        z-index: 2;
        
        &:hover {
          color: white;
          background-color: #007bff;
          border-color: #007bff;
          text-decoration: none;
        }
        
        &.toggle-active {
          color: white;
          background-color: #007bff;
          border-color: #007bff;
          font-weight: 500;
        }
        
        .edit-icon {
          font-size: 0.8rem;
          margin-left: 3px;
          color: inherit;
        }
      }

      .possession-info {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        flex-wrap: nowrap !important; // CRITICAL: Never wrap to next line
        
        // Improved flex layout for more responsive behavior
        > * {
          flex-shrink: 1; // Allow all children to shrink
        }
        
        // Prioritize the description field growth
        .description-field {
          flex: 1 1 auto !important; // Allow it to grow and shrink as needed
          min-width: 0 !important; // Critical for proper flex shrinking
          margin-left: 0.5rem !important;
          flex-shrink: 1 !important; // Shrink less aggressively
          
          // Ensure proper priority for the description field
          textarea {
            width: 100% !important;
            min-width: 0 !important; // Critical for proper shrinking
          }
          
          // Adjust spacing on smaller screens
          @media (max-width: 991px) {
            margin-left: 5px !important;
          }
          
          @media (max-width: 576px) {
            margin-left: 3px !important;
          }
        }
        
        // Ensure typeAlias field doesn't grow too much
        .category-label-field {
          flex: 0 1 auto !important; // Don't grow but allow shrinking
          max-width: 150px !important;
          flex-shrink: 5 !important; // Shrink more aggressively than description
          
          // Ensure proper shape at all sizes
          @media (max-width: 991px) {
            flex: 0 0 130px !important;
            min-width: 130px !important;
            width: 130px !important;
            max-width: 130px !important;
          }
          
          @media (max-width: 768px) {
            flex: 0 0 100px !important;
            min-width: 100px !important; 
            width: 100px !important;
            max-width: 100px !important;
          }
          
          @media (max-width: 576px) {
            flex: 0 0 80px !important;
            min-width: 80px !important;
            width: 80px !important;
            max-width: 80px !important;
          }
          
          // Media query-specific styles are in _family-property-calculator-inputs.scss
        }
        
        // Keep edit button from shrinking
        .toggle-button {
          flex: 0 0 auto !important;
          
          // Adjust size on smaller screens
          @media (max-width: 768px) {
            min-width: 28px !important;
            width: 28px !important;
            height: 28px !important;
            margin: 0 2px !important;
          }
          
          @media (max-width: 576px) {
            min-width: 26px !important;
            width: 26px !important;
            height: 26px !important;
            margin: 0 1.5px !important;
          }
          
          @media (max-width: 480px) {
            min-width: 24px !important;
            width: 24px !important;
            height: 24px !important;
            margin: 0 1px !important;
          }
        }
        
        .type-selector {
          width: 38%;
          max-width: 250px; // Added max-width constraint for category dropdown
          flex-shrink: 0;
          margin-right: 10px;
          position: relative;
        }
      }

      .possession-type {
        font-weight: 600;
        color: #495057;
        font-size: 0.9rem;
        display: inline-block;
        
        &:after {
          content: ": ";
        }
      }
    }

    // Updated details section styling
    .possession-details {
      background-color: #f8f9fa !important;
      border-radius: 0;
      border-top: 1px solid #e9ecef;
      border-bottom: 1px solid #e9ecef;
      padding: 0.75rem 0.5rem !important;
      margin: 0.25rem 0 0 !important;
      position: relative;
      z-index: 1;

      label {
        font-weight: 500;
        font-size: 0.85rem;
        color: #495057;
        margin-bottom: 0.25rem;
      }

      .form-group {
        margin-bottom: 0.75rem;
      }
      
      .details-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.75rem;
        
        @media (max-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
        
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
      
      .checkbox-group {
        margin-top: 0.25rem;
        
        .form-check {
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
          
          input {
            margin-right: 0.5rem;
          }
          
          label {
            margin-bottom: 0;
            font-size: 0.85rem;
          }
        }
      }
    }

    // Styles for the subtotal row 
    .subtotal-row {
      background-color: rgba(248, 249, 250, 0.85);
      padding: 0.3rem 0.25rem;
      border-top: 1px solid rgba(222, 226, 230, 0.8);
      position: relative;
      z-index: 1; // Ensure this is lower than the new button z-index
      
      // Add subtle shadows
      box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.02), 0 1px 4px rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      
      strong {
        font-size: 0.95rem;
        color: #343a40;
        font-weight: 600;
      }
      
      .subtotal-value {
        font-weight: 600;
        font-size: 0.95rem;
        color: #007bff; // Blue text for subtotal values
        padding-right: 0.75rem;
        font-family: inherit;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 30px;
      }
      
      // Add subtle hover effect
      &:hover {
        background-color: rgba(236, 242, 252, 0.7);
      }
      
      // Adjust column styling to match the rest of the grid
      .col-4 {
        width: calc(100% - (150px * 4)) !important;
        flex: 0 0 calc(100% - (150px * 4)) !important;
        max-width: calc(100% - (150px * 4)) !important;
      }
      
      .col-2 {
        width: 150px !important;
        flex: 0 0 150px !important;
        max-width: 150px !important;
      }
    }

    // Styles for the grand total row
    .grand-total-row {
      background-color: #f8f9fa;
      padding: 0.6rem 0.5rem;
      border-top: 2px solid #dee2e6;
      position: relative;
      z-index: 3;
      box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      
      strong {
        font-size: 1.1rem;
        color: #212529;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
      
      .grand-total-value {
        font-weight: 700;
        font-size: 1.1rem;
        color: #007bff; // Blue text for total values
        padding-right: 0.75rem;
        font-family: inherit;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 36px;
      }
      
      // Add subtle hover effect
      &:hover {
        background-color: rgba(236, 242, 252, 0.8);
      }
      
      // Ensure columns are properly sized
      .col-4 {
        width: calc(100% - (150px * 4)) !important;
        flex: 0 0 calc(100% - (150px * 4)) !important;
        max-width: calc(100% - (150px * 4)) !important;
      }
      
      .col-2 {
        width: 150px !important;
        flex: 0 0 150px !important;
        max-width: 150px !important;
      }
    }
  }

  .btn {
    font-weight: 500;
    padding: 0.4rem 1.25rem;
    transition: all 0.2s ease;
    border-radius: 4px;
    font-size: 0.9rem;

    &.btn-secondary {
      background-color: #6c757d;
      border-color: #6c757d;

      &:hover {
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
} 