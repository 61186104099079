.inform-wopi-modal {
  max-width: 430px !important;

  &__header {
    position: relative;

    padding-top: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;

    color: $neutral-500;

    transition: color 0.2s ease-in-out;

    &:hover {
      color: $neutral-600;
    }

    &:focus {
      color: $neutral-700;
    }
  }

  &__text {
    width: 100%;
    max-width: 360px;
    margin: 24px auto 20px;
  }

  &__title {
    margin-bottom: 10px;

    color: $neutral-black;
    text-align: center;

    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  &__description {
    margin-bottom: 0;

    color: $neutral-600;
    text-align: center;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 147%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
    padding: 20px 0px 24px;

    border-top: 1px solid $neutral-200;
    background: $neutral-100;

    a {
      padding: 0 !important;

      color: $link-500;
      text-align: center;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .modal-body {
    padding: 0 !important;
  }

  .custom-checkbox {
    margin-top: 20px;

    label.custom-control-label {
      cursor: pointer;

      color: $neutral-600;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
