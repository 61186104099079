.error-section {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 24px;
  border-bottom: 1px solid $neutral-200;

  p {
    width: 100%;
    max-width: 620px;
    margin: 0;
    
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 500;
  }
}
