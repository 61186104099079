@import 'intake';

.logo-form {
  .dropzone-container {
    outline: 0;
  }

  .logo-container {
    position: relative;
    width: fit-content;
    overflow: hidden;
    border-radius: 4px;

    &.loading {
      pointer-events: none;
    }

    .org-logo-preloader {
      position: absolute;
      top: 42%;
      left: 42%;

      svg {
        color: black;
        font-size: 32px;
      }
    }
  }

  &.empty {
    align-items: center;
    height: 200px !important;

    .buttons {
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-top: 0;

      .dropzone-container {
        display: flex;
        width: 100% !important;
        align-items: center;
        justify-content: center;
        outline: 0;

        .text-container {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .org-logo-preloader {
            font-size: 30px;
          }

          button {
            width: 100% !important;
            height: 100%;
          }
        }
      }
    }
  }

  .buttons {
    padding-top: 12px;
    justify-content: flex-start;

    .dropzone-container {
      width: auto !important;
      height: 100%;
      outline: 0;
    }
  }
}
