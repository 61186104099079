.section-header {
  background-color: #f6f5f9;

  padding: 15px;
  border-radius: 6px 6px 0 0;
  display: inline-flex;
  width: 100%;
  border: 1px solid $secondary;

  img {
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }

  .toggle-checkbox {
    align-self: flex-end;
    flex-direction: row-reverse;
    margin-left: 36px;

    .toggle-checkbox-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: $neutral-black;
      margin-right: 12px;
    }
  }
}

.calculator-section-container {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  margin-top: 50px;
  padding-bottom: 20px;
  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 22px;
    }
  }
  .calculator-section {
    padding: 20px;

    @include media-breakpoint-down(xs) {
      padding: 20px 10px;
    }
  }

  .neutral-300 {
    color: $neutral-400 !important;
  }

  &.loading {
    transition: opacity 0.4s;
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
  }
}
