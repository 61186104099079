.generate-pdf {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 32px;

  &.simple-row {
    .select-input {
      margin-right: 16px;
    }
  }

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  .generate-btn-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-red-link {
    padding: 0 !important;
    height: 24px;
    margin-top: 12px;
  }

  .select-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .select-input {
    margin-right: 16px;
    width: auto !important;
    flex: 1;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .btn {
    min-width: 120px;
    padding: 9px 12px 10px 16px !important;

    svg {
      min-width: 24px;
    }
  }

  .report-info {
    .report-title {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 8px;
    }

    .report-description {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
      color: $neutral-500;
    }
  }

  .report-type-img {
    margin-right: 1rem;
    margin-left: -3px;
    max-width: 258px;
    max-height: 307px;

    @include media-breakpoint-down(lg) {
      max-width: 220px;
      max-height: 270px;
    }

    @include media-breakpoint-down(md) {
      max-width: 258px;
      max-height: 307px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 200px;
      max-height: 238px;
    }

    @include media-breakpoint-down(xs) {
      float: initial;
      width: 100%;
      height: auto;
      max-width: initial;
      max-height: initial;
    }
  }
}

.page,
.previous-calc-list {
  .generate-pdf {
    .generate-btn-container {
      .select-container {
        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: initial;

          .select-input {
            margin-bottom: 8px;
            margin-right: 0;
          }
        }
      }

      .btn-red-link {
        @include media-breakpoint-down(xs) {
          margin-bottom: 20px;
        }
      }
    }

    .report-type-img {
      margin-right: 1rem;
      margin-left: -3px;
      max-width: 258px;
      max-height: 307px;

      @include media-breakpoint-down(lg) {
        max-width: 200px;
        max-height: 250px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 200px;
        max-height: 238px;
      }

      @include media-breakpoint-down(xs) {
        float: initial;
        width: 100%;
        height: auto;
        max-width: initial;
        max-height: initial;
      }
    }
  }
}
