// NFP Calculation Section Styles
// Extracted from family-property-calculator-modal for better organization

.family-property-calculator-modal {
  .nfp-calculation-section {
    margin-top: 2rem;
    padding: 0;
    
    h5 {
      font-size: 1.1rem;
      font-weight: 600;
      color: #212529;
      padding-bottom: 0.5rem;
    }
    
    .valuation-headers-row {
      .valuation-type-header {
        font-weight: 600;
        font-size: 1.05rem;
        color: #495057;
        padding: 0.3rem 0;
        border-bottom: 1px solid #f0f0f0;
      }
      
      .valuation-person-header {
        font-weight: 500;
        font-size: 0.9rem;
        color: #6c757d;
        padding: 0.15rem 0;
      }
    }
    
    // Add alternating row colors
    .nfp-row {
      padding: 0.15rem 0;
      margin: 0;
      
      &:nth-child(odd) {
        background-color: rgba(248, 249, 250, 0.7);
      }
      
      &:nth-child(even) {
        background-color: rgba(255, 255, 255, 0.8);
      }
      
      &:hover {
        background-color: rgba(236, 242, 252, 0.5);
      }
    }
    
    .subtotal-row, .grand-total-row {
      padding: 0.25rem 0;
      border: none;
      box-shadow: none;
      border-radius: 0;
      
      small.text-muted {
        font-size: 0.8rem;
        opacity: 0.9;
        font-weight: normal;
        color: #495057;
      }
      
      .formula-explanation {
        font-size: 0.75rem;
        opacity: 0.9;
        margin-top: 2px;
        font-style: italic;
        text-align: right;
        line-height: 1.2;
        font-weight: normal;
        color: #495057;
      }
      
      // Add border only to the top of subtotal rows
      &.with-top-border {
        border-top: 1px solid #dee2e6;
        padding-top: 0.4rem;
        margin-top: 0.4rem;
      }
    }
    
    // Grand total row should still stand out
    .grand-total-row {
      background-color: #f8f9fa;
      padding: 0.4rem 0;
      border-top: 2px solid #dee2e6;
      position: relative;
      z-index: 3;
      
      strong {
        font-size: 1.1rem;
        font-weight: 700;
        color: #212529;
      }
      
      .grand-total-value {
        font-weight: 700;
        font-size: 1.1rem;
        color: #007bff;
      }
      
      &:hover {
        background-color: rgba(236, 242, 252, 0.8);
      }
    }

    // Special styling for equalization payment row - keep emphasis
    .equalization-payment-row {
      background-color: #f0f4fa;
      border: 2px solid #007bff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      
      .equalization-statement {
        font-size: 1.1rem;
        color: #212529;
        font-weight: 700;
        text-align: right;
        
        .amount {
          color: #007bff;
          font-size: 1.2rem;
          padding: 0 0.3rem;
        }
      }
      
      strong {
        font-size: 1.1rem;
        font-weight: 700;
      }
      
      // Use consistent formula explanation styling
      .formula-explanation {
        font-size: 0.75rem;
        opacity: 0.9;
        margin-top: 2px;
        font-style: italic;
        text-align: right;
        line-height: 1.2;
        font-weight: normal;
        color: #495057;
      }
      
      // Add background color variation to differentiate columns
      // Payment column styling with background color variation
      .col-4:nth-child(2) {
        background-color: rgba(236, 237, 245, 0.8); // Light blue-gray background
        border-radius: 4px;
        padding: 0.5rem;
      }
      
      // Second payment column styling
      .col-4:nth-child(3) {
        background-color: rgba(246, 246, 251, 0.9); // Lighter blue-gray background
        border-radius: 4px;
        padding: 0.5rem;
      }
      
      // Ensure consistent column widths with the rest of the table
      // First col-4 is for the label, matches NET FAMILY PROPERTY label width
      .col-4:first-child {
        width: calc(100% - (150px * 4)) !important;
        flex: 0 0 calc(100% - (150px * 4)) !important;
        max-width: calc(100% - (150px * 4)) !important;
      }
      
      // Payment columns (col-4 that are not first-child)
      .col-4:not(:first-child) {
        width: 300px !important; // 2 standard columns (150px * 2)
        flex: 0 0 300px !important;
        max-width: 300px !important;
        padding: 0.5rem; // Increased padding for better spacing
        padding-right: 1rem !important; // Add extra padding on the right
      }
      
      // Standard value columns
      .col-2 {
        width: 150px !important;
        flex: 0 0 150px !important;
        max-width: 150px !important;
      }
      
      &:hover {
        background-color: #e6f0ff;
      }
    }
  }
} 