.notification-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  flex-direction: column;
  justify-content: space-between;

  background-color: $neutral;
  border: 1px solid $primary-500;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1rem;

  .body-container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding: 0 12px;
    width: 100%;

    .card-body {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.8px;

      .intake-form-link {
        color: $link-500;
      }

      .default-notification-text {
        margin-bottom: unset;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;

        .profile-edit-link {
          color: $link-500;
        }

        .noty-btn {
          height: auto;
          display: inline-block;
          padding: 0;
          font-weight: 400;
          line-height: 19.6px;
          vertical-align: baseline;
        }
      }
    }

    .card-footer {
      column-gap: 24px;

      .noty-btn {
        display: flex;
        align-items: center;
        padding: 0;
        height: auto;
        font-weight: 400;
      }
    }
  }
}
