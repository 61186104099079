.dropdown.calculations-type-dropdown.client-actions-dropdown {
  .dropdown-menu {
    padding-top: 4px;
    margin-top: 8px;
    border-color: $neutral-200;

    .item-header.dropdown-header {
      font-family: 'DM Sans', sans-serif;
      padding: 7px 16px 3px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.02em;

      &:not(:first-of-type) {
        border-top: 1px solid $neutral-200;
        margin-top: 5px;
        padding-top: 12px;
      }
    }

    .dropdown-item {
      height: 33px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
