.form-group.custom-input {
  &.disabled-active {
    cursor: pointer;
  }

  .form-control-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    color: $neutral-800;
    margin-bottom: 4px !important;

    &.with-tooltip {
      display: flex;
      align-items: center;

      svg {
        color: $primary-500;
        margin-left: 4px;
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }

    .required-field {
      color: $negative-400;
    }
  }

  .input-group {
    border: 1px solid $neutral-300;
    transition: border-color 0.15s ease-in-out;
    border-radius: 4px;

    .react-datepicker-wrapper {
      width: 100%;
    }

    input,
    textarea {
      padding: 12px 12px 12px 16px;
      border: 0 !important;
      border-radius: 4px;

      &::placeholder {
        color: $neutral-400;
        font-size: 16px;
      }

      &:focus,
      &:active {
        box-shadow: none !important;
      }

      &:-webkit-autofill {
        animation-name: onAutoFillStart;
        transition: background-color 50000s ease-in-out 0s;

        &::first-line {
          font-family: $font-family-base;
          font-size: 16px;
          letter-spacing: -0.11px;
        }
      }

      &:not(:-webkit-autofill) {
        animation-name: onAutoFillCancel;
      }
    }

    input {
      height: 42px;
    }

    @keyframes onAutoFillStart {
    }

    @keyframes onAutoFillCancel {
    }

    .prepend-icon {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $white;
    }

    .append-icon {
      position: relative;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .append-icon,
    .prepend-icon {
      border-radius: 4px;

      svg path {
        stroke: $neutral-300;
      }
    }

    &.search-input {
      input {
        padding-right: 0;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          -webkit-appearance: none;
        }

        &.has-value {
          border-radius: 0;
        }
      }

      svg path {
        stroke: $neutral-500;
      }

      .clear-btn {
        background-color: $neutral;
        border-radius: 0 4px 4px 0;
        padding: 0 8px 0 2px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        svg path {
          stroke-width: 2.3;
        }
      }
    }
  }

  &.focused:not(&.disabled) {
    .input-group {
      border-color: $primary-400;
    }
  }

  &.invalid:not(&.disabled) {
    .input-group {
      border-color: $negative-400;
    }
  }

  &.disabled {
    .append-icon,
    .prepend-icon,
    textarea,
    .input-group {
      background-color: $neutral-100 !important;
    }

    .form-control {
      background-color: $neutral-100;
    }
  }

  &:not(.disabled) {
    .input-group {
      &:hover {
        border-color: $neutral-400;
      }
    }
  }

  &.input-xs {
    .form-control-label {
      line-height: 16px;
    }

    .input-group {
      .prepend-icon,
      .append-icon {
        width: 36px;
      }

      input {
        height: 30px;
        padding: 6px 8px 7px;
      }

      textarea {
        padding: 6px 8px 7px;
      }
    }
  }

  &.input-md {
    .form-control-label {
      line-height: 16px;
    }

    .input-group {
      .prepend-icon,
      .append-icon {
        width: 36px;
      }

      input {
        height: 40px;
        padding: 10px 12px 11px;
      }

      textarea {
        padding: 10px 12px 11px;
      }
    }
  }

  &.color-picker {
    display: grid;
    grid-template-areas:
      'label label'
      'btn input';
    gap: 12px 16px;
    grid-template-columns: 1fr 90px;
    align-items: center;

    .form-control-label {
      height: 16px;
      grid-area: label;
    }

    .btn-color {
      width: 64px;
      height: 64px;
      border-radius: 8px;
      border: 1px solid #e9e9e9;
      grid-area: btn;

      &:focus {
        outline: none;
      }
    }

    .color-input {
      padding: 10px 12px;
      height: min-content;
      max-width: 90px;
      background-color: #cdd4de30;
      border-radius: 4px;
      border: 0;
      font-size: 16px;
      line-height: 19px;
      grid-area: input;

      &:focus {
        outline: none;
      }
    }
  }

  &.rating-input .input-group {
    border: 0;
  }
}

.form-group .form-control-label .required-field {
  color: $negative-400;
}

.custom-checkbox {
  display: flex;
  align-items: center;

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary;
  }

  .custom-control-input:disabled {
    & ~ .custom-control-label::before {
      border-color: $neutral-200;
      background-color: $white !important;
    }

    & ~ .custom-control-label::after {
      display: none !important;
    }
  }

  label.custom-control-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $neutral-800;

    span {
      padding-left: 6px;
    }

    &::after,
    &::before {
      top: -2px;
      width: 20px;
      height: 20px;
    }

    &::after {
      width: 20px;
      height: 20px;
      background-image: url('data:image/svg+xml;utf8,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 5.95246L3.85714 8.8096L11 1.66675" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>');
      box-shadow: none;
    }
  }
}

.popover {
  border: 0;

  .popover-body {
    padding: 0;
  }
}

input[type='number']::-webkit-inner-spin-button {
  display: none;
}

.form-control-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px;
  letter-spacing: 0;
  color: $neutral-800 !important;
}

.invalid-feedback {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: $negative-400;
  transition: margin-top 0.1s ease-in-out, opacity 0.1s ease-in-out;
  margin-top: 0px;

  &.show {
    margin-top: 0.25rem;
  }
}
