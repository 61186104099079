.enable-mfa-modal {
  p {
    margin-bottom: 8px;
  }

  .qr-code-container {
    border: 1px solid $gray-200;
    border-radius: 4px;
    padding: 40px;
    margin-bottom: 8px;
  }

  .modal-footer {
    padding-top: 24px !important;
  }
}